import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { CouponInstanceModel } from '../models/couponInstance.model'
import { Observable, throwError, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ApiUtil } from '../util/apiUtil';
import { JwtUtil } from '../util/jwtUtils';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class JwtService{
    constructor(
        private http: HttpClient,
        private apiUtil:ApiUtil,
        private jwtUtil:JwtUtil,
     ) {}

     private url:string=environment.apiUrl+'/ewallet/api';

     refresh():Observable<any>{
        const httpOptions = {
            headers: new HttpHeaders({
             'Accept': 'application/json',
             'ewRefreshToken':this.jwtUtil.getRefreshTocken(),
           }),
        };
        return this.apiUtil.doGet(this.url+"/auth/token/refresh",httpOptions);
      }

      validate():Observable<any>{
        const httpOptions = {
            headers: new HttpHeaders({
             'Accept': 'application/json',
             'serviceAppToken':this.jwtUtil.getServiceAppToken(),
             'serviceAppType':this.jwtUtil.getServiceAppType(),
           }),
        };
        return this.apiUtil.doGet(this.url+"/auth/token/app/validate",httpOptions);
      }

      getIconImage():Observable<any>{
        const httpOptions = {
            headers: new HttpHeaders({
             'Accept': 'application/json',
             'ewAccessToken':this.jwtUtil.getAccessToken()?this.jwtUtil.getAccessToken():'',
           }),
        };
        return this.apiUtil.doGet(this.url+"/info/brandImage",httpOptions);
      }

      getCustomerInfo():Observable<any>{
        const httpOptions = {
            headers: new HttpHeaders({
             'Accept': 'application/json',
             'ewAccessToken':this.jwtUtil.getAccessToken(),
           }),
          };
          return this.apiUtil.doGet(this.url+"/info/cust",httpOptions);
      }
}
<div>
<canvas id="canvas"></canvas>
<video id="camera" width="100%" height="100%" x-webkit-airplay="true" controls autoplay playsinline muted ></video>
<div style="height:35px;top:0px;position:fixed;width:100%;left:0px;">
   <p style="color:white;background-color: black;text-align: center;opacity:65%;height:100%;font-size: var(--bigSize);">{{channel}}</p> 
</div>
<div style="height:60px;top:35px;position:fixed;width:100%;left:0px;">
   <p style="color:white;background-color: black;text-align: center;opacity:65%;height:100%;font-size: var(--bigSize);">{{couponName}}</p> 
</div>
<img src="/assets/common/image/icons/{{channel}}/qrBorder.png"  style="position:fixed;left:-webkit-calc(50%-120px);left:-moz-calc(50% - 120px); left:calc(50% - 120px);top:-webkit-calc(50%-120px);top:-moz-calc(50% - 120px); top:calc(50% - 120px);z-index: 200;" width="240" height="240"/>
<button (click)="clickReturn()" style="font-size:24px;background-color:black;position: fixed; 
top:-webkit-calc( 100% - 90px);top:-moz-calc(100% - 90px); top:calc(100% - 90px);
left:-webkit-calc(50% - 30px);left:-moz-calc(50% - 30px); left:calc(50% - 30px);
width: 60px;height: 60px;border-radius: 30px;"><i class="fa fa-mail-reply" style="color: white;"></i></button>
<div class="line" style="position:fixed;left:-webkit-calc(50%-120x);left:-moz-calc(50% - 120px); left:calc(50% - 120px);
top:-webkit-calc(50%-120px);top:-moz-calc(50% - 120px); top:calc(50% - 120px);z-index:300;"></div>
</div>
<input type="text" class="scanResult" style="display:none;" />
<input type="button" id="scanInitBtn" class="scanInitBtn" style="display:none;" (click)="init()"/>
<input type="button" class="scanedBtn" style="display:none;" (click)="clickScaned()"/>
<input type="text" class="scanDebug" value="123" style="position: absolute;top:3px; left: 10px;display: none;"/>
<input type="button" id="resetScanBtn" style="display:none;" (click)="resetScan()"/>
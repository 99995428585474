import { Injectable, Injector } from '@angular/core';
import {  HttpHeaders } from '@angular/common/http';
import { ApiUtil } from '../util/apiUtil';
import { Observable, throwError, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LoginService{
    constructor(
        private apiUtil:ApiUtil,
     ) {}

      private url:string=environment.apiUrl+'/ewallet/api';

      login(form:any):Observable<any>{
          return this.apiUtil.doPost(this.url+"/login",form);
      }

      // refresh(refreshToken:string):Observable<any>{
      //   const httpOptions = {
      //       headers: new HttpHeaders({
      //        'Accept': 'application/json',
      //        'refreshTocken':refreshToken,
      //      })
      //     };
      //     return this.apiUtil.doGet(this.url+"/token/refresh",httpOptions);
      // }

      // verify(accessToken:string):Observable<any>{
      //   const httpOptions = {
      //       headers: new HttpHeaders({
      //        'Accept': 'application/json',
      //        'accessTocken':accessToken
      //      })
      //     };
      //     return this.apiUtil.doGet(this.url+"/token/verify",httpOptions);
      //  }
}
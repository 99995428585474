import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-myewallet-pending',
    templateUrl: './myewallet-pending-component.html',
    styleUrls: ['./myewallet-pending-component.scss']
})
export class MyEwalletPendingComponent implements OnInit {
    ngOnInit() {
    }
}
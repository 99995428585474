import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { CouponInstanceModel } from '../models/couponInstance.model'
import { Observable, throwError, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ApiUtil } from '../util/apiUtil';
import { JwtUtil } from '../util/jwtUtils';
import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class FavroitesService{
       constructor(
          private http: HttpClient,
          private apiUtil:ApiUtil,
          private jwtUtil:JwtUtil,
       ) {}

       defaultHeader = { 'Content-Type': 'application/json' };

      private favoritesUrl:string='/ewallet/api/coupons/favourites';
      
      private urlFavorites:string=environment.apiUrl+this.favoritesUrl;

    getFavoritesList(customerId:string):Observable<any>{
      const httpOptions = {
        headers: new HttpHeaders({
         'Accept': 'application/json',
         'Access-Control-Allow-Origin':'*',
         ewAccessToken:this.jwtUtil.getAccessToken()
       }),
       observe:"response",
       responseType:"json",
       reportProgress:true
      };
      return this.apiUtil.doGet(this.urlFavorites+'?customerId='+customerId,httpOptions);
    }


    putFavoritesList(customerId:string,favourites:string):Observable<any>{
      const httpOptions = {
        headers: new HttpHeaders({
         'Accept': 'application/json',
         'Access-Control-Allow-Origin':'*',
         ewAccessToken:this.jwtUtil.getAccessToken()
       }),
       observe:"response",
       responseType:"json",
       reportProgress:true
      };
      return this.apiUtil.doPut(this.urlFavorites+'?customerId='+customerId+'&favourites='+favourites,null,httpOptions);
    }
}
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-myewallet-sharepopup',
    templateUrl: './myewallet-sharepopup.component.html',
    styleUrls: ['./myewallet-sharepopup.component.scss']
})
export class MyEwalletSharePopupComponent implements OnInit {
    ngOnInit() {
    }
}
<div class="horizontalScrollDiv" style="padding-top:10px;padding-bottom:15px;padding-left:5px;" *ngIf="!(resultBrandMap.size==1&&resultBrandMap.get(appIssueBrand))">
    
      <!-- <img src="{{brandImageMap.get('csl.')}}" width="48" height="32" class="col-4 col-sm-2 col-md-3 col-lg-2 col-xl-2 {{filterBrand=='csl.'?'brandImage_select':'brandImage'}}" (click)="changeBrand('csl.',true)"  *ngIf="resultBrandMap.get('csl.')">
    <img src="{{brandImageMap.get('CSL')}}" width="48" height="32" class="col-4 col-sm-2 col-md-3 col-lg-2 col-xl-2 {{filterBrand=='CSL'?'brandImage_select':'brandImage'}}" (click)="changeBrand('CSL',true)"  *ngIf="resultBrandMap.get('CSL')">
    <img src="{{brandImageMap.get('1O1O')}}" width="90" height="32" class="col-4 col-sm-2 col-md-3 col-lg-2 col-xl-2 {{filterBrand=='1O1O'?'brandImage_select':'brandImage'}}" (click)="changeBrand('1O1O',true)"  *ngIf="resultBrandMap.get('1O1O')">
    <img src="{{brandImageMap.get('HKT')}}" width="48" height="32" class="col-4 col-sm-2 col-md-3 col-lg-2 col-xl-2 {{filterBrand=='HKT'?'brandImage_select':'brandImage'}}" (click)="changeBrand('HKT',true)"  *ngIf="resultBrandMap.get('HKT')">
    <img src="{{brandImageMap.get('Club SIM')}}" width="48" height="32" class="col-4 col-sm-2 col-md-3 col-lg-2 col-xl-2 {{filterBrand=='Club SIM'?'brandImage_select':'brandImage'}}" (click)="changeBrand('Club SIM',true)"  *ngIf="resultBrandMap.get('Club SIM')">
     -->

      <img *ngFor="let key of issueBrandList" src="{{sessionStorage('brandFilter-'+key)}}"  
            class="col-4 col-sm-2 col-md-3 col-lg-2 col-xl-2 {{filterBrand==key?'brandImage_select':(filterBrand=='ALL'?'brandImage_select':'brandImage')}}" 
            (click)="changeBrand(key,true)" 
            style="
            background-color: 
            {{sessionStorage('style')=='1010myhkt' && !originColorBrandlist.includes(key)?'white':''}};
            ">
      <div class="col-{{resultBrandMap.size*4>=12?0:12-resultBrandMap.size*4}} col-sm-{{resultBrandMap.size*2>=12?0:12-resultBrandMap.size*2}} col-md-{{resultBrandMap.size*3>=12?0:12-resultBrandMap.size*3}} col-lg-{{resultBrandMap.size*2>=12?0:12-resultBrandMap.size*2}} col-xl-{{resultBrandMap.size*2>=12?0:12-resultBrandMap.size*2}}"></div>
    <!---->
    <!--<div class="col-4 col-sm-4 col-md-4 col-lg-8 col-xl-9" *ngIf="!resultBrandMap.get('CSL')"></div>
    <div class="col-4 col-sm-4 col-md-4 col-lg-8 col-xl-9" *ngIf="!resultBrandMap.get('1010')"></div>
    
    <div class="col-4 col-sm-4 col-md-4 col-lg-8 col-xl-9" *ngIf="!resultBrandMap.get('HKT')"></div>-->
    <!--<div class="col-4 col-sm-4 col-md-4 col-lg-5 col-xl-9" *ngIf="!resultBrandMap.get('CLUBSIM')"></div>-->
    <!--
    <div class="brandNewDiv col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 ">
          <img src="/assets/common/image/brands/brand/CSL.png" width="33" height="22" class="brandNewImage">
    </div>
    <div class="brandNewDiv col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 ">
          <img src="/assets/common/image/brands/brand/1010.png" width="33" height="22" class="brandNewImage">
    </div>
    <div class="brandNewDiv col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 ">
          <img src="/assets/common/image/brands/brand/CLUBSIM.png" width="33" height="22" class="brandNewImage">
    </div>
     -->
</div>
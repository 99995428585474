import { RequestSourceModel } from './requestSource.model';
import { RequestOrderModel } from './requestOrder.model';

export class RedeemRequestModel{
    couponCode?:string;
    mappedRule?:string;
    order?:RequestOrderModel;
    qrCode?:string;
    redeemAmount?:number;
    redeemDate?:string;
    source?:RequestSourceModel;
    redeemMethod?:string;
}
export class QrCodeScanUtil{

    constructor(){
    }

    static closeCamera(vedioId){
        // console.log(camera);
        // camera.stop();
        var video=document.getElementById(vedioId);
        var stream=video.srcObject;
        var camera=stream.getTracks()[0];
        console.log(camera);
        if(camera){
            camera.stop();
        }
    }

    static nativeScan(handler){
        customWebToAppData("scannerQRCode", {}, function(data){
            //TODO The parameter - data is QR code Actual text
            handler.handleScan(data);
        });      
    }

    static doScan(canvasId,vedioId){
        var ch=window.screen.availHeight;
        var cw=window.screen.availWidth;

        // var video=document.createElement("video");
        var video=document.getElementById(vedioId);
        var canvasElement=document.getElementById(canvasId);
        var canvas=canvasElement.getContext("2d");
        // var outputSize=false;//debug screnn size

         //for canvas imgage high ppi++
        var getPixelRatio = function(context) {    
            var backingStore = context.backingStorePixelRatio ||
                  context.webkitBackingStorePixelRatio ||
                  context.mozBackingStorePixelRatio ||
                  context.msBackingStorePixelRatio ||
                  context.oBackingStorePixelRatio ||
                  context.backingStorePixelRatio || 1;
        
            return (window.devicePixelRatio || 1) // backingStore;
        };
        var ratio=getPixelRatio(canvas);
        // console.log(ratio);
        //for canvas imgage high ppi--

        // if(localStorage.getItem('env')=='ios'){
        //     alert('ios');
            canvasElement.setAttribute('style',"display:none;");
            video.setAttribute('style',"object-fit:fill;position: fixed;top: 0px;left: 0px;height:"+ch+"px;width:"+cw+"px;");
            ratio=1;
        // }
        // else{
            // canvasElement.setAttribute('style',"position: fixed;top: 0px;left: 0px;height:"+ch+"px;width:"+cw+"px;");
            // video.setAttribute('style',"display:none;");
            // ratio=1;
        // }
        // scaninit(video,canvasElement,canvas);
        var success=function(stream){
            console.log(stream);
            //video.src = CompatibleURL.createObjectURL(stream);
            video.srcObject = stream;
            var camera=stream.getTracks()[0];
            console.log(camera);
            video.play();
            requestAnimationFrame(scanQR);
        }
        var scanQR=function(){
            if(video.readyState===video.HAVE_ENOUGH_DATA){
                // canvasElement.height=window.screen.availHeight;
                // canvasElement.width=video.videoWidth;
                // console.log(canvasElement.width);
                // console.log(canvasElement.height);

                canvasElement.height=ch*ratio;//test not canvas
                canvasElement.width=cw*ratio;
                
                // var vcc=video.videoWidth/video.videoHeight;
                // var scc=ch/cw;
                // var clipHeight=video.videoHeight;
                // if(vcc<1.5&&vcc>0.8&&scc>1.5){//mobile carme
                //      clipHeight=video.videoWidth;
                // }
                //  -----debug screnn size
                // if(!outputSize){
                //     alert(''+video.videoWidth+':'+clipHeight);
                //     alert(''+cw+':'+ch);
                //     alert(''+ratio);
                //     alert(''+canvasElement.width+':'+canvasElement.height);
                //     outputSize=true;
                // }
                // canvasElement.width=video.videoWidth;
                // canvas.drawImage(video,0,0,canvasElement.width,canvasElement.height);
                // canvas.imageSmoothingEnabled = false;


                canvas.drawImage(video,0,0,video.videoWidth,video.videoHeight,0,0,canvasElement.width,canvasElement.height);
                var imageData=canvas.getImageData(0,0,canvasElement.width,canvasElement.height);
                var code=jsQR(imageData.data,imageData.width,imageData.height,{inversionAttempts:"dontInvert",});

                if(code&&code.data){
                    console.log('scan is ');
                    console.log(code);
                    console.debug(code);
                    var scanResult=document.getElementsByClassName('scanResult');
                    var scanedBtn=document.getElementsByClassName('scanedBtn');
                    scanResult[0].setAttribute('value',code.data);
                    scanedBtn[0].click();
                    drawLine(code.location.topLeftCorner,code.location.topRightCorner,"#FF3B58");
                    drawLine(code.location.topRightCorner,code.location.bottomRightCorner,"#FF3B58");
                    drawLine(code.location.bottomRightCorner,code.location.bottomLeftCorner,"#FF3B58");
                    drawLine(code.location.bottomLeftCorner,code.location.topLeftCorner,"#FF3B58");
                }
            }
            setTimeout(() => {
                requestAnimationFrame(scanQR);
            }, 1000);   
        }
        var drawLine=function(begin,end,color){
            canvas.beginPath();canvas.moveTo(begin.x,begin.y);
            canvas.lineTo(end.x,end.y);
            canvas.lineWidth=4;canvas.strokeStyle=color;canvas.stroke();
        }

        var error=function(error) {
            alert('error');
            console.log(`访问用户媒体设备失败${error.name}, ${error.message}`);
            var dd=document.getElementsByClassName('scanDebug');
            dd[0].setAttribute('value','error:'+error.name+":"+error.message);
        }
        var hh={ min: 300, ideal: 1700, max: 3000 };
        var ww={ min: 300, ideal: 1700, max: 3000 };
        if (navigator.mediaDevices||navigator.mediaDevices.getUserMedia) {
            //最新的标准API
            navigator.mediaDevices.getuser
            navigator.mediaDevices.getUserMedia({video : {width: ww, height: hh,facingMode:"environment"}}).then(success).catch(error);
        } else if (navigator.webkitGetUserMedia) {
            //webkit核心浏览器
            navigator.webkitGetUserMedia({video : {width: ww, height: hh,facingMode:"environment"}},success, error)
        } else if (navigator.mozGetUserMedia) {
            //firfox浏览器
            navigator.mozGetUserMedia({video : {width: ww, height: hh,facingMode:"environment"}}, success, error);
        } else if (navigator.getUserMedia) {
            //旧版API
            navigator.getUserMedia({video : {width: ww, height: hh,facingMode:"environment"}}, success, error);
        }
    }

    // static success(stream) {
    //     //兼容webkit核心浏览器
    //     // let CompatibleURL = window.URL || window.webkitURL;

    //     //将视频流设置为video元素的源
    //     console.log(stream);
    //     alert('successs');
    //     //video.src = CompatibleURL.createObjectURL(stream);
    //     video.srcObject = stream;
    //     video.play();
    //     requestAnimationFrame(scanQR);
    // }

    // static drawLine(begin,end,color){
    //     canvas.beginPath();canvas.moveTo(begin.x,begin.y);
    //     canvas.lineTo(end.x,end.y);
    //     canvas.lineWidth=4;canvas.strokeStyle=color;canvas.stroke();
    // }

    // static scanQR(){
    //     if(video.readyState===video.HAVE_ENOUGH_DATA){
    //         canvasElement.height=video.videoHeight;
    //         canvasElement.width=video.videoWidth;
    //         canvas.drawImage(video,0,0,canvasElement.width,canvasElement.height);
    //         var imageData=canvas.getImageData(0,0,canvasElement.width,canvasElement.height);
    //         var code=jsQR(imageData.data,imageData.width,imageData.height,{inversionAttempts:"dontInvert",});
    //         if(code){
    //             console.log('scan is ');
    //             console.log(code);
    //             drawLine(code.location.topLeftCorner,code.location.topRightCorner,"#FF3B58");
    //                     drawLine(code.location.topRightCorner,code.location.bottomRightCorner,"#FF3B58");
    //                     drawLine(code.location.bottomRightCorner,code.location.bottomLeftCorner,"#FF3B58");
    //                     drawLine(code.location.bottomLeftCorner,code.location.topLeftCorner,"#FF3B58");
    //         }
    //     }
    //     requestAnimationFrame(scanQR);
    // }

    // static error(error) {
    //     alert('error');
    //     console.log(`访问用户媒体设备失败${error.name}, ${error.message}`);
    //     // document.write('<p>error.message<p>');
    //    var dd=document.getElementsByClassName('scanDebug');
    //    dd[0].setAttribute('value','error:'+error.name+":"+error.message);
    // }
}
  
  // var DateRangePickerUtils={
  //     normalRange:function(id){
  //       $('#'+id).daterangepicker({
  //         opens:'left'
  //       }, function(start, end, label) {
  //      console.log("选择了一个新的日期: "+start.format('YYYY-MM-DD') +' to '+end.format('YYYY-MM-DD'));
  //       });
  //     }
  // }

  export class DateRangePickerUtils{
    constructor(){}

    static normalRange(id){
        $('#'+id).daterangepicker({
          "autoApply":false,
          "showCustomRangeLabel": false,
          "isShowing":false,
          "showSingleRange":true,
          // "showDayOfWeekShort":true,
          // "showOffDay":false, 
          // "showCouponStyle":true,
          // "initNull":true,
          // "noPopup":true,
          // "noPopupLocation":"top:100px;left:0;right:auto;display:block;"
        }, function(start, end, label) {
       console.log("选择了一个新的日期: "+start.format('YYYY-MM-DD') +' to '+end.format('YYYY-MM-DD'));
        });
        // $('#'+id).click();
    }

    static couponDateRange(id,nopopup,nopopupLocation,noPopupId,dataformat,separator,dayOfWeekStr,monthFormat,lang,page){
      if(lang){
        moment.locale(lang);
      }
      $('#'+id).daterangepicker({  
           "autoApply":false,
          "showCustomRangeLabel": false,
          "isShowing":false,
          "showSingleRange":true,
          "showDayOfWeekShort":true,
          "showOffDay":false, 
          "showCouponStyle":true,
          "initNull":true,
          "noPopup":nopopup,
          "noPopupLocation":nopopupLocation,
          "noPopupId":noPopupId,
          "dayOfWeekStr":dayOfWeekStr,
          "lang":lang,
          "page":page,
          "monthFormat":monthFormat,
          "maxDate":moment().startOf('day'),
          "locale":{'format':dataformat,"separator":separator}
        }, function(start, end, label) {
                console.log("选择了一个新的日期: "+start.format(dataformat) +separator+end.format(dataformat));
                var input=document.getElementById(id);
                input.setAttribute('value',start.format(dataformat) +separator+end.format(dataformat));
        });
        // if(noPopup){
          $('#'+id).click();
    }

    static getLastWeekFirstDay(dayFormat){
      var result='';
      var weekOfday = moment().format('E');
      result = moment().subtract(parseInt(weekOfday)+7, 'days').format(dayFormat);
      return result;
    }

    static getLastWeekLastDay(dayFormat){
      var result='';
      var weekOfday = moment().format('E');
      result = moment().subtract(parseInt(weekOfday)+1, 'days').format(dayFormat);
      return result;
    }

    static getLastMonthFirstDay(dayFormat){
      var dayOfMonth=moment().format('D');
      var lastDayOfLastMonth=moment().subtract(parseInt(dayOfMonth),'days');
      var dayOfLdlm=lastDayOfLastMonth.format('D');
      // console.log(parseInt(dayOfLdlm));
      // console.log(lastDayOfLastMonth.format('YYYY/MM/DD'));
      var firstDayOfLastMonth=lastDayOfLastMonth.subtract(parseInt(dayOfLdlm)-1,'days');
      return firstDayOfLastMonth.format(dayFormat);
    }

    static getLastMonthLastDay(dayFormat){
      var dayOfMonth=moment().format('D');
      var lastDayOfLastMonth=moment().subtract(parseInt(dayOfMonth),'days');
      return lastDayOfLastMonth.format(dayFormat);
    }

    static getNow(dayFormat){
       return moment().format(dayFormat);
    }

    static isBefore(startDate,endDate,dayFormat){
      var result=false;
      var startDate=moment(startDate,dayFormat);
      var endDate=moment(endDate,dayFormat);
      result=startDate.isBefore(endDate);
      return result;
    }

    static isAfter(startDate,endDate,dayFormat){
      var result=false;
      var startDate=moment(startDate,dayFormat);
      var endDate=moment(endDate,dayFormat);
      result=startDate.isAfter(endDate);
      return result;
    }

    static convertDateFormat(dateStr,fromFormat,toFormat){
       var result='';
       var d=moment(dateStr,fromFormat);
       result=d.format(toFormat);
       return result;
    }
  }
    
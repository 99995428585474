<!--<button (click)="changeLanguage(language)">{{ languageBtn}}</button> -->
<router-outlet></router-outlet>
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#errorModal" id="errorModalBtn" (click)="clickPopupErrMsg(2000)" style="display:none;">
    error click
</button>
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#tokenModal" id="tokenModalBtn" style="display:none;">
  error click
</button>
<div class="modal fade" id="errorModal" aria-hidden="false" data-backdrop="static" style="position:fixed; top: 30%;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
			    <h4 id="errorModalHeader" class="modal-title"></h4>
        </div>
        <div class="modal-body">
          <div>
            <p id="errorModalContent"><b></b></p>
            <p id="errorModalContentDetail" style="font-size: 0.7rem;line-height: 1;"></p>
          </div>
        </div>
        <button type="button" id="errorModalClose" class="btn btn-secondary" data-dismiss="modal" >{{'close'|translate}}</button>
      </div>
    </div>
  </div>

  <div class="modal fade" id="tokenModal" aria-hidden="false" data-backdrop="static">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
			<p style="color:darkgrey"><b id="tokenModalContent">{{'systemError'|translate}}</b></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="clickCopyErrMsg()">Copy</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="clickCloseErrMsg()">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!--
  <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#loadingModal" id="loadingModalBtn" style="display: none;">
    error click
</button>
<div class="modal fade" id="loadingModal">
    <div class="modal-dialog">
      <div class="modal-content" style="background-color: transparent;margin-top:30%;border:none;width:100%;height:30%">
        <div class="modal-body">
            <div style="width: 32px;height: 32px;clear: both;margin: 20px auto;border: 4px red solid;border-top: 4px red solid;border-radius: 50%;-webkit-animation: spCircRot .6s infinite linear;animation: spCircRot .6s infinite linear;"></div>
            <img src="/assets/common/loading.gif" width=50 height=50 
            style="position:absolute;
            left:-webkit-calc(50%-25px);left:-moz-calc(50% - 25px); left:calc(50% - 25px);top:170px;z-index:888;"/>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" id="loadingModalEndBtn" style="display: none;">Close</button>
        </div>
      </div>
    </div>
  </div>-->
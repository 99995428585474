import { Injectable, Injector } from '@angular/core';
import { ErrHandler } from './errHandler';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit,OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { EwalletListService } from '../service/ewalletList.service';
import { CouponInstanceModel } from '../models/couponInstance.model';
import { PopupMsgUtil } from '../util/popupMsgUtil';
import { LoginModel } from '../models/login.model';
import { JwtUtil } from './jwtUtils';
import { JwtService } from '../service/jwt.service';
import { environment } from 'src/environments/environment';
import {TranslateService} from '@ngx-translate/core';
import { InfiniteScroll } from 'angular2-infinite-scroll';

@Injectable({ providedIn: 'root' })
export class ErrMsgUtil {
    constructor(
        private jwtUtil:JwtUtil,
        private router:Router,
        private ewalletListService:EwalletListService,
        private jwtService:JwtService,
        private translateService:TranslateService,
    ){}

    popupMsg(layer:string, status:string, msg:string,detail:string,type:string){
        var errorBtn=document.getElementById('errorModalBtn');
        let errorModalHeader=document.getElementById('errorModalHeader');
        let errorModalContent=document.getElementById('errorModalContent')
        let errorContentDetail=document.getElementById('errorModalContentDetail');
        errorContentDetail.setAttribute('font-size', '0')


        
        let errorMsg:string;
        let tokenInvalid:string;
        let sessionTimeout:string;
        let errorMsgContent:string;
        let sessionTimeoutContent:string;

        this.translateService.get('systemError').subscribe(
          (res)=>{
              errorMsg=res;
          }
        );

        this.translateService.get('tokenInvalid').subscribe(
          (res)=>{
            tokenInvalid=res;
          }
        );

        this.translateService.get('sessionTimeout').subscribe(
          (res)=>{
            sessionTimeout=res;
          }
        );

        this.translateService.get('systemErrorContent').subscribe(
          (res)=>{
            errorMsgContent=res;
          }
        );

        this.translateService.get('sessionTimeoutContent').subscribe(
          (res)=>{
            sessionTimeoutContent=res;
          }
        );

        if(type) {
          if(type=='sessionTimeout') {
            if(layer && msg){
              errorModalHeader.innerHTML=sessionTimeout;
              errorModalContent.innerHTML=sessionTimeoutContent;
              errorContentDetail.innerHTML="["+layer+"]"+"["+status+"]"+msg+':'+detail;
            }else {
              errorModalHeader.innerHTML=sessionTimeout;
              errorModalContent.innerHTML=sessionTimeoutContent;
              errorContentDetail.innerHTML=msg;
            }

          } else if(type='tokenInvalid') {
            if(layer && msg){
              errorModalHeader.innerHTML=tokenInvalid;
              errorModalContent.innerHTML=sessionTimeoutContent;
              errorContentDetail.innerHTML="["+layer+"]"+"["+status+"]"+msg+':'+detail;
            }else {
              errorModalHeader.innerHTML=tokenInvalid;
              errorModalContent.innerHTML=sessionTimeoutContent;
              errorContentDetail.innerHTML=msg;
            }

          }

        }else {
          let channelType:string =this.jwtUtil.getSubServiceAppType();//set channel type from token
          if(layer && msg){
            if(channelType != 'MyHKT' ) 
              errorModalHeader.innerHTML=errorMsg;
            errorModalContent.innerHTML=errorMsgContent;
            errorContentDetail.innerHTML="["+layer+"]"+"["+status+"]"+msg+':'+detail;
          }else {
            if(channelType != 'MyHKT' ) 
              errorModalHeader.innerHTML=errorMsg;
            errorModalContent.innerHTML=errorMsgContent;
            errorContentDetail.innerHTML=msg;
          }
        }



        
        errorBtn.click();
    }

    static popupMessage(msg:string){
      var errorBtn=document.getElementById('errorModalBtn');
      var errorContent=document.getElementById('errorModalContent');
      if(msg){
        errorContent.innerHTML=msg;
      }
      errorBtn.click();
    }

    static popupToken(msg:string){
      var errorBtn=document.getElementById('tokenModalBtn');
      var errorContent=document.getElementById('tokenModalContent');
      if(msg){
        errorContent.innerHTML=""+msg;
      }
      errorBtn.click();
    }

    handleNormalErr(handler:ErrHandler,err:any,msg:string){
        
    }

    popErrMsg(err:any,msg:string,type:string){
      if(!msg && err){
        console.log(err);
        this.popupMsg(err.layer, err.status, err.errors.message,err.errors.detail,type?type:'');
      }
      else{
        this.popupMsg('', '', msg,'',type?type:'');
      }
    }

    handleErr(handler:ErrHandler,err:any){
      handler.handleErr();
      if(this.jwtUtil.getValidateStatus()||this.jwtUtil.getGetTokenStatus()){
        return;
      }

      if(handler.isCustomError!=undefined&&handler.isCustomError()){
         handler.handleCustomError();
      }else{
        var errorMsg;
        var tokenMsg;
        this.translateService.get('sessionTimeout').subscribe(
          (res)=>{
              errorMsg=res;
          }
        );

        this.translateService.get('tokenInvalid').subscribe(
          (res)=>{
               tokenMsg=res;
          }
        );

        if(err&&(err.status==401||err.status==0)){
          if(err.errors&&err.errors.code == 'ACS_EXP') {
            this.jwtService.refresh().subscribe(
              (res)=>{
                console.log("refresh token suceess.");
                console.log(res);
                this.jwtUtil.setAccessToken(res.accessToken);
                this.jwtUtil.setRefreshTocken(res.refreshToken);
                handler.handleRefreshToken();
              }
              ,(err)=>{
                  this.jwtUtil.removeJwtToken();
                  console.log("refresh token error.");
                  this.jwtUtil.removeJwtToken();
                  this.popupMsg(err.layer,err.status,err.errors.message,err.errors.detail,'tokenInvalid');
              }
            );
          }else if(err.errors&&err.errors.code== 'RFH_EXP') {     
           this.popupMsg(err.layer,err.status,errorMsg,'','sessionTimeout');
          }else if(err.errors
            &&(err.errors.code== 'SERVICE_EXP'||err.errors.code== 'SERVICE_SIGN_KEY_ERROR'
            ||err.errors.code== 'SERVICE_DECODE_ERROR'||err.errors.code== 'SERVICE_AES_SIGN_KEY_ERROR')) {
              this.popupMsg(err.layer,err.status,tokenMsg,'','tokenInvalid');
          }else {
            this.popupMsg(err.layer,err.status,errorMsg,'','tokenInvalid');
          }
        }else if(err&&(err.status==504 || err.status==500 || err.status==400)) {
          if(err.errors){
            this.popupMsg(err.layer,err.status,err.errors.message,err.errors.detail,'');
          }else if(err.error){
            this.popupMsg(err.layer,err.status,err.error.message,err.error.detail,'');
          }
          
        }else {
          this.popupMsg('','','','','');
        }
      }       
    }


    getErrorMsg(err:any):string{
      var errorMsg;
      this.translateService.get('systemError').subscribe(
        (res)=>{
            errorMsg=res;
        }
    );
      return errorMsg;
    }
}
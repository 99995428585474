import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../data/service/login.service';
import { ActivatedRoute,Router } from '@angular/router';
import { ApiUtil } from '../data/util/apiUtil';
import { LoginModel } from '../data/models/login.model';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
   
  // ,private apiUtil:ApiUtil
  constructor(private route: ActivatedRoute,private router: Router, ) {
  }

  loginServer:LoginService;

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    // console.log("interceptor start");
    // console.log(req);
    // console.log(next);
    // if(req.url=='./assets/common/dev.json'||req.url=='./assets/common/prod.json'
    //     ||req.url.match('/token/verify')||req.url.match('/token/refresh')){
    //   return next.handle(req);
    // }
    // else{
    //   if(!this.loginServer){
    //     this.loginServer=new LoginService(this.configService,this.apiUtil);
    //   }
    //    if(sessionStorage&&sessionStorage.getItem("accessTocken")){
    //      this.loginServer.verify(sessionStorage.getItem("accessTocken")).subscribe(
    //       (res) => {
    //       //  console.log('access token:'+sessionStorage.getItem("accessTocken"));
    //       //  var li:LoginModel=res;
    //          console.log('verify successful');
    //          console.log(res);
    //       //  sessionStorage.clear();
    //       //  var n:Date=new Date();
    //       //  sessionStorage.setItem("sessionTime",""+n.getTime());
    //       //  sessionStorage.setItem("userName",li.user.username);
    //       //  sessionStorage.setItem("accessTocken",li.accessToken);
    //       //  sessionStorage.setItem("refreshTocken",li.refreshToken);
    //       //  return next.handle(req);
    //       },(err) => {
    //          console.log('verify error');
    //          console.log(err);
    //          console.log(sessionStorage);
    //          if(sessionStorage.getItem("refreshTocken")){
    //                 this.loginServer.refresh(sessionStorage.getItem("refreshTocken")).subscribe(
    //                       (res) => {
    //                           console.log('refresh successful');
    //                           console.log(res);
    //                           console.log(this.router);
    //                           var li:LoginModel=res;
    //                           sessionStorage.setItem("accessTocken",li.accessToken);
    //                           sessionStorage.setItem("refreshTocken",li.refreshToken);
    //                           sessionStorage.setItem("refreshHttp",'1');

    //                           var myHeader=req.clone({
    //                             setHeaders:{
    //                                Accept: 'application/json',
    //                                refreshTocken:li.refreshToken,
    //                                accessTocken:li.accessToken
    //                                // bb:'12'
    //                             }
    //                           });
    //                           next.handle(myHeader);
    //                           console.log(myHeader);
    //                           // console.log(myHeader.headers)
    //                           // return next.handle(myHeader);
    //                       },(err) => {
    //                            console.log('refresh error');
    //                            console.log(err);
    //                       }
    //                 );
    //           }
    //       //  this.router.navigateByUrl('/login/unAuth');
    //       // return ;
    //      });
    //    }
    // }

    return next.handle(req);
  }
}
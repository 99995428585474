import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { CouponInstanceModel } from '../models/couponInstance.model'
import { stockBalanceReq } from '../models/stockBalanceReq.model';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ApiUtil } from '../util/apiUtil';
import { JwtUtil } from '../util/jwtUtils';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckStockService{
  constructor(
     private http: HttpClient,
     private apiUtil:ApiUtil,
     private jwtUtil:JwtUtil,
  ) {}

  defaultHeader = { 'Content-Type': 'application/json' };

private stockBalanceUrl:string=environment.apiUrl+'/ewallet/api/info/stock-balance';

getStockBalance(area:string,productSKU:string,redemChannellist:string[]):Observable<any>{
  const httpOptions = {
    headers: new HttpHeaders({
     'Accept': 'application/json',
     'Access-Control-Allow-Origin':'*',
     ewAccessToken:this.jwtUtil.getAccessToken()
   }),
   observe:"response",
   responseType:"json",
   reportProgress:true
  };
  var req = new stockBalanceReq;
  req.area = area;
  req.productSKU = productSKU;
  req.redemChannellist = redemChannellist;
  return this.apiUtil.doPost(this.stockBalanceUrl,req,httpOptions);
 }

}

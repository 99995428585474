import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { Router } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MyEwalletModule} from './module/myewallet/myewallet.module';
import { MicrositeModule } from './module/microsite/microsite.module';
import { LoginModule} from './module/login/login.module';
import { QRCodeModule } from 'angularx-qrcode';
import { FormsModule} from '@angular/forms';
import { HttpClient,HttpClientModule } from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { httpInterceptorProviders } from './http-interceptors/index';
// import { ConfigModule, ConfigService } from './data/service/config.service';

export function createTranslateHttpLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MyEwalletModule,
    MicrositeModule,
    LoginModule,
    QRCodeModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateHttpLoader),
        deps: [HttpClient]
      }
    }),
     
  ],
  providers: [httpInterceptorProviders
    //  ,ConfigService
    //  ,ConfigModule.init()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(router: Router) {
    // Use a custom replacer to display function names in the route configs
    // const replacer = (key, value) => (typeof value === 'function') ? value.name : value;

    // console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
  }
}

export class ShareUtils{
    constructor(){}
    
    static share(shareTitle,shareMessage,shareUrl,lang){
        if (getOS() == "other") {
            alert('web share');
            webshare(shareTitle, shareMessage, shareUrl, lang);
        } else {
            // alert('native share');
            nativeShare(shareTitle, shareMessage, shareUrl, lang);
        }
    }
    

    static isWebShare(){
        var tokenType=sessionStorage.getItem("serviceAppType");
        var isAppToken=tokenType?tokenType.match('app'):null;
        console.log(isAppToken!=null);
        if (getOS() != "other"&&isAppToken!=null) {//only apptoken and env is app too should use the app method
            return false;
        }
        else{
            return true;
        }
    }
  }
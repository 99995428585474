import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, ROUTER_CONFIGURATION } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { EwalletListService } from '../../../data/service/ewalletList.service';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { TransactionLogService } from '../../../data/service/transactionLog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { LoginModel } from '../../../data/models/login.model';
import { TranslateService } from '@ngx-translate/core';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { ComponentUtil } from '../../../data/util/componentUtil';
import { formatDate } from '@angular/common';
import { RedemptionRecordItemModel } from '../../../data/models/redemptionRecordItem.model';
import { ErrHandler } from '../../../data/util/errHandler';
import { DateRangePickerUtils } from '../../../../assets/common/util/daterangepickerutil';
import { environment } from 'src/environments/environment';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

@Component({
    selector: 'app-myewallet-redemptionRecord',
    templateUrl: './myewallet-redemptionRecord.component.html',
    styleUrls: ['./myewallet-redemptionRecord.component.scss']
})
export class MyEwalletRedemptionRecordComponent implements OnInit {
    dateKeys: string[] = [];

    pickDateRangeStr: string = 'Pick Date Range';

    @Input()
    page: string;

    redemptionHists: RedemptionRecordItemModel[] = [];
    redemptionHistMap: Map<String, RedemptionRecordItemModel[]> = new Map<String, RedemptionRecordItemModel[]>();

    filterRedemptionName: string;

    filterDateRange: string;

    dateRangePickerShow: string;

    enterDateTime: string;

    dateTimeFormat: string = 'YYYY-MM-DD hh:mm:ss';

    dateFormat: string = 'YYYY-MM-DD';

    tsDateFormat: string = 'yyyy-MM-dd';

    // dateTimeFormat:string='DD-MMM-YYYY hh:mm:ss';

    // dateFormat:string='DD-MMM-YYYY';

    apiDateFormat: string = 'YYYY-MM-DD HH:mm:ss'

    dateFormatReg: string = '^\\d{4}-\\d{2}-\\d{2}';

    separator: string = ' to ';

    defaultLimit: number = 10;

    nextOffset: number = 0;

    totalNumber: number;

    channel: string;

    //isWeb: boolean = false;

    lang: string = sessionStorage.getItem("lang");

    env = environment;

    brandImageMap: Map<string, string> = new Map<string, string>();

    constructor(private router: Router, private ewalletListService: EwalletListService, private transactionLogService: TransactionLogService
        , private location: Location, private errMsgUtil: ErrMsgUtil, private jwtUtil: JwtUtil, private popupMsgUtil: PopupMsgUtil, private translateService: TranslateService, private componentUtil: ComponentUtil) { }


    sessionStorage(id) {
        return sessionStorage.getItem(id);
    }

    ngOnInit() {
        // this.enterDateTime=DateRangePickerUtils.getNow(this.dateTimeFormat);
        // console.log(this.enterDateTime);
        // this.filterDateRange="";
        // this.filterRedemptionName="";
        // this.totalNumber=0;
        // this.hardCode();
        // this.filterRedemptionRecord(this.filterRedemptionName);
        //"Pick Date Range"
        //  this.filterDateRange="2021-03-06 to 2021-03-20";     
        //  console.log(this.redemptionHists);
        //  this.redemptionHistMap=this.convertRedempitionHistListToMap(this.redemptionHists);
        //  this.dateKeys=this.convertDateKey(this.redemptionHistMap);
        //  console.log(this.redemptionHistMap);
        //  console.log(this.dateKeys);
        //  var dateStr='2020-11-01 0:00:00';
        //  console.log(formatDate(dateStr,"yyyy-MM-dd",'en'));
        //  this.registerPopupEvent(); 
        this.channel = this.jwtUtil.getSubServiceAppType();
        this.translateService.get("pickDateRange").subscribe(
            (res) => {
                this.pickDateRangeStr = res;
            }
        );
        this.translateService.get("momentDateFormat").subscribe(
            (res) => {
                this.dateFormat = res;
            }
        );
        this.translateService.get("momentDateTimeFormat").subscribe(
            (res) => {
                this.dateTimeFormat = res;
            }
        );
        this.translateService.get("dateFormatReg").subscribe(
            (res) => {
                this.dateFormatReg = res;
            }
        );
        this.translateService.get("dateFormat").subscribe(
            (res) => {
                this.tsDateFormat = res;
            }
        );
        this.translateService.get("dateSeparator").subscribe(
            (res) => {
                this.separator = res;
            }
        );
        this.defaultLimit = this.componentUtil.getPageLimit();
        this.brandImageMap = this.jwtUtil.getBrandImage();
        
        //this.isWeb=localStorage.getItem('env')?localStorage.getItem('env')=='web':false;
    }

    initDetail() {
        this.enterDateTime = DateRangePickerUtils.getNow(this.dateTimeFormat);
        console.log(this.enterDateTime);
        this.filterDateRange = "";
        this.filterRedemptionName = "";
        this.changeButtonSelectStyle(this.page + 'rfAll', "redemptionFilterBtn");
        this.dateRangePickerShow = "";
        this.totalNumber = 0;
        this.nextOffset = 0;
        this.hardCode();
        this.searchRedemptionRecord(null, null, this.defaultLimit, 0, true);
        this.changeDateRangeClassToDefault();
        // var csDiv=document.getElementById('redemptionFilterDiv');
        // csDiv.scroll(-100,0);
    }

    clickSerchBar() {
        this.searchRedemptionRecord(this.filterRedemptionName, this.filterDateRange, this.defaultLimit, 0, true);
    }

    clickDateRange(id: string) {
        if ('DateRange' == id) {
            this.openDateRange();
            return;
        }
        else if ('DateRangeEnter' == id) {
            id = 'DateRange';
        }
        else if ('LastWeek' == id) {
            this.filterDateRange = DateRangePickerUtils.getLastWeekFirstDay(this.dateFormat) + this.separator
                + DateRangePickerUtils.getLastWeekLastDay(this.dateFormat);
            this.changeDateRangeClassToDefault();
        }
        else if ('LastMonth' == id) {
            this.filterDateRange = DateRangePickerUtils.getLastMonthFirstDay(this.dateFormat) + this.separator
                + DateRangePickerUtils.getLastMonthLastDay(this.dateFormat);
            this.changeDateRangeClassToDefault();
        }
        else {
            this.changeDateRangeClassToDefault();
            this.filterDateRange = '';
        }
        this.searchRedemptionRecord(this.filterRedemptionName, this.filterDateRange, this.defaultLimit, 0, true);
        this.changeButtonSelectStyle(this.page + 'rf' + id, "redemptionFilterBtn");
    }

    clickMore() {
        this.searchRedemptionRecord(this.filterRedemptionName, this.filterDateRange, this.defaultLimit, this.nextOffset, false);
    }

    private changeButtonSelectStyle(ctId: string, className: string) {
        var ctButtons = document.getElementsByClassName(className);
        // console.log(ctButtons);
        for (var i = 0; i < ctButtons.length; i++) {
            // ctButtons[i].setAttribute("style","");
            var btnClass = ctButtons[i].getAttribute("class");
            btnClass = btnClass.replace('csbuttonFill', 'csbuttonNoFill');
            ctButtons[i].setAttribute("class", btnClass);
        }
        if (ctId && ctId.length > 0) {
            var selectButton = document.getElementById(ctId);
            if (selectButton) {
                // selectButton.setAttribute("style","border:2px solid orange;color:orange");
                var btnClass = selectButton.getAttribute("class");
                btnClass = btnClass.replace('csbuttonNoFill', 'csbuttonFill');
                selectButton.setAttribute("class", btnClass);
            }
        }
    }

    searchRedemptionRecord(filterRedemptionName: string, dateRange: string, limit: number, offset: number, reset: boolean) {
        this.popupMsgUtil.loading();

        if (reset) {
            this.redemptionHists = [];
            this.redemptionHistMap = new Map<String, RedemptionRecordItemModel[]>();
            console.log(this.redemptionHistMap)
            this.dateKeys = [];
        }

        var fd = this.getDateRangeFrom(dateRange);
        var td = this.getDateRangeTo(dateRange);
        console.log("key:" + filterRedemptionName + ";dateRange:" + dateRange + ";limit:" + limit + ";offset:" + offset + ";fd:" + fd + ";td:" + td);
        var nowD = DateRangePickerUtils.getNow(this.dateFormat);
        if (ComponentUtil.checkSelectNotNull(fd)) {
            if (this.isDateAfterNow(fd)) {
                fd = nowD
            }
            fd = fd + ' 00:00:00';
        }
        if (ComponentUtil.checkSelectNotNull(td)) {
            if (this.isDateAfterNow(td)) {
                td = DateRangePickerUtils.getNow(this.dateFormat);
            }
            if (td == nowD) {
                td = this.enterDateTime;
            }
            else {
                td = td + ' 23:59:59';
            }
        }
        else {
            td = this.enterDateTime;
        }
        console.log("key:" + filterRedemptionName + ";dateRange:" + dateRange + ";limit:" + limit + ";offset:" + offset + ";fd:" + fd + ";td:" + td);
        if (fd) {
            fd = DateRangePickerUtils.convertDateFormat(fd, this.dateTimeFormat, this.apiDateFormat);
        }
        if (td) {
            td = DateRangePickerUtils.convertDateFormat(td, this.dateTimeFormat, this.apiDateFormat);
        }
        console.log("key:" + filterRedemptionName + ";dateRange:" + dateRange + ";limit:" + limit + ";offset:" + offset + ";fd:" + fd + ";td:" + td);
        var category = 'C';
        if (this.page == 'rewards') { category = 'R'; }
        this.transactionLogService.getRedemptionRecords(limit, offset, fd, td, category).subscribe(
            (res) => {
                this.nextOffset = offset + limit;
                console.log(res);
                res = this.jwtUtil.setupResponse(res);
                this.totalNumber = res.totalNumber;

                console.log(this.nextOffset);
                console.log(this.totalNumber);
                // this.redemptionHists.push(res.data);
                if (res.data) {
                    var addDatas: RedemptionRecordItemModel[] = res.data;
                    if (addDatas && addDatas.length > 0) {
                        for (var i = 0; i < addDatas.length; i++) {
                            // UTC plus 8 hours(convert to HK time)
                            if(addDatas[i].redemptionDate){
                                addDatas[i].redemptionDate = dayjs.utc(addDatas[i].redemptionDate).add(8,'hour').format('YYYY-MM-DD HH:mm:ss');
                            }

                            this.redemptionHists.push(addDatas[i]);
                        }
                    }
                }
                this.filterRedemptionRecord(filterRedemptionName);
                this.popupMsgUtil.endLoading();
            }
            , (err) => {
                this.popupMsgUtil.endLoading();
                const _this = this;
                function getHandler(): ErrHandler {
                    let handler = <ErrHandler><unknown>function () { };
                    handler.handleErr = function () {

                    }
                    handler.handleRefreshToken = function () {
                        _this.searchRedemptionRecord(filterRedemptionName, dateRange, limit, offset, reset);
                    }
                    return handler;
                }
                var eh: ErrHandler = getHandler();
                this.errMsgUtil.handleErr(eh, err.error);
            }
        );
    }

    filterRedemptionRecord(filterRedemptionName: string) {
        if (this.redemptionHists && this.redemptionHists.length > 0) {
            if (ComponentUtil.checkSelectNotNull(filterRedemptionName)) {
                var temp: RedemptionRecordItemModel[] = [];
                var filter_upper = filterRedemptionName.toUpperCase();
                this.redemptionHists.filter(b => {
                    if (+b.couponCode.toUpperCase().indexOf(filter_upper) >= 0
                        || ("" + b.redemptionAmount).toUpperCase().indexOf(filter_upper) >= 0
                        || ("" + b.channelGroup).toUpperCase().indexOf(filter_upper) >= 0
                        || ("" + b.couponItem.name.toUpperCase()).indexOf(filter_upper) >= 0
                        || ("" + b.couponItem.nameCht.toUpperCase()).indexOf(filter_upper) >= 0
                    ) {
                        temp.push(b);
                    }
                });
                this.redemptionHistMap = this.convertRedempitionHistListToMap(temp);
                this.dateKeys = this.convertDateKey(this.redemptionHistMap);
            }
            else {
                this.redemptionHistMap = this.convertRedempitionHistListToMap(this.redemptionHists);
                this.dateKeys = this.convertDateKey(this.redemptionHistMap);
            }
        }
        console.log(this.redemptionHistMap)
    }

    convertRedempitionHistListToMap(list: RedemptionRecordItemModel[]): Map<String, RedemptionRecordItemModel[]> {
        if (!list || list.length == 0) { return null; }
        var map: Map<String, RedemptionRecordItemModel[]> = new Map<String, RedemptionRecordItemModel[]>();
        var i: number;
        for (i = 0; i < list.length; i++) {
            var item: RedemptionRecordItemModel = list[i];
            // var dateKey=formatDate(item.redemptionDate,this.tsDateFormat,'en');
            var dateKey = DateRangePickerUtils.convertDateFormat(item.redemptionDate?item.redemptionDate:item.actionDate, this.apiDateFormat, this.dateFormat);
            dateKey = DateRangePickerUtils.convertDateFormat(dateKey, this.dateFormat, this.apiDateFormat);
            // console.log(dateKey);
            var histList: RedemptionRecordItemModel[] = map.get(dateKey);
            if (!histList) {
                histList = [];
            }

            histList.push(item);
            map.set(dateKey, histList);
        }
        return map;
    }

    convertDateKey(map: Map<String, RedemptionRecordItemModel[]>): string[] {
        if (!map || map.size == 0) { return null; }
        var dateKeys = [];
        map.forEach((v, k, map) => {
            dateKeys.push(k);
        });
        dateKeys.sort((a, b) => {
            var diff = 0;
            //    var i:number;
            //     for(i=0;i<a.length;i++){
            //         var sa=a.charAt(i);
            //         var sb=b.charAt(i);
            //         diff=sb.localeCompare(sa);
            //         if(diff!=0){
            //             break;
            //         }
            //     }
            if (a == b) {

            }
            else if (DateRangePickerUtils.isBefore(a, b, this.apiDateFormat)) {
                diff = 1;
            }
            else {
                diff = -1;
            }

            return diff;
        });
        return dateKeys;
        //    callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any
    }

    hardCode() {
        var item1: RedemptionRecordItemModel = new RedemptionRecordItemModel();
        item1.redemptionDate = '2021-11-01 0:00:00';
        item1.logId = '1';
        item1.couponCode = 'aa1';
        item1.channelGroup = 'Shop';
        item1.redemptionAmount = 100;

        var item2: RedemptionRecordItemModel = new RedemptionRecordItemModel();
        item2.redemptionDate = '2021-11-01 12:00:00';
        item2.logId = '2';
        item2.couponCode = 'aa1';
        item2.channelGroup = 'Shop';
        item2.redemptionAmount = 10;

        var item3: RedemptionRecordItemModel = new RedemptionRecordItemModel();
        item3.redemptionDate = '2021-11-01 0:00:00';
        item3.logId = '3';
        item3.couponCode = 'aa1';
        item3.channelGroup = 'Shop';
        item3.redemptionAmount = 121;

        var item4: RedemptionRecordItemModel = new RedemptionRecordItemModel();
        item4.redemptionDate = '2021-11-02 0:00:00';
        item4.logId = '4';
        item4.couponCode = 'aa1';
        item4.channelGroup = 'Shop';
        item4.redemptionAmount = 130;


        var item5: RedemptionRecordItemModel = new RedemptionRecordItemModel();
        item5.redemptionDate = '2021-11-02 0:00:00';
        item5.logId = '5';
        item5.couponCode = 'aa1';
        item5.channelGroup = 'Shop';
        item5.redemptionAmount = 110;

        this.redemptionHists.push(item1);
        this.redemptionHists.push(item2);
        this.redemptionHists.push(item3);
        this.redemptionHists.push(item4);
        this.redemptionHists.push(item5);
    }

    openDateRange() {
        var drDiv = document.getElementById(this.page + 'redemptionHistoryDateRangeDiv');
        var dateRangeInitBtn = document.getElementById(this.page + 'CouponDateRangeInitBtn');
        dateRangeInitBtn.click();
        drDiv.style.display = "block";
    }

    closeDateRange() {
        var drDiv = document.getElementById(this.page + 'redemptionHistoryDateRangeDiv');
        drDiv.style.display = "none";
    }

    enterDateRange() {
        var drDiv = document.getElementById(this.page + 'redemptionHistoryDateRangeDiv');
        var dateRangeInitBtn = document.getElementById(this.page + 'CouponDateRangeEnterBtn');
        dateRangeInitBtn.click();
        var popVal = document.getElementById(this.page + 'CouponDateRangeText').getAttribute('value');
        // console.log(popVal);

        var rfDateRangeBtn = document.getElementById(this.page + 'rfDateRange');
        var drClass = rfDateRangeBtn.getAttribute('class');
        console.log(popVal);
        if (!popVal || popVal == '') {
            drClass = drClass.replace('col-8', 'col-5');
        }
        else {
            drClass = drClass.replace('col-5', 'col-8');
        }
        this.filterDateRange = popVal;
        this.dateRangePickerShow = popVal;
        drDiv.style.display = "none";
        rfDateRangeBtn.setAttribute('class', drClass);
        if (!popVal || popVal == '') {
            this.clickDateRange('All');
        }
        else {
            this.clickDateRange('DateRangeEnter');
        }

    }

    changeDateRangeClassToDefault() {
        this.dateRangePickerShow = "";
        var rfDateRangeBtn = document.getElementById(this.page + 'rfDateRange');
        var drClass = rfDateRangeBtn.getAttribute('class');
        drClass = drClass.replace('col-8', 'col-5');
        rfDateRangeBtn.setAttribute('class', drClass);
    }

    registerPopupEvent() {
        var drDiv = document.getElementById(this.page + 'redemptionHistoryDateRangeDiv');
        window.onclick = function (e) {
            if (e.target = drDiv) {
                drDiv.style.display = "none";
            }
        }
    }


    getDateRangeFrom(dateRange: string): string {
        var result: string = null;
        if (dateRange) {
            console.log(dateRange.length);
            console.log(this.dateFormat.length);
            console.log(this.separator.length);
        }
        if (dateRange && dateRange.length == (this.dateFormat.length * 2 + this.separator.length)) {
            result = dateRange.substr(0, this.dateFormat.length);
            if (!this.matchDateFormatReg(result)) {
                result = null;
            }
        }
        return result;
    }

    getDateRangeTo(dateRange: string) {
        var result: string = null;
        if (dateRange && dateRange.length == (this.dateFormat.length * 2 + this.separator.length)) {
            result = dateRange.substr(this.dateFormat.length + this.separator.length, this.dateFormat.length);
        }
        return result;
    }

    matchDateFormatReg(date: string): boolean {
        var result: boolean = false;
        if (date) {
            console.log(this.dateFormatReg);
            var m = date.match(this.dateFormatReg);
            if (m) {
                result = true;
            }
        }
        return result;
    }

    isDateAfterNow(date: string): boolean {
        var result: boolean = false;
        var now = DateRangePickerUtils.getNow(this.dateFormat);
        result = DateRangePickerUtils.isAfter(date, now, this.dateFormat);
        return result;
    }
}
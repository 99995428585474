import { Injectable, Injector } from '@angular/core';
import { Base64Utils } from '../../../assets/common/util/base64util';
import { JwtUtil } from './jwtUtils';
import { CouponInstanceModel } from '../models/couponInstance.model';
import { ComponentUtil } from './componentUtil';
import { DateRangePickerUtils } from '../../../assets/common/util/daterangepickerutil';
import { ProviderCouponModel } from '../models/providerCoupon.model';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(customParseFormat);


@Injectable({ providedIn: 'root' })
export class CouponListCommonUtil {
    public static setDayOffAndSort(couponList:CouponInstanceModel[],resultBrandMap:Map<string,number>,format:string):CouponInstanceModel[]{
            //  var now:number=new Date().getTime()/86400000;
            var now:number=new Date().getTime()/1000;
            
            
            //  console.log(now);
            if(couponList&&couponList.length>0){
                var i:number;
                for(i=0;i<couponList.length;i++){
                        var vtT:number=new Date(couponList[i].validTo).getTime()/1000;
                        couponList[i].dateOff=parseInt(""+(vtT-now+1));
                       


                        if(couponList[i].validFrom ) {
                           couponList[i].validFrom = dayjs.utc(couponList[i].validFrom).add(8, 'hour').format('YYYY-MM-DD HH:mm:ss');    
                        }
                        
                        if(couponList[i].validTo ) {
                            couponList[i].validToResource = couponList[i].validTo;
                            if(couponList[i].source=="SB")
                                couponList[i].validTo = dayjs(couponList[i].validTo, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss');
                            else
                                couponList[i].validTo = dayjs.utc(couponList[i].validTo).add(8, 'hour').format('YYYY-MM-DD HH:mm:ss');
                        }
                        
                    couponList[i]=this.changeCouponBanlanceShow(couponList[i],true);                    
                    if(resultBrandMap){
                        resultBrandMap.set(couponList[i].couponItem.issueBrand,1);
                    }
                }                 
                console.log(resultBrandMap);
            }
    
            return couponList;
    }


    public static changeDateFormatFromBe(coupon:CouponInstanceModel,now:number):CouponInstanceModel{
        if(!now){
            now=new Date().getTime()/1000;
        }
        if(coupon){
            if(coupon.validTo&&coupon.validTo.length>0){
                coupon.validTo=coupon.validTo.replace('Z','').replace('T',' ');
                var vtT:number=new Date(coupon.validTo).getTime()/1000;
                coupon.dateOff=parseInt(""+(vtT-now+1));
            }
            if(coupon.validFrom){
                coupon.validFrom=coupon.validFrom.replace('Z','').replace('T',' ');
            }
        }
        return coupon;
    }

    public static changeCouponDateFormat(cp:CouponInstanceModel,format:string):CouponInstanceModel{
        
        if(cp.validFrom) {
            cp.validFrom = dayjs.utc(cp.validFrom).add(8, 'hour').format('YYYY-MM-DD HH:mm:ss');
            cp.validFrom=DateRangePickerUtils.convertDateFormat(cp.validFrom,'YYYY-MM-DD HH:mm:ss',format);
        }
        if(cp.validTo) {
            cp.validTo = dayjs.utc(cp.validTo).add(8, 'hour').format('YYYY-MM-DD HH:mm:ss');
            cp.validTo=DateRangePickerUtils.convertDateFormat(cp.validTo,'YYYY-MM-DD HH:mm:ss',format);
        }  
        return cp;
    }

    public static changeCouponBanlanceShow(cp:CouponInstanceModel,isMoveTag:boolean):CouponInstanceModel{
       var label=null;//balanceTotalQuotaLab
       var value='';
       if(cp&&cp.couponItem){//Cash_Discount====CASH_Discount
            // cp.is3rdCoupon=cp.id.indexOf('KpcEAE')>-1;//hard cord 3rd part coupon
            // cp.is3rdCoupon=cp.couponItem&&cp.couponItem.couponType=='Third_Party_Coupon';
            // var isChcekTransferStatus:boolean=(cp.status=='REDEEMABLE'||cp.status=='PENDING');
            // if(isChcekTransferStatus&&cp.couponItem.isTransferrable){
            //       cp.couponItem.isTransferrable=false;
            // }
            // cp.couponItem.description='哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈';
            // cp.couponItem.description=this.handleTextLen(cp.couponItem.description,3,42);
            // cp.couponItem.descriptionCht=this.handleTextLen(cp.couponItem.descriptionCht,3,45);
            this.setup3rdPartCouponFlag(cp);
            this.setupTranferableFlag(cp);
            if(isMoveTag){
                //console.log("couponItem:"+JSON.stringify(cp.couponItem))
                cp.couponItem.description=this.replaceElement(cp.couponItem.description);
                cp.couponItem.descriptionCht=this.replaceElement(cp.couponItem.descriptionCht);
            }
            if(cp.couponItem.couponType=='Cash_Discount'&&(cp.couponItem.cashDiscountType=='DP'||cp.couponItem.cashDiscountType=='DR')){

            }
            else if(cp.status=='PENDING'){

            }
            else if(cp.couponItem.couponType=='Cash_Discount'&&cp.status=='REDEEMABLE'&&cp.couponItem.cashDiscountType=='C'){
            // else if(cp.couponItem.couponType=='Cash_Discount'&&cp.status=='REDEEMABLE'){
                if(cp.couponItem.keepBalance){//case i
                    label='balanceTotalAmountLab';
                    value+=' $'+ComponentUtil.convertNumber(cp.remainingBalance)+' / $'+ComponentUtil.convertNumber(cp.couponItem.initBalance);
                }
                else{//case ii
                    label='balanceTotalAmountLab';
                    value+=' $'+ComponentUtil.convertNumber(cp.couponItem.initBalance)+' / $'+ComponentUtil.convertNumber(cp.couponItem.initBalance);
                }
           }
           else if(cp.couponItem.couponType=='Cash_Discount'&&cp.status=='REDEEMED'&&cp.couponItem.keepBalance==false&&cp.couponItem.cashDiscountType=='C'){
            //   &&cp.couponItem.keepBalance==false&&cp.cashDiscountType=='C'){
                label='balanceTotalAmountLab';
            //    value+='All amount used/$' + ComponentUtil.convertNumber(cp.couponItem.initBalance);//case iii
                value+=' $0 / $' + ComponentUtil.convertNumber(cp.couponItem.initBalance);//case iii
           }
           else if(cp.status=='REDEEMABLE'&&cp.couponItem.cashDiscountType!='C'){//case iv
        //    else if(cp.status=='REDEEMABLE'&&!cp.cashDiscountType){//case iv
               label='balanceTotalQuotaLab';
               value+=' '+ComponentUtil.convertNumber(cp.remainingUse)+' / '+ComponentUtil.convertNumber(cp.couponItem.redemptionQuota);
           }
           else if(cp.status=='REDEEMABLE'&&cp.couponItem.cashDiscountType!='C'){//case v
        //    else if(cp.status=='REDEEMABLE'&&!cp.cashDiscountType){//case v
               label='balanceTotalQuotaLab';
               value+=' '+ComponentUtil.convertNumber(cp.remainingUse)+' / '+ComponentUtil.convertNumber(cp.couponItem.redemptionQuota);
           }
        //    else if(cp.couponItem.couponType=='Gift_Coupon'){
        //        label='balanceTotalQuotaLab';
        //        value+=cp.remainingUse+'/'+cp.couponItem.redemptionQuota;
        //    }
           else if(cp.status=='REDEEMED'&&cp.couponItem.cashDiscountType!='C'){//case vi
        //    else if(cp.status=='REDEEMED'&&!cp.cashDiscountType){//case vi
               label='balanceTotalQuotaLab';
            //    value+='All Qutoas used/'+ComponentUtil.convertNumber(cp.couponItem.redemptionQuota);
               value+=' 0 / '+ComponentUtil.convertNumber(cp.couponItem.redemptionQuota);
           }
           cp.banlanceShowLabel=label;
           cp.banlanceShowValue=value;
       }
       return cp;
    }


    public static filterResult(allCouponList:CouponInstanceModel[],brand:string,status:string,type:string,name:string):CouponInstanceModel[]{
        window.scroll(0,0);
        console.log("brand:"+brand+";status:"+status+";type:"+type+";name:"+name);
        var couponList:CouponInstanceModel[]=allCouponList;
        if(couponList&&couponList.length>0){            
              if(ComponentUtil.checkSelectNotNull(name)){
                var temp:CouponInstanceModel[]=[];
                couponList.filter(b=>{//加搜索中文，nameCht,descriptionCht,tncCht
                  if(b&&b.couponItem)
                  {
                    //   console.log("sessionStorage.getItem"+sessionStorage.getItem("lang"))
                      //lang:string = sessionStorage.getItem("lang");
                      if(sessionStorage.getItem("lang")=='zh-Hk' ){
                        //如果是中文 对比中文
                        // console.log("MIKI ADD for Chinese")
                        if(b.couponItem.nameCht&&b.couponItem.nameCht.toUpperCase().indexOf(name.toUpperCase())>=0){//indexOf
                            temp.push(b);//add
                            }
                            else if(b.couponItem.descriptionCht&&b.couponItem.descriptionCht.toUpperCase().indexOf(name.toUpperCase())>=0){
                            temp.push(b);
                            }
                            else if(b.couponItem.tncCht&&b.couponItem.tncCht.toUpperCase().indexOf(name.toUpperCase())>=0){
                            temp.push(b);
                            }
                     
                    }else{
                             //如果是英文
                            // console.log("MIKI add for searching English")
                            if(b.couponItem.name&&b.couponItem.name.toUpperCase().indexOf(name.toUpperCase())>=0){//indexOf
                                temp.push(b);//add
                                }
                                else if(b.couponItem.description&&b.couponItem.description.toUpperCase().indexOf(name.toUpperCase())>=0){
                                temp.push(b);
                                }
                                else if(b.couponItem.tnc&&b.couponItem.tnc.toUpperCase().indexOf(name.toUpperCase())>=0){
                                temp.push(b);
                                }
                    }
                  }
                });
                couponList=temp;
              }

             if(ComponentUtil.checkSelectNotNull(brand)){                
                var temp:CouponInstanceModel[]=[];
                 couponList.filter(b=>{
                       if(b&&b.couponItem&&b.couponItem.issueBrand==brand){
                             temp.push(b);
                       }
                });
                couponList=temp;
             }

             if(ComponentUtil.checkSelectNotNull(type)){
                
                var temp:CouponInstanceModel[]=[];
                couponList.filter(b=>{
                  if(b&&b.couponItem&&b.couponItem.couponType==type){
                        temp.push(b);
                  }
                });
                couponList=temp;
             }
  
             if(ComponentUtil.checkSelectNotNull(status)&&(status!='All' 
             && status.indexOf('BOOKMARKED')==-1)){//by coco:bookmark show all coupons first,then use status to filter favroites by ngif in html                 
                var temp:CouponInstanceModel[]=[];
                 couponList.filter(b=>{
                  if(b&&b.status==status){
                        temp.push(b);
                  }
                 });
                  couponList=temp;
             }
             console.log("filter coupon list:");
             console.log(couponList);
             return couponList;
        }
    }
    
    public static filterResultWithFavriotes(allCouponList:CouponInstanceModel[],status:string,favorites:string):CouponInstanceModel[]{
        var couponList:CouponInstanceModel[]=allCouponList;
        var temp:CouponInstanceModel[]=[];
        couponList.filter(b=>{// add the favorite flag for all the cuopon data not only status=bookmark
            if( favorites && favorites.indexOf(b.couponCode)!=-1){
                    b.isFavorites = true;
            }else{
                    b.isFavorites = false;
            }
        });
        if(ComponentUtil.checkSelectNotNull(status)&& status.indexOf('BOOKMARKED')!=-1){//just show bookmark cuopon when click to bookmark status
            var temp:CouponInstanceModel[]=[];
            couponList.filter(b=>{
                if(favorites && favorites.indexOf(b.couponCode)!=-1){
                    console.log('couponCode:');
                    temp.push(b);
                }
            });
            couponList=temp;            
        }
        return couponList;
    }
    
    public convertStringArrayToMap(strArr:string[]):Map<string,number>{
        var map:Map<string,number>=null;
        if(strArr&&strArr.length>0){
           map=new Map<string,number>();
           for(var i=0;i<strArr.length;i++){
              map.set(strArr[i],1);
           } 
        }
        return map;
    }

    public static setup3rdPartCouponFlag(cp:CouponInstanceModel){
        cp.is3rdCoupon=cp.couponItem&&cp.couponItem.couponType=='Third_Party_Coupon';
        // cp.isShowToRedeem=false;
        // cp.is3rdCoupon=true;
        // cp.status='REDEEMED';
        // var pc:ProviderCouponModel=new ProviderCouponModel();
        // pc.couponCode='123';
        // pc.type='T';
        // pc.providerAlias='AAA';
        // pc.url='https://www.baidu.com';
        // cp.providerCoupon=pc;

        if(cp.is3rdCoupon){
            if(cp.status=='REDEEMABLE'||cp.status=='PENDING'){
                cp.isShowToRedeem=true;
            }
        }  
    }

    public static setupTranferableFlag(cp:CouponInstanceModel){
        if(cp.couponItem.isTransferrable){
            // var isChcekTransferStatus:boolean=(cp.status=='REDEEMABLE'||cp.status=='PENDING');
            // if(!isChcekTransferStatus&&cp.couponItem.isTransferrable){
                // cp.couponItem.isTransferrable=false;
            // }
            if(cp.status!='REDEEMABLE'&&cp.status!='PENDING'){
                cp.couponItem.isTransferrable=false;
            }
        }
    }

    public static handleTextLen(txt:string,row:number,rLen:number):string{
        var result:string=txt;
        if(txt.length>row*rLen){
            result=txt.substring(0,row*rLen)+'...';
        }
        return result;
    }

    public static replaceElement(txt:string):string{
        if (txt==null){
            return txt;
        }
        var ttMap:Map<string,string>=new Map<string,string>();
        ttMap.set('&nbsp;',' ');
        ttMap.set('&lt;','<');
        ttMap.set('&gt;','>');
        ttMap.set('&amp;','&');
        ttMap.set('&quot;','"');
        ttMap.set('&apos;',"'");
        ttMap.set('&cent;','￠');
        ttMap.set('&pound;','£');
        ttMap.set('&yen;','¥');
        ttMap.set('&euro;','€');
        ttMap.set('&sect;','§');
        ttMap.set('&copy;','©');
        ttMap.set('&reg;','®');
        ttMap.set('&trade;','™');
        ttMap.set('&times;','×');
        ttMap.set('&divide;','÷');
        ttMap.forEach(
           (v,k,map)=>{
            //    console.log(''+k+":"+v);
               var reg=new RegExp(k+'+',"ig");
               txt=txt.replace(reg,v);
           }
        );
        return txt.replace(/<.*?>/ig,'');
    }
    public static filter90DayAnd3rdPartyCoupon(couponList:CouponInstanceModel[], status:string):any{
        return couponList.filter(c=>{
            let pass = true;
            const validTo:number=new Date(c.validToResource).getTime();
            // remove the coupons: validTo + 90 day < now

            // if coupons status is unused
            if (status == 'REDEEMABLE,REDEEMED') {
                // status = unused or (3party bucket = unused AND validto >= today)
                pass = (c.status == 'REDEEMABLE' || (c.couponItem.thirdPartyBucket == 'Unused' && validTo >= Date.now())) || (c.couponItem.couponItemCode && !c.couponCode);
            }
            // if coupons status is used/expired
            else if(status == 'REDEEMED,EXPIRED') {
                // valid-to < today or (3 party bucket  != unused and status = used)
                pass = (validTo < Date.now() || (c.couponItem.thirdPartyBucket != 'Unused' && c.status == 'REDEEMED'));
            }
            return pass;
        });
    }
}

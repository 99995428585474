import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { CouponInstanceModel } from '../models/couponInstance.model'
import { Observable, throwError, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ApiUtil } from '../util/apiUtil';
import { JwtUtil } from '../util/jwtUtils';
import { environment  } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class EwalletMgmService{
    constructor(
        private http: HttpClient,
        private apiUtil:ApiUtil,
        private jwtUtil:JwtUtil,
     ) {}

     defaultHeader = { 'Content-Type': 'application/json' };

     private mgmUrl:string='/MGM/coupon/instance';

     private url:string=environment.apiUrl+this.mgmUrl;

     getShareContent(couponCode:string):Observable<any>{
      const httpOptions = {
        headers: new HttpHeaders({
         'Accept': '*/*',
         'ewAccessToken':this.jwtUtil.getAccessToken(),
       }),
      };
      var cc='';
      if(couponCode){
         cc='?couponCode='+couponCode;
      }
      return this.apiUtil.doGet(environment.apiUrl+'/ewallet/api/MGM/shareContent'+cc,httpOptions);
     }

     //old jwt
     getCouponList1(customerId:string):Observable<any>{
        const httpOptions = {
          headers: new HttpHeaders({
           'Accept': 'application/json',
           'accessToken':this.jwtUtil.getAccessToken(),
           'refreshToken':this.jwtUtil.getRefreshTocken(),
         }),
        };
        return this.apiUtil.doGet(this.url+'?customerId='+customerId+'&statusIncluded=ALL',httpOptions);
      }

      //new jwt
      getCouponList2(customerId:string):Observable<any>{
        var a:String=new String('response1');
        const httpOptions = {
          'headers': new HttpHeaders({
           'Accept': 'application/json',
          ewAccessToken:this.jwtUtil.getAccessToken(),
         }),
         observe:"response",
         responseType:"json",
         reportProgress:true
        };
        return this.apiUtil.doGet(this.url+'?customerId='+customerId+'&statusIncluded=ALL',httpOptions);
      }

      //old jwt
      getCouponDetail1(couponCode:string):Observable<any>{
          const httpOptions = {
               headers: new HttpHeaders({
                'Accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'accessToken':this.jwtUtil.getAccessToken(),
                'refreshToken':this.jwtUtil.getRefreshTocken(),
                })
             };
            return this.apiUtil.doGet(this.url+'/'+couponCode,httpOptions);
      }

      //new jwt
      getCouponDetail2(couponCode:string):Observable<any>{
        const httpOptions = {
             headers: new HttpHeaders({
              'Accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              ewAccessToken:this.jwtUtil.getAccessToken(),
              }),
              observe:"response",
              responseType:"json",
              reportProgress:true
           };
          return this.apiUtil.doGet(this.url+'/'+couponCode,httpOptions);
    }

    //main factory
    getCouponDetail(couponCode:string):Observable<any>{
      // if(localStorage.getItem("JWTMode")=="1"){
      //   return this.getCouponDetail1(couponCode);
      // }
      // else{
        return this.getCouponDetail2(couponCode);
      // }
    }

    //main factory
    getCouponList(customerId:string):Observable<any>{
      // if(localStorage.getItem("JWTMode")=="1"){
      //   console.log("1");
      //   return this.getCouponList1(customerId);
      // }
      // else{
      //   console.log("2");
        return this.getCouponList2(customerId);
      // }
    }
}
import { Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PopupMsgUtil{
    constructor(){}

    toast(msg:string,duration:number){
       if(!duration){
           duration=3000;
       }
       var m = document.createElement('div');
       var b=document.getElementById('body');
       var theme=b.getAttribute('class');
       if(theme){
         m.setAttribute("class","toast");
       }  
       m.innerText=msg;
       m.style.cssText="width:300px;min-width: 180px;opacity: 0.6;height: auto;min-height: 30px;line-height: 30px;text-align: center;border-radius: 9px;position: fixed;top: 90%;left:-webkit-calc(50%-150px);left:-moz-calc(50% - 150px);left: calc(50% - 150px);z-index: 999999;opacity:1;"; 
      document.body.appendChild(m);  
      setTimeout(function() {  
          var d = 0.5;  
          m.style.webkitTransition = '-webkit-transform ' + d + 's ease-in, opacity ' + d + 's ease-in';  
          m.style.opacity = '0';  
          setTimeout(function() { document.body.removeChild(m) }, d * 1000);  
      }, duration); 
    }

    static toast(msg:string,duration:number){
      if(!duration){
          duration=3000;
      }
      var m = document.createElement('div');
      var b=document.getElementById('body');
      var theme=b.getAttribute('class');
      if(theme){
        m.setAttribute("class","toast");
      }  
      m.innerText=msg;
      m.style.cssText="width:300px;min-width: 180px;opacity: 0.6;height: auto;min-height: 30px;line-height: 30px;text-align: center;border-radius: 9px;position: fixed;top: 14%;left:-webkit-calc(50%-150px);left:-moz-calc(50% - 150px);left: calc(50% - 150px);z-index: 999999;opacity:1;"; 
     document.body.appendChild(m);  
     setTimeout(function() {  
         var d = 0.5;  
         m.style.webkitTransition = '-webkit-transform ' + d + 's ease-in, opacity ' + d + 's ease-in';  
         m.style.opacity = '0';  
         setTimeout(function() { document.body.removeChild(m) }, d * 1000);  
     }, duration); 
   }

    loading(){
        // var loadingBtn=document.getElementById('loadingModalBtn');
        // loadingBtn.click();
        let alreadyLoading: boolean = false;
        try {
          var loadingElement = document.getElementById('loading');
          if (loadingElement) alreadyLoading = true;
        } catch (err) {
          console.log('loadingElement not found');
        }
        var m = document.createElement('img');
        m.setAttribute('src','/assets/common/image/loading.gif');
        m.setAttribute('width','50');
        m.setAttribute('height','50');
        m.setAttribute('id','loading');
        m.setAttribute('style','width:50px;height:50px;position:fixed;left:-webkit-calc(50%-25px);left:-moz-calc(50% - 25px); left:calc(50% - 25px);top:50%;z-index:9999;');
        // m.src('/assets/common/loading.gif');
        // m.width(50);
        // m.height(50);
        // m.id('loading');
        // m.style.cssText="position:absolute;left:-webkit-calc(50%-25px);left:-moz-calc(50% - 25px); left:calc(50% - 25px);top:170px;z-index:888;";
        !alreadyLoading && document.body.appendChild(m);
    }

    endLoading(){
        // var loadingBtn=document.getElementById('loadingModalEndBtn');
        // loadingBtn.click();
        var m=document.getElementById('loading');
        if(m){
          document.body.removeChild(m);
        }
    }
}
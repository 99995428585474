import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { EwalletListService } from '../../../data/service/ewalletList.service';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { LoginModel } from '../../../data/models/login.model';
import {TranslateService} from '@ngx-translate/core';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { ComponentUtil } from '../../../data/util/componentUtil';
import { RedemptionRecordItemModel } from '../../../data/models/redemptionRecordItem.model';
import { TransactionLogService } from '../../../data/service/transactionLog.service';
import { ErrHandler } from '../../../data/util/errHandler';
import { JqueryUiUtils } from 'src/assets/common/util/jquiutil';
import { JqueryUiPopupModel } from 'src/app/data/models/jquiPopup.mode';
import { TransferResultModel} from 'src/app/data/models/transferresult.model';
import { RedeemResultModel } from '../../../data/models/redeemResult.model';
import { ProviderCouponModel } from '../../../data/models/providerCoupon.model';
import { CouponListCommonUtil } from 'src/app/data/util/couponListCommonUtil';
import { QrCodeScanUtil } from '../../../../assets/common/util/qrcodescanutil';
import { ShareUtils } from '../../../../assets/common/util/shareutil';
import { ScanHandler } from '../../../data/util/scanHandler';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MyewalletStockAvailComponent } from '../myewallet-stock-avail/myewallet-stock-avail.component';
import { Base64Utils } from 'src/assets/common/util/base64util';
import { Clipboard } from '@angular/cdk/clipboard';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

@Component({
    selector: 'app-myewallet-couponDetail',
    templateUrl: './myewallet-couponDetail.component.html',
    styleUrls: ['./myewallet-couponDetail.component.scss']
  })
  export class MyEwalletCouponDetailComponent implements OnInit {

    @ViewChild(MyewalletStockAvailComponent) stockAvailComponent:MyewalletStockAvailComponent;
  
     showRedemChan:boolean=true;//Miki add 2021/06/17
     showRedemHis:boolean=true;
     showTermCondition:boolean=true;
     showRemarks:boolean=true;
     isUnderRedeem:boolean=false;

     @Input()
     page:string;

     _couponDetail:any=null;

     isSpringBoarCoupon:string=null;

     @Input('couponDetail')
      get couponDetail():any{
        return this._couponDetail;
      }

      set couponDetail(val:any){
        this._couponDetail=val;
        var couponItem:any;
        if(val){
          this.isSpringBoarCoupon=val.source;
          this._couponDetail.couponCode=val.couponCode;
          couponItem = val.couponItem;
          if(this.page!='microsite'){
            if(couponItem.description){
              couponItem.description = this.addAppUrlPrefix(couponItem.description);
            }
            if(couponItem.descriptionCht){
              couponItem.descriptionCht = this.addAppUrlPrefix(couponItem.descriptionCht);
            }
            if(couponItem.tnc){
              couponItem.tnc = this.addAppUrlPrefix(couponItem.tnc);
            }
            if(couponItem.tncCht){
              couponItem.tncCht = this.addAppUrlPrefix(couponItem.tncCht);
            }
          }
        
          if(couponItem && couponItem.issueBrand){
            var brand = couponItem.issueBrand.toLowerCase();
            if(brand.includes("csl")){
              this.logo_src = "/assets/common/image/icons/qrcode_logo_CSL.png"
            }else if(brand.includes("1010")||brand.includes("1o1o")){
              this.logo_src = "/assets/common/image/icons/qrcode_logo_1010.png"
            }else if(brand.includes("clubsim")){
              this.logo_src = "/assets/common/image/icons/qrcode_logo_ClubSim.png"
            }
          }
          this._couponDetail.couponItem = couponItem;   
        }
      }

    logo_src="/assets/common/image/icons/qrcode_logo_CSL.png";
    
    redemptionHists:RedemptionRecordItemModel[]=[];

    defaultLimit:number=10;

    nextOffset:number=0;

    totalNumber:number;

    channel:string;

    inputMobileNum:string;

    inputMobileValidateMsg:string;

    transferResult:string;

    transferErrMsg:string;

    isInitRedeem:boolean;

    isToRedeemPopupDonedRedeem:boolean;

    redeemResult:RedeemResultModel;

    appIssueBrand:string;

    appsUrlPrefix:string="";

    appsDescUrlPrefix:string="";

    lang:string = sessionStorage.getItem("lang");

    dateFormat:string;

    brandImageMap:Map<string,string>=new Map<string,string>();
    buttonCopyPng:string;
    
    originColorBrandlist:Array<string> = ['csl.','1O1O','HKT','Club Sim', 'NowTV'];

      ngOnInit() {
        if(!this.lang)
          this.route.queryParams.subscribe(params => {
            this.lang = params['lang'];
          });
        this.defaultLimit=this.componentUtil.getPageLimit();
        this.channel=this.jwtUtil.getSubServiceAppType();
        console.log('channel:'+this.channel);    
        this.inputMobileNum=null;
        this.inputMobileValidateMsg=null;
        this.transferResult=null;
        this.transferErrMsg=null;
        this.isToRedeemPopupDonedRedeem=false;
        this.isInitRedeem=true;
        this.translateService.get("momentDateFormat").subscribe(
          (res)=>{
              this.dateFormat=res;
          }
        );

        this.appIssueBrand = this.jwtUtil.getIssueBrandFromServiceAppType();
        if(this.appIssueBrand) {
          if (this.appIssueBrand=='CSL'){
            this.appsUrlPrefix = 'https://cslserviceapp.page.link/goTo=INAPPLINKLAYER-';
            this.appsDescUrlPrefix = 'https://cslserviceapp.page.link?goTo=LINK-';
          }else if (this.appIssueBrand=='1010'){
            this.appsUrlPrefix = 'https://1010serviceapp.page.link/goTo=INAPPLINKLAYER-';
            this.appsDescUrlPrefix = 'https://1010serviceapp.page.link?goTo=LINK-';
          }
          else if (this.appIssueBrand=='clubsim'){
            this.appsUrlPrefix = 'https://cspostpaidapp.page.link/goTo=INAPPLINKLAYER-';
            this.appsDescUrlPrefix = 'https://cspostpaidapp.page.link?goTo=LINK-';
          }
        }
        this.buttonCopyPng = this.jwtUtil.getButtonCopyPng(); 
      }

      sessionStorage(id) {
        return sessionStorage.getItem(id);
      }

      initCouponDetail(){
        console.log('detail init');
        this.isToRedeemPopupDonedRedeem=false;
        this.showRedemHis=true;
        this.showTermCondition=true;
        this.showRemarks=true;
        this.showRedemChan=true;//Miki add 2021/06/21
        var rh=document.getElementById('redemHisDetail');
        if(rh){
           rh.setAttribute('class','row collapse show');
        }
        document.getElementById('termConditionDetail').setAttribute('class','row collapse show');
        var remarkDocument=document.getElementById('remarksDetail');
        if(remarkDocument) {
          remarkDocument.setAttribute('class','row collapse show')
        } 
        var rc=document.getElementById('redemChanDetail');
        if(rc){
            rc.setAttribute('class','row collapse show');//Miki Add 2021/06/21
        }
        console.log(this.getCurrentCouponCode());
        if(this.getCurrentCouponCode()){
          this.searchRedemptionRecord(this.getCurrentCouponCode(),this.defaultLimit,0,true,this.page);
        }else if(this.couponDetail&&this.couponDetail.couponCode?(this.couponDetail.couponCode&& (this.page=='mgm' && this.isSpringBoarCoupon=='SB')):false) {
          this.searchRedemptionRecord(this.couponDetail.couponCode,this.defaultLimit,0,true,this.page);
        }
        if(this.page!='microsite'){
          var pm:JqueryUiPopupModel=new JqueryUiPopupModel();
          pm.autoOpen=false;
          pm.modal=true;
          pm.draggable=false;
          // pm.title='test';
          pm.zIndex=9990; 
          // pm.dropLayout=true;
          // pm.dropTop=false;
          // pm.dropPrecent=80;
          pm.diableCloseBtn=true;
          JqueryUiUtils.initPopup(this.page+'TransferForm',pm);
          JqueryUiUtils.initPopup(this.page+'ConfirmTransfer',pm);
          JqueryUiUtils.initPopup(this.page+'TransferResult',pm);

          pm.diableCloseBtn=false;
          JqueryUiUtils.initPopup(this.page+'ToRedeem',pm);
          JqueryUiUtils.initPopup(this.page+'WebOpenCamera',pm);

          pm.diableCloseBtn=true;
          // pm.dropLayout=false;
          pm.scanLayout=true;
          // pm.width=window.screen.availWidth;
          // pm.height=window.screen.availHeight;
          JqueryUiUtils.initPopup(this.page+'Scan',pm);

          
          pm.diableCloseBtn=false;
          pm.width=window.screen.availWidth;
          pm.height=window.screen.availHeight+10;
          JqueryUiUtils.initPopup(this.page+'StockAvail',pm);
        }
      }

      private getCurrentCouponCode():string{
          var result:string=null;
          var initCoupon=document.getElementById(this.page+'couponDetailInitCouponCode');
          if(initCoupon&&initCoupon.innerHTML){
            result=initCoupon.innerHTML;
          }
          return result;
      }

    constructor(
      private ewalletListService:EwalletListService,
      private route: ActivatedRoute,
      private router: Router,
      private location: Location,
      private errMsgUtil:ErrMsgUtil,
      private jwtUtil:JwtUtil,
      private popupMsgUtil:PopupMsgUtil,
      private translateService:TranslateService,
      private transactionLogService:TransactionLogService,
      private componentUtil:ComponentUtil,
      private zone:NgZone,
      private clipboard: Clipboard
    ){};

    //Miki add 2021/06/17

    clickCollapseRedemChan(){
      this.showRedemChan=!this.showRedemChan;
    }

    clickCollapseRedemHis(){
      this.showRedemHis=!this.showRedemHis;
    }

    clickCollapseTermCondition(){
      this.showTermCondition=!this.showTermCondition;
    }
    
    clickCollapseRemarks() {
      this.showRemarks=!this.showRemarks;
    }

     clickCopyCouponCode(){
       /*var ccn=document.getElementById(this.page+'3rdText');*/
      // var ccn=this.couponDetail.couponCode;
      //  const copyEl = this.elementRef.nativeElement.querySelector('#copyInput');
      /* const range=document.createRange();*/
      // console.log(ccn.cloneNode(true));
      //  range.selectNodeContents
      //  ccn.isSameNode
       /*range.selectNode(ccn);
       window.getSelection().removeAllRanges();
       window.getSelection().addRange(range);
       document.execCommand('copy');
       window.getSelection().removeAllRanges();*/
       this.clipboard.copy(this.couponDetail.providerCoupon.couponCode);
       this.translateService.get("msg.copySuccessMsg").subscribe(
          (res)=>{
            console.log(res);
            // this.popupMsgUtil.toast(res,2000);
            PopupMsgUtil.toast(res,3000);
          }
        );
       
     }

     clickCopyCouponDespText(){
     var shareContent=document.getElementById('shareModalContent');
     const range=document.createRange();
     range.selectNode(shareContent);
     window.getSelection().removeAllRanges();
     window.getSelection().addRange(range);
     document.execCommand('copy');
     window.getSelection().removeAllRanges();
     this.translateService.get("msg.copySuccessMsg").subscribe(
        (res)=>{
          console.log(res);
          this.popupMsgUtil.toast(res,2000);
        }
      );
     }


  clickMore(){
    if(this.getCurrentCouponCode()){
      this.searchRedemptionRecord(this.getCurrentCouponCode(),this.defaultLimit,this.nextOffset,false,this.page);
    }
  }


    searchRedemptionRecord(couponCode:string,limit:number,offset:number,reset:boolean,page:string){
      this.popupMsgUtil.loading();
      
     
      if(reset){
          this.redemptionHists=[];
          this.redemptionHists.length
      }
      console.log("Coupon Code:"+couponCode+";limit:"+limit+";offset:"+offset);
      this.transactionLogService.getRedemptionRecordsByCouponCode(limit,offset,couponCode,page,true).subscribe(
          (res)=>{
               this.nextOffset=offset+limit;
               console.log(res);
               res=this.jwtUtil.setupResponse(res);
               this.totalNumber=res.totalNumber;
               // this.redemptionHists.push(res.data);
               if(res.data){
                   var addDatas:RedemptionRecordItemModel[]=res.data;
                   if(addDatas&&addDatas.length>0){
                       for(var i=0;i<addDatas.length;i++){
                          if((addDatas[i].actionType=='RDM'&&addDatas[i].couponCode==couponCode)
                          ||addDatas[i].actionType=='RFS'){
                            // UTC plus 8 hours(convert to HK time)
                            if(addDatas[i].redemptionDate){
                              addDatas[i].redemptionDate = dayjs.utc(addDatas[i].redemptionDate).add(8,'hour').format('YYYY-MM-DD HH:mm:ss');
                            }

                            this.redemptionHists.push(addDatas[i]);
                          }
                          
                       }
                       console.log(this.redemptionHists);
                   }
               }
               this.popupMsgUtil.endLoading();
          }
          ,(err)=>{
           this.popupMsgUtil.endLoading();
           const _this=this;
           function getHandler():ErrHandler{
              let handler=<ErrHandler><unknown>function () { };
              handler.handleErr=function(){

              }
              handler.handleRefreshToken=function(){
                _this.searchRedemptionRecord(couponCode,limit,offset,reset,page);              
              }
              return handler;
           }
           var eh:ErrHandler=getHandler();
           this.errMsgUtil.handleErr(eh,err.error); 
          }
      );
   }

   clickCheckStock(){
    this.resetStockAvail();
    this.stockAvailComponent.initialPopup();
    this.popupStockAvail();
  }

  resetStockAvail(){
    this.stockAvailComponent.resetForm();
  }

  

  popupStockAvail(){
    // alert('a');
    JqueryUiUtils.showPopup(this.page+'StockAvail');
  }


   clickTransfer(){
    this.resetTransferForm();
    this.popupTransferForm();
    // this.popupConfirmTransfer();
    // this.popupTransferResult();
  }


  resetTransferForm(){
    this.inputMobileNum=null;
    this.inputMobileValidateMsg=null;
  }

  popupTransferForm(){
    // alert('a');
    JqueryUiUtils.showPopup(this.page+'TransferForm');
  }

  submitTransferForm(){
    if(this.inputMobileNum&&this.inputMobileNum.trim()!=''){
        this.inputMobileNum=this.inputMobileNum.trim();
        if(this.channel!='MyHKT'&&!this.inputMobileNum.match('^\\d{8}$')){
          this.inputMobileValidateMsg="mobileNumFormatWrong";
        }
        else{
          this.closeTransferForm();
          this.popupConfirmTransfer();
        }
    } 
    else{
      this.inputMobileValidateMsg="mobileNumRequired";
    }
  }

  closeTransferForm(){
    JqueryUiUtils.hidePopup(this.page+'TransferForm');
  }

  closeStockAvail(){
    JqueryUiUtils.hidePopup(this.page+'StockAvail');
  }

  popupConfirmTransfer(){
    JqueryUiUtils.showPopup(this.page+'ConfirmTransfer');
  }

  returnTransferForm(){
    this.closeConfirmTransfer();
    this.popupConfirmTransfer();
  }

  closeWebOpenCamera(){
    JqueryUiUtils.hidePopup(this.page+'WebOpenCamera');
  }

  closeConfirmTransfer(){
    JqueryUiUtils.hidePopup(this.page+'ConfirmTransfer');
  }

  submitConfirmTransfer(){
    this.transferResult=null;
    this.transferErrMsg=null;
    console.log("couponCd:"+this.couponDetail.couponCode+";mobileNum:"+this.inputMobileNum);
    this.popupMsgUtil.loading();
    this.ewalletListService.transfer(this.couponDetail.couponCode,this.inputMobileNum).subscribe(
      (res)=>{
        // console.log(res);
        res=this.jwtUtil.setupResponse(res);
        var r:TransferResultModel=res;
        console.log(r);
        if(r.code=='SUCCESS'){
           this.transferResult='transferResultSuccess';
        }
        else{
           this.transferResult='transferResultFailed';
           this.transferErrMsg=r.errorMsg;
        }
        this.closeConfirmTransfer();
        this.popupTransferResult();
        this.popupMsgUtil.endLoading();
      }
      ,(err)=>{
        this.popupMsgUtil.endLoading();
        const _this=this;
        function getHandler():ErrHandler{
           let handler=<ErrHandler><unknown>function () { };
           handler.handleErr=function(){

           }
           handler.handleRefreshToken=function(){
             _this.submitConfirmTransfer();            
           }
           return handler;
        }
        var eh:ErrHandler=getHandler();
        this.errMsgUtil.handleErr(eh,err.error);  
      }
    );
  }

  popupTransferResult(){
    JqueryUiUtils.showPopup(this.page+'TransferResult');
  }

  closeTransferResult(){
    if(this.transferResult=='transferResultSuccess'){
      window.location=window.location;
    }
    else{
       JqueryUiUtils.hidePopup(this.page+'TransferResult');
    }
  }

  clickToRedeem(){
    console.log(this.couponDetail);
    if(this.couponDetail.couponItem.TPCRedemptionMethod=='SB'){
      // this.isToRedeemPopupDonedRedeem=(this.couponDetail&&this.couponDetail.status=='REDEEMED');
      // JqueryUiUtils.showPopup(this.page+'ToRedeem');
      // if(this.isInitRedeem){
      //   var dragInitBtn=document.getElementById(this.page+'_dragInit');
      //   dragInitBtn.click();
      //   this.isInitRedeem=false;
      // }
      // var dragResetBtn=document.getElementById(this.page+'_dragRest');
      // dragResetBtn.click();
      // if(this.isToRedeemPopupDonedRedeem){ 
      //   var initCompleteBtn=document.getElementById(this.page+'_dragInitCompleted');
      //   initCompleteBtn.click();
      // }
      this.completeToRedeem();
    }
    else{
      this.clickToRedeemNew();
    }
  }

  clickToRedeemNew(){
    if(ShareUtils.isWebShare()){
      /* //No Scan in browser for web yet
      var scanInit=document.getElementById('scanInitBtn');
      scanInit.click();
      JqueryUiUtils.showPopup(this.page+'Scan');*/
      JqueryUiUtils.showPopup(this.page+'WebOpenCamera');
    }
    else{
        const _this=this;
        let handler=<ScanHandler><unknown>function () { };
        handler.handleScan=function(code:string){
             console.log(code);
             if(this.channel!='MyHKT') 
               code=Base64Utils.decode(code);
            _this.onScaned(code);
        };
        QrCodeScanUtil.nativeScan(handler);
    }
  }

  completeToRedeem(){
    this.isUnderRedeem=true;
    this.popupMsgUtil.loading();
    this.ewalletListService.redeem3PCoupon(this.couponDetail.couponCode,null).subscribe(
      (res)=>{
        // console.log(res);
        res=this.jwtUtil.setupResponse(res);
        var r:RedeemResultModel=res;
        console.log(r);
        JqueryUiUtils.hidePopup(this.page+'ToRedeem');
        if(r.code=='SUCCESS'){
          /**Add by Jeremy for CVP5 redeem code merge start  */
          this.couponDetail.status='REDEEMED';
          this.couponDetail.isShowToRedeem=false;
          // // this.couponDetail.providerCoupon=r.providerCoupon;
          // // console.log(r.providerCoupon);
          this.couponDetail.providerCoupon=new ProviderCouponModel();
          this.couponDetail.providerCoupon.url=r.url;
          this.couponDetail.providerCoupon.type=r.type;
          this.couponDetail.providerCoupon.couponCode=r.couponCode;
          this.couponDetail.providerCoupon.providerAlias=r.providerAlias;
          this.popupMsgUtil.endLoading();
          /**Add by Jeremy for CVP5 redeem code merge end  */
          this.ewalletListService.getCouponDetail(this.couponDetail.couponCode).subscribe(
            (res)=>{
              res=this.jwtUtil.setupResponse(res);
              // this.couponDetail=res;
              // this.couponDetail=CouponListCommonUtil.changeCouponDateFormat(res,this.dateFormat);
              // this.couponDetail=CouponListCommonUtil.changeCouponBanlanceShow(this.couponDetail,false);
              this.couponDetail = res;
              this._couponDetail=CouponListCommonUtil.changeCouponDateFormat(res,this.dateFormat);
              this._couponDetail=CouponListCommonUtil.changeCouponBanlanceShow(this.couponDetail,false);
              this.displayRedeemSuccess();
              if(this.couponDetail.providerCoupon&&this.couponDetail.providerCoupon.type=='L')this.clickLink();
            },
            (err)=>{
              const _this=this;
              function getHandler():ErrHandler{
                let handler=<ErrHandler><unknown>function () { };
                handler.handleErr=function(){
                  
                }
                handler.handleRefreshToken=function(){
                  this.zone.run(()=>{
                  _this.completeToRedeem();
                  })
                }
                return handler;
            }
            var eh:ErrHandler=getHandler();
            this.errMsgUtil.handleErr(eh,err.error);
              //this.popupMsgUtil.endLoading();
            }
          );
        }
        else{
          // this.errMsgUtil.popErrMsg(null,r.errorMsg);
          // this.errMsgUtil.popErrMsg(null,'Redemption failed. Please try again later or contact support.');
          // this.popupMsgUtil.toast('Redemption failed. Please try again later or contact support.',1000);
          // this.resetDrag();
          // this.popupMsgUtil.endLoading();
            var errMsg:string='Redemption failed. Please try again later or contact support.';
            var scanErrAlready:string;
            var scanErrCouponInvalid:string;
            var scanErrQrInvalid:string;
            this.translateService.get("scanErrAlready").subscribe(
              (res)=>{
                scanErrAlready=res;
              }
            );
            this.translateService.get("scanErrCouponInvalid").subscribe(
              (res)=>{
                scanErrCouponInvalid=res;
              }
            );
            this.translateService.get("scanErrQrInvalid").subscribe(
              (res)=>{
                scanErrQrInvalid=res;
              }
            );
            errMsg=scanErrQrInvalid;
            if(r.errorCode=='1'){
                errMsg=scanErrAlready;
            }
            else if(r.errorCode=='2'||r.errorCode=='3'||r.errorCode=='4'||r.errorCode=='5'||r.errorCode=='6'){
               errMsg=scanErrCouponInvalid;
            }
            else if(r.errorCode=='7'){
              errMsg=scanErrQrInvalid;
            }
            // this.errMsgUtil.popErrMsg(null,r.errorMsg);
            // this.errMsgUtil.popErrMsg(null,errMsg);
            this.resetDrag();
            PopupMsgUtil.toast(errMsg,3000);
            this.popupMsgUtil.endLoading();        
          }      
      }
      ,(err)=>{
        var errMsg:string;
        this.translateService.get("scanErrQrInvalid").subscribe(
          (res)=>{
            errMsg=res;
          }
        );
        JqueryUiUtils.hidePopup(this.page+'ToRedeem');
        this.popupMsgUtil.endLoading();
        this.resetDrag();
        const _this=this;
        function getHandler():ErrHandler{
           let handler=<ErrHandler><unknown>function () { };
           handler.handleErr=function(){

           }
          handler.isCustomError=function(){
             if(err.status==500||err.status==501){
               return true;
             }
             return false;
          }
          handler.handleCustomError=function(){
            //  ErrMsgUtil.popupMessage('QrCode format is Wrong!');
            // ErrMsgUtil.popupMessage(errMsg);
            PopupMsgUtil.toast(errMsg,3000);
         }
           handler.handleRefreshToken=function(){
            this.zone.run(()=>{
             _this.completeToRedeem();  
            })        
           }
           return handler;
        }
        var eh:ErrHandler=getHandler();
        this.errMsgUtil.handleErr(eh,err.error);  
        
      }
    );
    this.isUnderRedeem=false;
    // _dragInit
  }

  displayRedeemSuccess(){
    this.translateService.get("redeemSuccess").subscribe(
      (res)=>{
        PopupMsgUtil.toast(res,3000);
      }
    );
  }

  resetDrag(){
    var dragReset=document.getElementById(this.page+'_dragRest');
    // console.log(dragReset);
    dragReset.click();
    JqueryUiUtils.hidePopup(this.page+'ToRedeem');
  }

  clickLink(){
     var linka=document.getElementById(this.page+'linka');
     linka.click();
  }

  clickScanReturn(){
    console.log('return')
    JqueryUiUtils.hidePopup(this.page+'Scan');
  }

  addAppUrlPrefix(text){
    return text.replaceAll("href=\"","href=\""+this.appsDescUrlPrefix);
  }

  onScaned(code){
    JqueryUiUtils.hidePopup(this.page+'Scan');
    this.popupMsgUtil.loading();
    this.ewalletListService.redeem3PCoupon(this.couponDetail.couponCode,code).subscribe(
      (res)=>{
        // console.log(res);
        res=this.jwtUtil.setupResponse(res);
        var r:RedeemResultModel=res;
        console.log(r);
        if(r.code=='SUCCESS'){
          this.isToRedeemPopupDonedRedeem=true;
          this.couponDetail.status='REDEEMED';
          this.couponDetail.isShowToRedeem=false;
          //this.couponDetail.providerCoupon=null;
          this.couponDetail.providerCoupon=new ProviderCouponModel();
          this.couponDetail.providerCoupon.url=r.url;
          this.couponDetail.providerCoupon.type=r.type;
          this.couponDetail.providerCoupon.couponCode=r.couponCode;
          this.couponDetail.providerCoupon.providerAlias=r.providerAlias;
          this.popupMsgUtil.endLoading();

          console.log(r);
          console.log(this.couponDetail);
          this.ewalletListService.getCouponDetail(this.couponDetail.couponCode).subscribe(
            (res)=>{
              this.zone.run(()=>{
                res=this.jwtUtil.setupResponse(res);
                this.couponDetail=res;
                this.couponDetail=CouponListCommonUtil.changeCouponDateFormat(res,this.dateFormat);
                this.couponDetail=CouponListCommonUtil.changeCouponBanlanceShow(this.couponDetail,false);
                //this.popupMsgUtil.endLoading();
                this.displayRedeemSuccess();
              })
            },
            (err)=>{
              const _this=this;
              function getHandler():ErrHandler{
                let handler=<ErrHandler><unknown>function () { };
                handler.handleErr=function(){
                  
                }
                handler.handleRefreshToken=function(){
                  this.zone.run(()=>{
                    _this.onScaned(code);
                  })
                }
                return handler;
            }
            var eh:ErrHandler=getHandler();
            this.errMsgUtil.handleErr(eh,err.error);
              this.popupMsgUtil.endLoading();
            }
          )
            /**Add by Jeremy to sync CVP5 coding */
          if(this.getCurrentCouponCode()&&this.page=='coupon'){
            this.searchRedemptionRecord(this.getCurrentCouponCode(),this.defaultLimit,0,true,this.page);
          }
        }
        else{
          var errMsg:string='Redemption failed. Please try again later or contact support.';
          var scanErrAlready:string;
          var scanErrCouponInvalid:string;
          var scanErrQrInvalid:string;
          this.translateService.get("scanErrAlready").subscribe(
            (res)=>{
              scanErrAlready=res;
            }
          );
          this.translateService.get("scanErrCouponInvalid").subscribe(
            (res)=>{
              scanErrCouponInvalid=res;
            }
          );
          this.translateService.get("scanErrQrInvalid").subscribe(
            (res)=>{
              scanErrQrInvalid=res;
            }
          );
          errMsg=scanErrQrInvalid;
          if(r.errorCode=='1'){
              errMsg=scanErrAlready;
          }
          else if(r.errorCode=='2'||r.errorCode=='3'||r.errorCode=='4'||r.errorCode=='5'||r.errorCode=='6'){
             errMsg=scanErrCouponInvalid;
          }
          else if(r.errorCode=='7'){
            errMsg=scanErrQrInvalid;
          }
          // this.errMsgUtil.popErrMsg(null,r.errorMsg);
          // this.errMsgUtil.popErrMsg(null,errMsg);
          PopupMsgUtil.toast(errMsg,3000);
          this.popupMsgUtil.endLoading();        
        }
        var resetScan=document.getElementById('resetScanBtn');
        resetScan.click();
      }
      ,(err)=>{
        var errMsg:string;
        this.translateService.get("scanErrQrInvalid").subscribe(
          (res)=>{
            errMsg=res;
          }
        );
        this.popupMsgUtil.endLoading();
        const _this=this;
        function getHandler():ErrHandler{
           let handler=<ErrHandler><unknown>function () { };
           handler.handleErr=function(){

           }
           handler.handleRefreshToken=function(){
            this.zone.run(()=>{
               _this.onScaned(code);  
            })
           }
           handler.isCustomError=function(){
              if(err.status==500||err.status==501){
                return true;
              }
              return false;
           }
           handler.handleCustomError=function(){
              //  ErrMsgUtil.popupMessage('QrCode format is Wrong!');
              // ErrMsgUtil.popupMessage(errMsg);
              console.log('custom');
              PopupMsgUtil.toast(errMsg,3000);
           }
           return handler;
        }
        var eh:ErrHandler=getHandler();
        this.errMsgUtil.handleErr(eh,err.error);
        var resetScan=document.getElementById('resetScanBtn');
        resetScan.click();
      }
    );
  }
}
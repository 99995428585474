import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';
import { FormsModule }    from '@angular/forms';

import { QRCodeModule } from 'angularx-qrcode';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
// import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { CouponDetailComponent } from './coupon-detail/coupon-detail.component';
import { CouponListComponent } from './coupon-list/coupon-list.component';
import { NameCardComponent } from './name-card/name-card.component';
import { MyEwalletModule } from '../myewallet/myewallet.module';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      QRCodeModule,
      TranslateModule,
      BrowserModule,
      MyEwalletModule,
      // InfiniteScrollModule,
      // platformBrowserDynamic,
    ],
    declarations: [
        CouponDetailComponent,
        CouponListComponent,
        NameCardComponent,
        // MyEwalletNameCardComponent, 
    ]
  })
  export class MicrositeModule {}
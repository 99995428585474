<div id="{{page}}StatusDiv" class="horizontalScrollDiv" style="padding-top: 30px; padding-bottom: 10px; padding-left: 5px;padding-right:0px;margin-right:-27px">
  <!-- <button type="button" class="col-4 col-md-3 col-lg-2 btn filterBtn {{filterStatus=='REDEEMABLE'?'csbuttonFill':'csbuttonNoFill'}} couponStatusBtn" (click)="clickCouponStatus('REDEEMABLE')">{{'redeemable'|translate}}</button>
    <button type="button" class="col-3 col-md-3 col-lg-2 btn filterBtn {{filterStatus=='REDEEMED'?'csbuttonFill':'csbuttonNoFill'}} couponStatusBtn" (click)="clickCouponStatus('REDEEMED')">{{'redeemed'|translate}}</button>
    <button type="button" class="col-3 col-md-3 col-lg-2 btn filterBtn {{filterStatus=='EXPIRED'?'csbuttonFill':'csbuttonNoFill'}} couponStatusBtn" (click)="clickCouponStatus('EXPIRED')">{{'expired'|translate}}</button>
    <button style="{{env.threePartRelease==1?'display:none;':''}}" type="button" class="col-4 col-md-3 col-lg-2 btn filterBtn {{filterStatus=='BOOKMARKED'?'csbuttonFill':'csbuttonNoFill'}} couponStatusBtn" (click)="clickCouponStatus('BOOKMARKED')">{{'bookMarked'|translate}}</button>
    <button type="button" class="col-3 col-md-3 col-lg-2 btn filterBtn {{filterStatus=='PENDING'?'csbuttonFill':'csbuttonNoFill'}} couponStatusBtn" (click)="clickCouponStatus('PENDING')">{{'pending'|translate}}</button> -->
    <button type="button" class="col-3 col-md-3 col-lg-2 btn filterBtn {{filterStatus=='REDEEMABLE,REDEEMED'?'csbuttonFill':'csbuttonNoFill'}} couponStatusBtn" (click)="clickCouponStatus('REDEEMABLE,REDEEMED')">{{'redeemable'|translate}}</button>
    <button type="button" class="col-4 col-md-3 col-lg-2 btn filterBtn {{filterStatus=='REDEEMED,EXPIRED'?'csbuttonFill':'csbuttonNoFill'}} couponStatusBtn" (click)="clickCouponStatus('REDEEMED,EXPIRED')">{{'redeemed'|translate}} / {{'expired'|translate}}</button>
    <button type="button" class="col-3 col-md-3 col-lg-2 btn filterBtn {{filterStatus=='All'?'csbuttonFill':'csbuttonNoFill'}} couponStatusBtn" (click)="clickCouponStatus('All')">{{'all'|translate}}</button>
  </div>

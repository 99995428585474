import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { CouponInstanceModel } from '../models/couponInstance.model'
import { Observable, throwError, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ApiUtil } from '../util/apiUtil';
import { JwtUtil } from '../util/jwtUtils';
import { environment } from 'src/environments/environment';
import { TransactionLogReqModel } from '../models/transactionLogReq.model';


@Injectable({ providedIn: 'root' })
export class TransactionLogService{
    constructor(
        private http: HttpClient,
        private apiUtil:ApiUtil,
        private jwtUtil:JwtUtil,
     ) {}

     defaultHeader = { 'Content-Type': 'application/json' };

    private transcationUrl:string='/ewallet/api/trans';

    private url:string=environment.apiUrl+this.transcationUrl;

    // private key:string=this.configService.get('key');

    getRedemptionRecords(limit:number,offset:number,fromActionDate:string,toActionDate:string,category:string):Observable<any>{
        const httpOptions = {
          'headers': new HttpHeaders({
           'Accept': 'application/json',
          ewAccessToken:this.jwtUtil.getAccessToken(),
         }),
         observe:"response",
         responseType:"json",
         reportProgress:true
        };
        var req:TransactionLogReqModel=new TransactionLogReqModel();
        req.category=category;
        if(fromActionDate){
          req.fromActionDate=fromActionDate;
        }       
        req.toActionDate=toActionDate;
        req.lmt=limit;
        req.offset=offset;

        if(category=='M'){
          req.actionType=['RDM','RFS'];
        }else{
          req.actionType=['RDM','TFT'];
        }

        return this.apiUtil.doPost(this.url+'/redHist',req,httpOptions);
        // var fdStr=''
        // if(fromRedemptionDate){
        //    fdStr='&fromRedemptionDate='+fromRedemptionDate;
        // }
        // return this.apiUtil.doGet(this.url+'/redHist?limit='+limit+'&offset='+offset+fdStr+'&toRedemptionDate='+toRedemptionDate,httpOptions);
        

      }

      getRedemptionRecordsByCouponCode(limit:number,offset:number,couponCode:string,category:string,isDetail:boolean):Observable<any>{
        const httpOptions = {
          'headers': new HttpHeaders({
           'Accept': 'application/json',
          ewAccessToken:this.jwtUtil.getAccessToken(),
         }),
         observe:"response",
         responseType:"json",
         reportProgress:true
        };
        var req:TransactionLogReqModel=new TransactionLogReqModel();
        // req.category='C';
        // req.fromRedemptionDate=fromRedemptionDate;
        // req.toRedemptionDate=toRedemptionDate;
        req.lmt=limit;
        req.offset=offset;

        if(category=='mgm'){
          if(isDetail){
            req.actionType=['RDM','RFS'];
          }else{
            req.actionType=['RDM'];
          } 
          
        }else{
          req.actionType=['RDM','TFT'];
        }
        
        req.couponCode=couponCode;
        return this.apiUtil.doPost(this.url+'/redHist',req,httpOptions);
        // return this.apiUtil.doGet(this.url+'/redHist/'+couponCode+'?limit='+limit+'&offset='+offset,httpOptions);
      }
}
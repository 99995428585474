
<form class="form-signin text-center" >
    <!--<img class="logo mb-4" src="assets/common/crmx-logo-b.png" alt="CRMX" />-->
    <h1 class="h3 mb-3 font-weight-normal">{{'plsSignIn'|translate}}</h1>
    <div style="padding-top:10px;padding-left:10px;padding-right:10px;">
    <label for="inputEmail" class="sr-only">{{'userName'|translate}}</label>
    <input
      type="text"
      class="form-control"
      placeholder="{{'userName'|translate}}"
      requiredName
      autofocus
      name="loginId"
      [(ngModel)]="loginId"
    />
    <label for="inputPassword" class="sr-only">{{'password'|translate}}</label>
    <input type="password" id="inputPassword" name="pws" [(ngModel)]="pws" class="form-control" placeholder="{{'password'|translate}}" required />
    <div *ngIf="showMsg" class="alert alert-warning" role="alert">
      {{ msg|translate}}
    </div>
    </div>
    <!--<div style="padding-top:10px;padding-left:10px;padding-right:10px;">
      Web&nbsp;&nbsp;<input type="checkbox" [(ngModel)]="callWeb" name="callWeb"/> 
    </div>-->
    <div style="padding-top:10px;padding-left:10px;padding-right:10px;">
      <button class="btn btn-lg btn-primary btn-block" type="button" (click)="login({username: loginId, password: pws})">{{'signIn'|translate}}</button>
    </div>
     <input type="text" id="testDateRange" value="" style="width:100%;display: none;"/>

  </form>
   <!--
     <div class="checkbox mb-3">
      <label> <input type="checkbox" value="remember-me" /> Remember me </label>
    </div> 
    <div class="mb-3" style="position: fixed; bottom:0%;">
    <label> {{this.getVersion()}} </label>
  </div> -->
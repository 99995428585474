import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { Location } from '@angular/common';
// import { EwalletMgmService } from '../../../data/service/ewalletMgm.service';
import { EwalletListService } from '../../../data/service/ewalletList.service';
import { JwtService } from '../../../data/service/jwt.service';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { CustomerInfoModel } from '../../../data/models/customerInfo.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { LoginModel } from '../../../data/models/login.model';
import { ErrHandler } from '../../../data/util/errHandler';
import { ComponentUtil } from '../../../data/util/componentUtil';
import { CouponListCommonUtil } from '../../../data/util/couponListCommonUtil';
import { FavroitesService } from '../../../data/service/favroites.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

//options not use pikiable
@Component({
  selector: 'app-myewallet-mgm',
  templateUrl: './myewallet-mgm.component.html',
  styleUrls: ['./myewallet-mgm.component.scss']
})
export class MyEwalletMgmComponent implements OnInit {
  /**api use data */
  couponList: CouponInstanceModel[] = [];//page use result list
  allCouponList: CouponInstanceModel[] = [];//api return result list
  resultBrandMap: Map<string, number> = new Map<string, number>();//api return get the exist brand
  // selectCoupon:CouponInstanceModel=null;//coupon detail use data

  issueBrandList: string[] = [];

  /** filter variable */
  filterBrand: string;
  filterStatus: string;
  // filterType:string;
  // filterCouponName:string;

  /** touch event variable */
  touchStartY: number;
  touchEndY: number;

  /** component event variable */
  @Output()
  qrCode = new EventEmitter<any>();
  @Output()
  share = new EventEmitter<any>();
  @Output()
  popDetail = new EventEmitter<any>();
  @Input()
  activeTab: string;

  /** page flag  */
  showCouponList: boolean = false;
  channel: string;
  // showPickBtn:boolean=true;//pick function use not use now,remain for future pikiable++
  appIssueBrand: string;//use for myhkt not name card blankbar should diable
  favoritesString: string;

  /** storage use key*/
  private brandFilterKey: string = "myWallet_mgm_brands_filter";
  private typeFilterKey: string = "myWallet_mgm_type_filter";
  private statusFilterKey: string = "myWallet_mgm_status_filter";
  private nameFilterKey: string = "myWallet_mgm_name_filter";

  /**split page pram */
  defaultLimit: number = environment.couponPageLimit;
  nextOffset: number = 0;
  totalNumber: number = 0;
  currentY: number = 0;
  env = environment;
  dateFormat: string;
  isWeb: boolean;
  scrollLock: boolean;

  currentTab: string = 'myewallet-mgm';

  constructor(private router: Router, private ewalletListService: EwalletListService, private jwtService: JwtService
    , private location: Location, private errMsgUtil: ErrMsgUtil, private translateService: TranslateService, public jwtUtil: JwtUtil, private popupMsgUtil: PopupMsgUtil, private favroitesService: FavroitesService, private componentUtil: ComponentUtil,) { }

  ngOnInit() {
    this.channel = this.jwtUtil.getSubServiceAppType();
    this.appIssueBrand = this.jwtUtil.getIssueBrandFromServiceAppType();
    // this.selectCoupon=null;
    // var sv=document.getElementsByClassName('mgmScrollFreshView')[0];
    // sv.addEventListener("touchstart",this.onTouchStart,false);
    // sv.addEventListener("touchend",this.onTouchEnd,false);
    this.favoritesString = '';
    this.translateService.get("momentDateFormat").subscribe(
      (res) => {
        this.dateFormat = res;
      }
    );
    // if(this.currentTab == this.activeTab){
    //   this.initDetail();
    // }
  }

  init() {
    var sbf = sessionStorage.getItem(this.brandFilterKey);
    // var stf=sessionStorage.getItem(this.typeFilterKey);
    var ssf = sessionStorage.getItem(this.statusFilterKey);
    // var snf=sessionStorage.getItem(this.nameFilterKey);

    if (!ComponentUtil.checkSelectNotNull(ssf)) {
      //  sessionStorage.setItem(this.statusFilterKey,"REDEEMABLE");
      //  ssf="REDEEMABLE";
      sessionStorage.setItem(this.statusFilterKey, "REDEEMABLE,REDEEMED");
      ssf = "REDEEMABLE,REDEEMED";
    }
    console.log("session brand:" + sbf + ";type:" + null + ";status:" + ssf + ";name:" + null);

    this.filterBrand = sbf;
    // this.filterType=stf;
    this.filterStatus = ssf;
    this.scrollLock = false;
    // this.filterCouponName=null;

    // this.showPickBtn=true;//pikiable++
    this.isWeb = localStorage.getItem('env') ? localStorage.getItem('env') == 'web' : false;
    this.initDetail();
  }

  initDetail() {
    this.resultBrandMap.clear();
    this.allCouponList = [];
    this.couponList = [];
    this.showCouponList = false;
    this.searchCouponListNew(0, this.defaultLimit, this.filterBrand, true, this.filterStatus, (this.filterStatus == 'All' || this.filterStatus.indexOf('REDEEMABLE') > -1) ? true : false, true);
  }

  goDetail(coupon: CouponInstanceModel) {
    // if(!this.showPickBtn){//pikiable++
    //    return; 
    //  }
    if (coupon.source == "SB") {
      var a = { 'couponId': "", 'page': 'mgm', 'coupon': coupon }
    } else {
      var a = { 'couponId': coupon.couponCode, 'page': 'mgm', 'coupon': coupon }
    }
    this.popDetail.emit(a);
  }

  /* component method  start */
  //1 search bar
  searchCouponForCouponName() {
    // var sc=document.getElementById('searchCoupon');
    // console.log(sc);
    // this.filterCouponName=sc.getAttribute('ng-reflect-model');
    // if(!this.filterCouponName||this.filterCouponName==''){
    //    this.filterBrand=null;
    //    this.clickCouponStatus('All');
    //    sessionStorage.removeItem("myWallet_mgm_brand_filter");
    //    sessionStorage.removeItem("myWallet_mgm_status_filter");
    // }  
    // this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,null,null,null,this.filterCouponName);
  }

  //2 brand filter
  brandChange(event) {
    this.nextOffset = 0;
    sessionStorage.setItem(this.brandFilterKey, event);
    var brand: string = sessionStorage.getItem(this.brandFilterKey);
    var status: string = sessionStorage.getItem(this.statusFilterKey);
    this.searchCouponListNew(this.nextOffset, this.defaultLimit, brand, true, status, (status == 'All' || status.indexOf('REDEEMABLE') > -1) ? true : false, true);

    this.jwtUtil.setupSessionTime();
  }

  //3 status filter
  statusChange(event) {
    this.showCouponList = false;
    this.nextOffset = 0;
    sessionStorage.setItem(this.statusFilterKey, event);
    this.filterStatus = event;
    var brand: string = sessionStorage.getItem(this.brandFilterKey);
    var status: string = sessionStorage.getItem(this.statusFilterKey);
    this.searchCouponListNew(this.nextOffset, this.defaultLimit, brand, true, status, (status == 'All' || status.indexOf('REDEEMABLE') > -1) ? true : false, true);
    this.jwtUtil.setupSessionTime();
  }

  //5 name card event +
  clickQrCode() {
    this.qrCode.emit();
  }
  clickShare() {
    console.log(1);
    this.share.emit();
  }

  initMgmRewardHist() {
    var mrhBtn = document.getElementById('mgmRewardHistroyInitBtn');
    mrhBtn.click();
  }
  //5 name card event --

  getFavorites() { //by coco:get favorites coupon from backend
    var status: string = sessionStorage.getItem(this.statusFilterKey);
    this.favroitesService.getFavoritesList('1234').subscribe(
      (res) => {
        res = this.jwtUtil.setupResponse(res);
        console.log('favorites:' + JSON.stringify(res.favourites));
        this.favoritesString = JSON.stringify(res.favourites);
        this.favoritesString = this.favoritesString.replace(/"/g, '');
        this.favoritesString = this.favoritesString.replace('null', '');
        if (this.couponList) {
          this.couponList = CouponListCommonUtil.filterResultWithFavriotes(this.couponList, status, this.favoritesString);
        }
      }
      , (err) => {
        this.popupMsgUtil.endLoading();
        const _this = this;
        function getHandler(): ErrHandler {
          let handler = <ErrHandler><unknown>function () { };
          handler.handleErr = function () {

          }
          handler.handleRefreshToken = function () {
            _this.getFavorites();
          }
          return handler;
        }
        var eh: ErrHandler = getHandler();
        this.errMsgUtil.handleErr(eh, err.error);
      }
    );
  }

  fromChildFunc(data) {
    console.log('on click data:' + data);
    this.favoritesString = data;
    // this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,this.filterBrand,this.filterStatus,null,null);
    // if(this.couponList){
    //   this.couponList=CouponListCommonUtil.filterResultWithFavriotes(this.couponList,this.filterStatus,this.favoritesString);
    // }
  }

  searchCouponListNew(offset: number, limit: number, brand: string, reset: boolean, status: string, hasPromotional: boolean, hasSpringboard: boolean) {
    if (reset) {
      // this.resultBrandMap.clear();
      this.allCouponList = [];
      this.couponList = [];
    }
    this.popupMsgUtil.loading();

    const getList = (offsetCall: number) => {
      combineLatest(
        this.ewalletListService.getCouponList('M', offsetCall, limit, brand, status, hasPromotional, hasSpringboard),
      ).subscribe(
        ([res]) => {
          res = this.jwtUtil.setupResponse(res);
          console.log("original couponList")
          console.log(res);
          this.totalNumber = res.totalNumber;
          console.log(this.totalNumber);
          res = res.data;
          res = CouponListCommonUtil.setDayOffAndSort(res, this.resultBrandMap, this.dateFormat);
          console.log("after setDayOffAndSort couponList")
          console.log(res);
          console.log(this.resultBrandMap);

          if (this.resultBrandMap.size > 0) {
            var temp = [];
            for (const [key, value] of this.resultBrandMap.entries()) {
              if (key) {
                temp.push(key);
              }
            }
            this.appIssueBrand = this.jwtUtil.getIssueBrandFromServiceAppType();
            console.log(temp)
            if (this.appIssueBrand && temp.length > 0) {
              var appIssueBrand = this.appIssueBrand.toLowerCase();
              temp.sort(function (x, y) { return x.toLowerCase().includes(appIssueBrand) ? -1 : y.toLowerCase().includes(appIssueBrand) ? 1 : 0; });
              if (appIssueBrand == '1010') {
                appIssueBrand = '1o1o';
                temp.sort(function (x, y) { return x.toLowerCase().includes(appIssueBrand) ? -1 : y.toLowerCase().includes(appIssueBrand) ? 1 : 0; });
              }
            }

            this.issueBrandList = temp;
          }
          this.allCouponList = this.allCouponList.concat(res);
          console.log("couponList before filter")
          console.log(this.couponList);

          this.nextOffset = offsetCall + limit;
          const filterList = CouponListCommonUtil.filter90DayAnd3rdPartyCoupon(this.allCouponList, status);
          // if list is not enough and have not requested yet, call searchCoupon api
          if (filterList.length - this.couponList.length < limit && this.allCouponList.length < this.totalNumber) {
            getList(this.nextOffset);
          } else {
            this.couponList = filterList;
            console.log("couponList after filter")
            console.log(this.couponList);
            this.showCouponList = true;
            if (this.currentY > 0) {
              window.scroll(0, this.currentY);
              this.currentY = 0;
            }
            this.scrollLock = false;
            this.popupMsgUtil.endLoading();
          }
        }
        , (err) => {
          this.showCouponList = true;
          this.popupMsgUtil.endLoading();
          this.scrollLock = false;
          const _this = this;
          function getHandler(): ErrHandler {
            let handler = <ErrHandler><unknown>function () { };
            handler.handleErr = function () {

            }
            handler.handleRefreshToken = function () {
              _this.initDetail();
            }
            return handler;
          }
          var eh: ErrHandler = getHandler();
          this.errMsgUtil.handleErr(eh, err.error);
        }
      );
    }


    getList(offset);
  }

  moreCoupon() {
    var brand: string = sessionStorage.getItem(this.brandFilterKey);
    var status: string = sessionStorage.getItem(this.statusFilterKey);
    if (this.allCouponList.length > 0 && this.totalNumber > this.nextOffset) {
      this.currentY = window.scrollY;
      this.searchCouponListNew(this.nextOffset, this.defaultLimit, brand, false, status, false, false);
    }
  }
  openPromoCpnDetail(item: any) {
    var a = { 'couponId': item.couponCode, 'couponItemId': item.couponItem.couponItemCode, 'page': 'coupon', isFromPromotional: true, sourceTab: 'myewallet-mgm' };
    this.popDetail.emit(a);
  }

}

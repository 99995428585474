import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { CustomerInfoModel } from '../../../data/models/customerInfo.model';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { ShareUtils } from '../../../../assets/common/util/shareutil';
import { JwtService } from '../../../data/service/jwt.service';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { ErrHandler } from '../../../data/util/errHandler';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { ComponentUtil } from '../../../data/util/componentUtil';
import { EwalletMgmService } from '../../../data/service/ewalletMgm.service';
import { environment } from 'src/environments/environment';
import { Base64Utils } from 'src/assets/common/util/base64util';


@Component({
    selector: 'app-myewallet-nameCard',
    templateUrl: './myewallet-nameCard.component.html',
    styleUrls: ['./myewallet-nameCard.component.scss']
})
export class MyEwalletNameCardComponent implements OnInit {

    appImg:string;

    channel:string;

    @Input()
    customerInfo:CustomerInfoModel;

    @Input()
    tab:string;

    @Output()
    qrCode= new EventEmitter<any>();

    @Output()
    share= new EventEmitter<any>();

    env=environment;

    lang:string=sessionStorage.getItem("lang");

    constructor(private jwtUtil:JwtUtil,private jwtService:JwtService,private popupMsgUtil:PopupMsgUtil
      ,private errMsgUtil:ErrMsgUtil,private ewalletMgmService:EwalletMgmService, ){};

    ngOnInit() {
        this.appImg=this.jwtUtil.getAppImg();
        this.channel=this.jwtUtil.getSubServiceAppType();
        if(this.tab=='mgm'){
            this.initCustomerInfo();
        }
    }

    updateLogo(){
      // this.channel=this.jwtUtil.getSubServiceAppType();
      // this.appImg=this.jwtUtil.getAppImg();
      // console.log(this.appImg+":"+this.channel);
    }


    clickQrCode(){
        this.qrCode.emit();
    }

    onShare(){
      this.popupMsgUtil.loading(); 
      var langParam='';
      var shareUrl=environment.micrositeUrl;
      if(this.lang=='zh-HK'){
           langParam='&lang=zh-HK'
      }
      var typeParam='&type='+Base64Utils.encode(this.jwtUtil.getServiceAppType());
      this.ewalletMgmService.getShareContent(null).subscribe(
          (res)=>{
              var shareContentText=encodeURIComponent(res.shareContent);
              this.popupMsgUtil.endLoading();
              if(ShareUtils.isWebShare()){
                var shareContent=document.getElementById('shareModalContent');
                shareContent.innerHTML=shareUrl+"/mgm?code="+shareContentText+langParam+typeParam;
                // shareContent.innerHTML='aa445 refers you a fabulous offer from csl! Hello! I would like to share you the newest special offer from csl! ('+shareUrl+'/mgm?code='+shareContentText+langParam+typeParam+')';
                ComponentUtil.copyToClipBoard(shareUrl+"/mgm?code="+shareContentText+langParam+typeParam);
                this.share.emit(); 
              }
              else{
                var lang=sessionStorage.getItem('lang');
                var shareUrlMessage = shareUrl +"/mgm?code="+shareContentText+langParam+typeParam;
                if(!lang){lang='en';}
                ShareUtils.share('Name Card share','Hello! I would like to share you the newest special offer! (URL)',shareUrlMessage,lang);
              }
          }
          ,(err)=>{
              console.log(err);
              this.popupMsgUtil.endLoading();
              const _this=this;
              function getHandler():ErrHandler{
                 let handler=<ErrHandler><unknown>function () { };
                 handler.handleErr=function(){
   
                 }
                 handler.handleRefreshToken=function(){
                   _this.onShare();               
                 }
                 return handler;
              }
              var eh:ErrHandler=getHandler();
              this.errMsgUtil.handleErr(eh,err.error);  
             }
          );
     }

     historyPopup(id:string){
         var popupBtn=document.getElementById(id);
         popupBtn.click(); 
     }

     initCustomerInfo(){
        // if(localStorage.getItem("JWTMode")=="1"){
        //   //   this.popupMsgUtil.endLoading();
        //   this.customerInfo=new CustomerInfoModel();
        //   this.customerInfo.nickname='ZhangFei';
        //   this.customerInfo.mrt='12345678';
        //   this.customerInfo.imageUrl='http://uat.sdeweb.hkcsl.com/cs/profile/?hash=eyJhbGciOiJIUzI1NiJ9.eyJhY3Rpb24iOjIwMCwibXNpc2RuIjo1NDcwMzgwOH0.iF6mvb9J0pLx3-E1-9PI6pETOwExpDellZOkNV3JMXA';
        // }
        // else{
            // this.popupMsgUtil.loading();
            this.jwtService.getCustomerInfo().subscribe(
              (res)=>{
                this.customerInfo=res;
                // this.popupMsgUtil.endLoading();
              },(err)=>{
                  console.log(err);
                //   this.popupMsgUtil.endLoading();
                  const _this=this;
                  function getHandler():ErrHandler{
                    let handler=<ErrHandler><unknown>function () { };
                    handler.handleErr=function(){
      
                    }
                    handler.handleRefreshToken=function(){
                      _this.ngOnInit();                
                    }
                    return handler;
                  }
                  var eh:ErrHandler=getHandler();
                  this.errMsgUtil.handleErr(eh,err.error);  
              }
          );
        // }
      }
}
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { CouponInstanceModel } from '../models/couponInstance.model'
import { Observable, throwError, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ApiUtil } from '../util/apiUtil';
import { JwtUtil } from '../util/jwtUtils';
import { environment } from 'src/environments/environment';
import { CouponListRequestModel } from '../models/couponListRequest.model';
import { RequestSourceModel } from '../models/requestSource.model';
import { RedeemRequestModel } from '../models/redeemRequest.model';
import { RequestOrderModel } from '../models/requestOrder.model';
import { DateRangePickerUtils } from 'src/assets/common/util/daterangepickerutil';


@Injectable({ providedIn: 'root' })
export class EwalletListService{
       constructor(
          private http: HttpClient,
          private apiUtil:ApiUtil,
          private jwtUtil:JwtUtil,
       ) {}

       defaultHeader = { 'Content-Type': 'application/json' };

      private couponListUrl:string='/ewallet/api/coupon/instance';

      private transferUrl:string='/ewallet/api/coupons/transfer';

      private redeeemUrl:string='/ewallet/api/coupons/redeem';

      private couponItemUrl:string='/ewallet/api/coupons/items/'

      private url:string=environment.apiUrl+this.couponListUrl;

      getSearchCouponList(){
        // https://e-wallet-coupon-backend.herokuapp.com
        var url=environment.apiUrl+'/ewallet/api/coupon/instance/all?statusIncluded=ALL';
        var a:String=new String('response1');
        const httpOptions = {
          'headers': new HttpHeaders({
           'Accept': 'application/json',
           ewAccessToken:this.jwtUtil.getAccessToken(),
         }),
         observe:"response",
         responseType:"json",
         reportProgress:true
        };
        return this.apiUtil.doGet(url,httpOptions);
      }

      //old jwt refresh token method
      refresh():Observable<any>{
        var url=environment.apiUrl+'/ewallet/api';
        return this.apiUtil.refresh(url);
      }

      //old jwt
      getCouponList1():Observable<any>{
        const httpOptions = {
          headers: new HttpHeaders({
           'Accept': 'application/json',
           'accessToken':this.jwtUtil.getAccessToken(),
           'refreshToken':this.jwtUtil.getRefreshTocken(),
         }),
        };
        return this.apiUtil.doGet(this.url+'?statusIncluded=ALL',httpOptions);
      }

      //new jwt
      getCouponList(category:string,offset:number,lmt:number,brand:string,status:string,hasPromotional:boolean,hasSpringboard:boolean):Observable<any>{
        var a:String=new String('response1');
        const httpOptions = {
          'headers': new HttpHeaders({
           'Accept': 'application/json',
           ewAccessToken:this.jwtUtil.getAccessToken(),
         }),
         observe:"response",
         responseType:"json",
         reportProgress:true
        };
        // return this.apiUtil.doGet(this.url+'?statusIncluded=ALL&category='+category,httpOptions);
        var srcReq:RequestSourceModel=new RequestSourceModel();
        srcReq.sys='COUPON';
        var req:CouponListRequestModel=new CouponListRequestModel();
        // req.custType='MRT';
        // req.custId='91234567';
        req.statusIncluded='ALL';
        console.log(status);
        if(status){
          req.statusIncluded=(status=='All'||status=='BOOKMARKED')?'ALL':status;
          console.log(req);
        }
        req.issueBrand=brand;
        if(brand==''||brand==null) {
          req.issueBrand='ALL'
        }
        req.category=category;
        req.hasPromotional=hasPromotional;
        req.hasSpringboard=hasSpringboard;
        req.source=srcReq;
        req.offset=offset;
        req.lmt=lmt;
        return this.apiUtil.doPost(this.url,req,httpOptions);
      }

      //old jwt
      getCouponDetail1(couponCode:string):Observable<any>{
          const httpOptions = {
               headers: new HttpHeaders({
                'Accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'accessToken':this.jwtUtil.getAccessToken(),
                'refreshToken':this.jwtUtil.getRefreshTocken(),
                })
             };
            return this.apiUtil.doGet(this.url+'/'+couponCode,httpOptions);
      }

      //new jwt
      getCouponDetail2(couponCode:string):Observable<any>{
        const httpOptions = {
             headers: new HttpHeaders({
              'Accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              ewAccessToken:this.jwtUtil.getAccessToken(),
              }),
              observe:"response",
              responseType:"json",
              reportProgress:true
           };
          return this.apiUtil.doGet(this.url+'/'+couponCode,httpOptions);
    }

    //main factory
    getCouponDetail(couponCode:string):Observable<any>{
      // if(localStorage.getItem("JWTMode")=="1"){
      //   return this.getCouponDetail1(couponCode);
      // }
      // else{
        return this.getCouponDetail2(couponCode);
      // }
    }

    transfer(couponCode:string,custId:string):Observable<any>{
      const httpOptions = {
        headers: new HttpHeaders({
         'Accept': 'application/json',
         'Access-Control-Allow-Origin':'*',
         ewAccessToken:this.jwtUtil.getAccessToken(),
         }),
         observe:"response",
         responseType:"json",
         reportProgress:true
      };
      return this.apiUtil.doPut(environment.apiUrl+this.transferUrl+'?couponCode='+couponCode+'&custId='+custId,null,httpOptions)
    }

    redeem3PCoupon(couponCode:string,qrcode:string){
      const httpOptions = {
        'headers': new HttpHeaders({
         'Accept': 'application/json',
         ewAccessToken:this.jwtUtil.getAccessToken(),
       }),
       observe:"response",
       responseType:"json",
       reportProgress:true
      };
      // return this.apiUtil.doGet(this.url+'?statusIncluded=ALL&category='+category,httpOptions);
      var srcReq:RequestSourceModel=new RequestSourceModel();
      srcReq.sys='COUPON';
      // var orderReq:RequestOrderModel=new RequestOrderModel();
      var req:RedeemRequestModel=new RedeemRequestModel();
      req.couponCode=couponCode;
      req.redeemMethod='SB';
      if(qrcode){
        req.qrCode=qrcode;
        req.redeemMethod='SMC';
      }
      req.redeemDate=DateRangePickerUtils.getNow('YYYY-MM-DD HH:mm:ss');
      req.source=srcReq;
      return this.apiUtil.doPut(environment.apiUrl+this.redeeemUrl+'/3PCoupon',req,httpOptions);
    }

    getCouponItemDetail(couponItemCode){
      const httpOptions = {
           headers: new HttpHeaders({
            'Accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            ewAccessToken:this.jwtUtil.getAccessToken(),
            }),
            observe:"response",
            responseType:"json",
            reportProgress:true
         };
        return this.apiUtil.doGet(environment.apiUrl+this.couponItemUrl+couponItemCode,httpOptions);
    }

    // redeem(couponCode:string){
    //   const httpOptions = {
    //     'headers': new HttpHeaders({
    //      'Accept': 'application/json',
    //      ewAccessToken:this.jwtUtil.getAccessToken(),
    //    }),
    //    observe:"response",
    //    responseType:"json",
    //    reportProgress:true
    //   };
    //   // return this.apiUtil.doGet(this.url+'?statusIncluded=ALL&category='+category,httpOptions);
    //   var srcReq:RequestSourceModel=new RequestSourceModel();
    //   srcReq.sys='COUPON';
    //   // var orderReq:RequestOrderModel=new RequestOrderModel();
    //   var req:RedeemRequestModel=new RedeemRequestModel();
    //   req.couponCode=couponCode;
    //   req.redeemDate=DateRangePickerUtils.getNow('YYYY-MM-DD HH:mm:ss');
    //   req.source=srcReq;
    //   return this.apiUtil.doPut(environment.apiUrl+this.redeeemUrl,req,httpOptions);
    // }
}
import { RequestSourceModel } from './requestSource.model';
import { RequestOrderModel } from './requestOrder.model';

export class CreateCouponReq{
    balance?:number;
    couponItemCode?:string;
    createDate?:string;
    custId?:string;
    custType?:string;
    grantOrderType?:string;
    mappedRule?:string;
    order?:RequestOrderModel;
    source?:RequestSourceModel;
}
import { Component, OnInit,OnDestroy, ErrorHandler,Input,Output,EventEmitter } from '@angular/core';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { ShareUtils } from '../../../../assets/common/util/shareutil';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { ComponentUtil } from '../../../data/util/componentUtil';
import { EwalletMgmService } from '../../../data/service/ewalletMgm.service';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { ErrHandler } from '../../../data/util/errHandler';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { environment } from '../../../../environments/environment';
import { Base64Utils } from '../../../../assets/common/util/base64util';
import { FavroitesService } from '../../../data/service/favroites.service';


@Component({
    selector: 'app-myewallet-search-couponCard', 
    // directives: [ InfiniteScroll ],
    templateUrl: './myewallet-search-couponcard-component.html',
    styleUrls: ['./myewallet-search-couponcard-component.scss']
})
export class MyEwalletSearchCouponCardComponent implements OnInit{

    @Input()
    tab:string;

    @Output()
    share= new EventEmitter<any>();

    @Output()
    openDetail= new EventEmitter<any>();

    @Input()
    cp:CouponInstanceModel=null;

    @Input()
    favoritesString:string;

    @Output() 
    favoritesStringChanged = new EventEmitter<any>();

    channel:string;

    lang:string=sessionStorage.getItem("lang");

    constructor(private jwtUtil:JwtUtil,private ewalletMgmService:EwalletMgmService
        ,private favroitesService:FavroitesService
        ,private popupMsgUtil:PopupMsgUtil,private errMsgUtil:ErrMsgUtil,){

    }

    ngOnInit() {
        this.channel=this.jwtUtil.getSubServiceAppType();
    }

    onShare(){
        var langParam='';
        var shareUrl=environment.micrositeUrl;
        if(this.lang=='zh-HK'){
             langParam='&lang=zh-HK'
        }
        var typeParam='&type='+Base64Utils.encode(this.jwtUtil.getServiceAppType());
        if(this.cp&&this.cp.couponCode&&this.cp.refereeCouponCode){
            this.popupMsgUtil.loading(); 
            // this.ewalletMgmService.getShareContent(this.cp.couponCode).subscribe(
            this.ewalletMgmService.getShareContent(this.cp.refereeCouponCode).subscribe(
                (res)=>{
                    var shareContentText=res.shareContent;
                    this.popupMsgUtil.endLoading();
                    console.log(res);
                    if(ShareUtils.isWebShare()){
                        var shareContent=document.getElementById('shareModalContent');
                        shareContent.innerHTML=shareUrl+"/mgm/coupon?code="+shareContentText+langParam+typeParam;
                        // shareContent.innerHTML='aa445 refers you a fabulous offer from csl! Hello! I would like to share you the newest special offer from csl! ('+shareUrl+'/mgm/coupon?code='+shareContentText+langParam+typeParam+')';
                        ComponentUtil.copyToClipBoard(shareUrl+"/mgm/coupon?code="+shareContentText+langParam+typeParam);
                        this.share.emit();
                      }
                      else{
                        var lang=sessionStorage.getItem('lang');
                        var shareUrlMessage = shareUrl+"/mgm/coupon?code="+shareContentText+langParam+typeParam;
                        if(!lang){lang='en';}
                        ShareUtils.share('','Hello! I would like to share you the newest special offer! (URL)',shareUrlMessage,lang);
                      }
                }
                ,(err)=>{
                    console.log(err);
                    this.popupMsgUtil.endLoading();
                    const _this=this;
                    function getHandler():ErrHandler{
                       let handler=<ErrHandler><unknown>function () { };
                       handler.handleErr=function(){
         
                       }
                       handler.handleRefreshToken=function(){
                         _this.onShare();               
                       }
                       return handler;
                    }
                    var eh:ErrHandler=getHandler();
                    this.errMsgUtil.handleErr(eh,err.error);  
                   }
                );
        }
        else{
            this.errMsgUtil.popupMsg('','',"Coupon Code not found!",'','');
        }
        


    }

    onOpenDetail(){
        this.openDetail.emit();
    }

    onBookMark(bookmarkFlag:string){
        var customerId:string='0011s00000O3SsFAAV';
        if(bookmarkFlag == 'Y'){//by coco:current unable, change to enable,add value to favoritesString;
            if(this.favoritesString.length> 0){
                this.favoritesString = this.favoritesString + ',';
            }
            this.favoritesString = this.favoritesString + this.cp.couponCode;
        }else{
            var fav1 = this.favoritesString;
            var fav2 = fav1.split(",");
            fav2.forEach( (item, index) => {
                if(item === this.cp.couponCode) fav2.splice(index,1);
            });
            this.favoritesString = fav2.join(',');
        }
        this.favoritesStringChanged.emit(this.favoritesString);
        console.log('post favorites: ' + this.favoritesString);
        //this.putFavorites(customerId,this.favoritesString);
    }


    putFavorites(customerId,favourites){//by coco:put favorites coupon to backend
        this.favroitesService.putFavoritesList(customerId,favourites).subscribe(
          (res)=>{
            console.log('favorites:'+ res);
          }
          ,(err)=>{
               this.popupMsgUtil.endLoading();
               const _this=this;
               function getHandler():ErrHandler{
                  let handler=<ErrHandler><unknown>function () { };
                  handler.handleErr=function(){
    
                  }
                  handler.handleRefreshToken=function(){
                    //_this.initDetail(customerId);              8
                  }
                  return handler;
               }
               var eh:ErrHandler=getHandler();
               this.errMsgUtil.handleErr(eh,err.error);            
             }
          );
      }
}
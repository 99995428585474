import { Injectable } from '@angular/core';
import {
 CanActivate,
 Router,
 ActivatedRouteSnapshot,
 ActivatedRoute,
 RouterStateSnapshot,
 CanActivateChild,
 CanLoad,
 CanDeactivate,
 NavigationStart,
 RouterEvent,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Route } from '@angular/compiler/src/core';
import { ErrMsgUtil } from '../data/util/errMsgUtil';
import { JwtUtil } from '../data/util/jwtUtils';
import { PopupMsgUtil } from '../data/util/popupMsgUtil';
import { JwtService } from '../data/service/jwt.service';
import { Base64Utils } from '../../assets/common/util/base64util';
import { ComponentUtil } from '../data/util/componentUtil';
import { Observable } from 'rxjs';
import { HeaderDataModel } from '../data/models/headerData.model';
import { environment } from 'src/environments/environment';
import { BrandImageModel } from '../data/models/brandimage.model';
import { TranslateService } from '@ngx-translate/core';

declare var goBackAction:any;

@Injectable({ providedIn: 'root' })
// export class AuthGuard implements CanActivate, CanActivateChild, CanLoad, CanDeactivate<any> {
export class AuthGuard implements CanActivate {
    constructor(
     private router: Router,
     public activateRoute:ActivatedRoute,
     private errMsgUtil:ErrMsgUtil,
     private jwtUtil:JwtUtil,
     private popupMsgUtil:PopupMsgUtil,
     public JwtService:JwtService,
     private http: HttpClient,
     private translateService:TranslateService,
    ) {
    
    }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      const body=document.getElementsByTagName("body")[0];
      body.setAttribute("style","");
      window.scroll(0,0);

      var isHeader=environment.headerMode;//is header get key only
      var lang=route.queryParamMap.get('lang');//setup i18n
      let tab=route.queryParamMap.get('tab');//setup tab
      if(lang){
        if(lang=='en'||lang=='zh-HK'){
          sessionStorage.setItem('lang',lang);
          this.translateService.use(lang);
        }
      }
      if(tab) {
        sessionStorage.setItem('tab', tab);    
      }
      if(route.url&&route.url.length>0&&(route.url[0].path=='myewallet'||route.url[0].path=='')){
        var tokenParam:string=null;   
        if(!isHeader){
            tokenParam=route.queryParamMap.get('serviceAppToken');
        }
        var timestamp=route.queryParamMap.get('timestamp');
        if(timestamp){
          if(this.jwtUtil.getGetTokenStatus()){
            console.log('get the token status:'+isHeader+";"+tokenParam);
            this.jwtUtil.removeGetTokenStatus();
            return true;
          }else{
            console.log('no token case');
            this.getSessionToken(tokenParam);
            return false;
          }
        }else{
          this.jwtUtil.setGetTokenStatus();
          console.log('refesh case');
          this.refreshPage();
          return true;
        }
      }else{
        return this.pageKeyCheck();
      }
  }

  pageKeyCheck():boolean{//other page check the token exist
      if(!this.jwtUtil.getServiceAppType()||!this.jwtUtil.getServiceAppToken()){
          // console.log("new jwt not param");
          this.errMsgUtil.popErrMsg(null,"Request failed,please try again.",'');
          return false;
      }
      else{
        return true;
      }
  }
  callValidate(){
    // this.router.navigateByUrl('/pending');
    // console.log("validate")
    this.popupMsgUtil.loading();
    this.jwtUtil.setValidateStatus();
    this.JwtService.validate().subscribe(
      (res)=>{
        this.jwtUtil.removeValidateStatus();
        this.popupMsgUtil.endLoading();
        this.jwtUtil.setAccessToken(res.accessToken);
        this.jwtUtil.setRefreshTocken(res.refreshToken);
        this.setAppStyle();
        // this.router.navigateByUrl('/myewallet');
        this.JwtService.getIconImage().subscribe(
          (res)=>{
            res=this.jwtUtil.setupResponse(res);
            // console.log(res);
            var r:BrandImageModel[]=res.data;
            for(var i=0;i<r.length;i++){
              var bi:BrandImageModel=r[i];
              let brandName = bi.name;
              if(brandName=='NowE'){
                brandName='Now E';
              }else if(brandName=='NowTV'){
                brandName='Now TV';
              }
              sessionStorage.setItem(brandName,bi.brandLogoSmall);
              sessionStorage.setItem('couponCard-'+brandName,bi.couponCardLogo);
              sessionStorage.setItem('brandFilter-'+brandName,bi.brandFilterLogo);
            }
              this.refreshPage();
          },
          (err)=>{
            this.authErrMsg(err.error);
          }
        )
      },(err) => {
          this.jwtUtil.removeValidateStatus();
          console.log(err);
          this.popupMsgUtil.endLoading();
          this.authErrMsg(err.error);
        }
    );
  }

  authErrMsg(err:any){
    var errorMsg;
    var tokenMsg;
    this.translateService.get('systemError').subscribe(
      (res)=>{
        errorMsg=res;
      }
    );

    this.translateService.get('tokenInvalid').subscribe(
      (res)=>{
        tokenMsg=res;
      }
    );
    if(err.status==401||err.status==0){            
      if(err.errors
        &&(err.errors.code== 'SERVICE_EXP'||err.errors.code== 'SERVICE_SIGN_KEY_ERROR'
        ||err.errors.code== 'SERVICE_DECODE_ERROR'||err.errors.code== 'SERVICE_AES_SIGN_KEY_ERROR')) 
      {
        this.errMsgUtil.popErrMsg(err,'','tokenInvalid');
      }else if(err.errors.code== 'RFH_EXP') {
        this.errMsgUtil.popErrMsg(err,'','sessionTimeout')
      }else {
        this.errMsgUtil.popErrMsg(err,'','tokenInvalid');
      }
    }else if(err.status==504||err.status==500||err.status==400){
      this.errMsgUtil.popErrMsg(err,'','');
    }
    else{
      this.errMsgUtil.popErrMsg(err,errorMsg,'');
    }
  }

  isExistToken():boolean{
        var isExistToken=false;//check is page has been set token
        if(this.jwtUtil.getServiceAppToken()&&this.jwtUtil.getServiceAppType){isExistToken=true;}
        return isExistToken;
  }

  updateToken(token:string){
    var tokenMsg;
    this.translateService.get('tokenInvalid').subscribe(
      (res)=>{
           tokenMsg=res;
      }
    );
     if(token){
      this.jwtUtil.setServiceAppType(ComponentUtil.getTokenType(token,tokenMsg));
      this.jwtUtil.setServiceAppToken(token);
     }
  }

  getSessionToken(param:string){
    var origToken=this.jwtUtil.getServiceAppToken();
    var origType=this.jwtUtil.getServiceAppType();
    var tokenMsg;
    this.translateService.get('tokenInvalid').subscribe(
      (res)=>{
        tokenMsg=res;
      }
    );
    this.http.get('/api/getToken').subscribe((data) => {
      // console.log('get token');
      console.log(data);
      var hd:HeaderDataModel=data;
      if(hd&&hd.token){
        if(this.isExistToken()&&this.jwtUtil.getServiceAppToken()==hd.token){
          this.jwtUtil.setGetTokenStatus();
          console.log("1.1 get the session token and equal the orig");
          this.refreshPage();
          // window.location=window.location;
        }else{       
          console.log("1.2 get the session token and not equal the orig")        
          this.jwtUtil.setServiceAppType(ComponentUtil.getTokenType(hd.token,tokenMsg));
          this.jwtUtil.setServiceAppToken(hd.token);
          // this.handleToken(hd.token);
          this.callValidate();
        }
      }else if(param){
        console.log("2 no session token and has param");
        this.jwtUtil.setServiceAppType(ComponentUtil.getTokenType(param,tokenMsg));
        this.jwtUtil.setServiceAppToken(param);
        // this.handleToken(param);
        this.callValidate();
      }else if(this.isExistToken()){
        console.log("3 no session token and has orig token");
        this.jwtUtil.setGetTokenStatus();
        this.refreshPage();
        // window.location=window.location;
      }else{
        console.log("4 no token at get token");
        // this.errMsgUtil.popErrMsg(null,"Request failed,please try again.");
        this.authErrMsg(null);
      }
    },
      (error: any) => {
        this.jwtUtil.removeGetTokenStatus();
        if(error.status==404){
          console.log("5 local call token api 404 "+param);
          if(param){
            this.jwtUtil.setServiceAppType(ComponentUtil.getTokenType(param,tokenMsg));
            this.jwtUtil.setServiceAppToken(param);
          }
          this.jwtUtil.setGetTokenStatus();
          // this.handleToken(param);
          this.callValidate();
        }else{
          this.jwtUtil.removeGetTokenStatus();
          // this.errMsgUtil.popErrMsg(null,"Request failed,please try again.");
          return Observable.throw(error || 'Server error');
        }
      }); 
  }

  refreshPage(){
    console.log(window.location.href);
    var timestamp=Math.floor(Date.now() / 1000);
    console.log("timestamp:"+timestamp)
    var url;
    if(window.location.href.indexOf('timestamp')>0){
      
    }else{
      if(window.location.href.indexOf('?')>0){
        url=window.location.href+"&timestamp="+timestamp;
      }
      else{
        url=window.location.href+"?timestamp="+timestamp;
      }
      window.location.replace(url);
    }
    this.jwtUtil.removeGetTokenStatus();
    // alert('aaa');
  }

  setAppStyle(){
    console.log('********** setAppStyle');
    let serviceAppType =this.jwtUtil.getSubServiceAppType();
    let tokenStyle = this.jwtUtil.getAccessTokenParam('style');
    console.log('********** serviceAppType:' + serviceAppType);
    console.log('********** tokenStyle:' + tokenStyle);
   if(serviceAppType
        && serviceAppType==this.jwtUtil.getJwtTypeMyHkt()
        && tokenStyle == '1010'){
      sessionStorage.setItem('style','1010myhkt');
   }else{
    switch(serviceAppType){
      case this.jwtUtil.getJwtType1010():
        sessionStorage.setItem('style','1010');
        break;
      case this.jwtUtil.getJwtTypeMyHkt():
        sessionStorage.setItem('style','myhkt');
        break;
      case this.jwtUtil.getJwtTypeClubsim():
        sessionStorage.setItem('style','clubsim');
        break;
      default:
        sessionStorage.setItem('style','csl');
        break;
    }
   }
  }

}

import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { stockBalanceReq } from '../../../data/models/stockBalanceReq.model';
import {CheckStockService} from '../../../data/service/check-stock.service';
import { PopupMsgUtil } from 'src/app/data/util/popupMsgUtil';

@Component({
  selector: 'app-myewallet-stock-avail',
  templateUrl: './myewallet-stock-avail.component.html',
  styleUrls: ['./myewallet-stock-avail.component.css']
})


export class MyewalletStockAvailComponent implements OnInit {

  
  declare replaceAll:any;
  redemptionChannel:string[];
  productList:any[];
  stockDisplayList:any[];
  noAvailChannelFlag=false;
  _couponDetail:CouponInstanceModel=null;
  productListDisabled = false;
  districtListDisabled = false;


  @Input('couponDetail')
  get couponDetail():CouponInstanceModel{
    return this._couponDetail;
  }

  set couponDetail(val:CouponInstanceModel){
    this._couponDetail=val;
  }

  initialPopup(){
    this.selectedDistrict = "none";
    this.selectedProduct = "none";
    this.detailFlag = false;
    this.noAvailChannelFlag = false;
    this.stockDisplayList=[];
    this.districtList = [];
    var val = this.couponDetail;
    if (val){
      var couponItem = val.couponItem;
      if (couponItem.redemptionChannel){
        this.redemptionChannel = couponItem.redemptionChannel;
      }
      if (couponItem.appliedProductSKU&&couponItem.appliedProductSKU.length>1){
        this.productFlag = true;
        this.districtFlag = false;
        var productList = [];
        for (let i=0;i<couponItem.appliedProductSKU.length;i++){
          var temp = {
            "name":couponItem.appliedProductName?.[i],
            "SKU":couponItem.appliedProductSKU[i]
          }
          productList.push(temp)
        }
        // couponItem.appliedProductSKU.forEach(val=>{
        //   var temp = {
        //     "name":val,
        //     "SKU":val
        //   }
        //   productList.push(temp)
        // })
        this.productList = productList;
      }else if(couponItem.appliedProductSKU&&couponItem.appliedProductSKU.length==1){
        var temp = {
          "name":couponItem.appliedProductName[0],
          "SKU":couponItem.appliedProductSKU[0]
        }
        var productList = [];
        productList.push(temp);
        this.productList = productList;
        this.productFlag = false;
        this.selectedProduct = couponItem.appliedProductSKU[0];
        this.GetAllStockInfo(this.selectedProduct,this.redemptionChannel,false);
      }
    }
  }

  stockInfo = {};
  productListTxt = [];
  districtList = [];
  regionList = [];
  selectedProduct = "";
  selectedDistrict = "";
  productFlag = false;
  districtFlag = false;
  detailFlag = false;
  lang = "";

  constructor(private checkStockService:CheckStockService,private popupMsgUtil:PopupMsgUtil,private cd: ChangeDetectorRef) {
  }

  resetForm(){
    this.selectedDistrict = "none";
    this.selectedProduct = "none";
    this.districtList = [];
    this.stockDisplayList=[];
    this.detailFlag = false;
    this.noAvailChannelFlag = false;
    if (this.couponDetail){
      var couponItem = this.couponDetail.couponItem;
      if (couponItem.appliedProductSKU&&couponItem.appliedProductSKU.length>1){
        this.productFlag = true;
        this.districtFlag = false;
      }else if(couponItem.appliedProductSKU&&couponItem.appliedProductSKU.length==1){
        this.productFlag = false;
        this.selectedProduct = couponItem.appliedProductSKU[0];
      }
    }
  }

  ngOnInit(): void {
    console.log(this.couponDetail)
    this.lang = sessionStorage.getItem('lang');
    console.log("lang:"+this.lang)
    this.productFlag = true;
    this.selectedDistrict = "none";
    this.selectedProduct = "none";
    this.districtList = [];
    this.regionList = [{
      "name": "New_Territories",
      "code": "New_Territories"
    },
    {
      "name": "Kowloon",
      "code": "Kowloon"
    },
    {
      "name": "Hong_Kong_Island",
      "code": "Hong_Kong_Island"
    }];
  }

  GetAllStockInfo(product,channelList,disable){
    this.productListDisabled = true;
    this.productFlag = false;
    this.districtListDisabled = true;
    this.popupMsgUtil.loading();
    this.checkStockService.getStockBalance('ALL',product,channelList).subscribe(res=>{
      console.log(val)
      var val = res.body;
      if (val&&val.totalNumber>0){
        var data = val.data;
        this.stockInfo = {};
        data.forEach(val=>{
          if(val.stockLevel){
            var region = val.region;
            var district = val.district;
            // if(this.lang=='zh-HK'){
            //   region = val.regionChi;
            //   district = val.districtChi;
            // }else{
            //   region = val.region.split('_').join(' ');
            //   district = val.district;
            // }
            if(!this.stockInfo.hasOwnProperty(val.region)){
              this.stockInfo[region] = [];
            }
            if(!this.stockInfo.hasOwnProperty(district)){
              this.stockInfo[district] = [];
            }
            if(this.lang=='zh-HK'){
              val['districtDes'] = val.districtChi;
              val['addressDes'] = val.addressChi;
            }else{
              val['districtDes'] = val.district;
              val['addressDes'] = val.address;
            }
            this.stockInfo[region].push(val);
            this.stockInfo[district].push(val);
          }
        })
        console.log(this.stockInfo);
        for (const k in this.stockInfo){
          console.log(k);
          var temp = {};
          temp['code'] = k;
          if(k=='Hong_Kong_Island'||k=='Island_Districts'||k=='Kowloon'||k=='New_Territories'){
            if(this.lang=='zh-HK'){
              temp['name'] = '--'+this.stockInfo[k][0].regionChi+'--';
            }else{
              temp['name'] = '--'+k.split('_').join(' ')+'--';
            }
          }else{
            if(this.lang=='zh-HK'){
              temp['name'] = this.stockInfo[k][0].districtChi+'('+this.GetAvailShopCount(this.stockInfo[k])+')';
            }else{
              //temp['name'] = k+'('+this.stockInfo[k].length+')';
              temp['name'] = k+'('+this.GetAvailShopCount(this.stockInfo[k])+')';
            }
          }
          this.districtList.push(temp)
        }
        console.log(this.districtList)
        if(this.districtList.length>0){
          this.districtFlag = true;
        }else{
          this.districtFlag = false;
          this.noAvailChannelFlag = true;
        }
      }else if(val&&(val.totalNumber==0)){
        this.districtFlag = false;
        this.noAvailChannelFlag = true;
      }      
    this.productListDisabled = false;
    if(disable) {
      this.productFlag = true;
    }
    this.districtListDisabled = false;
    this.popupMsgUtil.endLoading();
    },error=>{
      this.productListDisabled = false;
      this.productFlag = true;
      this.districtListDisabled = false;
      this.popupMsgUtil.endLoading();
    })
  }

  ChangingDistrict(value){
    this.detailFlag = false;
    this.noAvailChannelFlag = false;
    if(this.selectedDistrict!="none"){
      this.stockDisplayList = this.stockInfo[this.selectedDistrict];
      this.detailFlag = true;
    }else{
      this.detailFlag = false;
    }
  }

  GetAvailShopCount(stockInfo){
    console.log(stockInfo)
    var count = 0;
    stockInfo.forEach(shop => {
      if (shop['stockLevel']!='No Stock'){
        count++;
      }
    });
    return count;
  }

  RemoveRecordWhenStockLevelNull(records){
    var temp = [];
    records.forEach(val=>{
      if(val.stockLevel!=null){
        temp.push(val);
      }
    })
    return temp;
  }

  ChangingProduct(value){
    this.detailFlag = false;
    this.noAvailChannelFlag = false;
    this.selectedDistrict = "none";
    this.districtFlag = false;
    this.districtList = [];
    console.log(this.selectedProduct)
    if (this.selectedProduct!="none"){
      this.GetAllStockInfo(this.selectedProduct,this.redemptionChannel,true);
    }
  }

  getLastItemClass(index: number, arrayLength: number): string {
    // Checks if the index is the last element in the array
    return index === arrayLength - 1 ? 'last-tr' : '';
  }

  // ChangingDistrict(value){
  //   console.log(this.selectedDistrict)
  //   this.detailFlag = false;
  //   this.noAvailChannelFlag = false;
  //   if (this.selectedDistrict!="none"){
  //     //call API()
  //     //get value to stockDisplay
  //     this.checkStockService.getStockBalance(this.selectedDistrict,this.selectedProduct,this.redemptionChannel).subscribe((res)=>{
  //       console.log(res);
  //       console.log(res.body.data)
  //       if(res&&res.body&&res.body.data){
  //         this.stockDisplayList = res.body.data;
  //         this.stockDisplayList = this.RemoveRecordWhenStockLevelNull(this.stockDisplayList);
  //         if(this.stockDisplayList.length>0){
  //           this.detailFlag = true;
  //           this.noAvailChannelFlag = false;
  //         }else{
  //           this.detailFlag = false;
  //           this.noAvailChannelFlag = true;
  //         }
  //       }
  //     })
  //   }
  // }
}

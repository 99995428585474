import { RequestSourceModel } from './requestSource.model';

export class CouponListRequestModel{
    category?:string;
    couponDesc?:string;
    couponName?:string;
    custId?:string;
    custType?:string;
    hasPromotional?:boolean;
    hasSpringboard?:boolean;
    issueBrand?:string;
    lmt?:number;
    offset?:number;
    source?:RequestSourceModel;
    statusIncluded?:string;
}
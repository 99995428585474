import { Injectable, Injector } from '@angular/core';
import { Base64Utils } from '../../../assets/common/util/base64util';
import { JwtUtil } from './jwtUtils';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ErrMsgUtil } from './errMsgUtil';

@Injectable({ providedIn: 'root' })
export class ComponentUtil {
     constructor(private translateService:TranslateService){}


    public static checkSelectNotNull(value:string):Boolean{
        var b:Boolean=false;
        if(value&&value.length>0){
            if(value!='---'){
              b=true;
            }
        }
        return b;
    }

    public static copyToClipBoard(text:string){
        var input = document.createElement('input');
        input.setAttribute('readonly', 'readonly'); 
        input.setAttribute('value', text);
        document.body.appendChild(input);
        // input.setSelectionRange(0, 9999);
        input.select();
        var res = document.execCommand('copy');
        document.body.removeChild(input);
    }

    public static getTokenType(tokenStr:string,tokenMsg:string):string{
        // console.log('token str:'+tokenStr);
        var tokenType:string=null;
        var tokenObj=null;
        var jwtUtil:JwtUtil=new JwtUtil(null);
        var tokenStrArray=tokenStr.split('.');
        var tokenObjStr=Base64Utils.decode(tokenStrArray[1]);
        // console.log('token convert:'+tokenObjStr);
        if(!tokenObjStr.endsWith("}")){
            tokenObjStr=tokenObjStr.substr(0,tokenObjStr.lastIndexOf('}')+1);
            // tokenObjStr=tokenObjStr.substr(0,tokenObjStr.length-2);
        }
        
        try{
        //   tokenObj=JSON.parse(atob(tokenStrArray[1]));
        tokenObj=JSON.parse(tokenObjStr);
        }
        catch(err){
               ErrMsgUtil.popupMessage(tokenMsg);
        }
        tokenType=tokenObj.channel;
        if(tokenType&&tokenType.indexOf(jwtUtil.getJwtTypeCsl())>-1){
            // tokenType=jwtUtil.getJwtTypeCsl();
        }
        else if(tokenType&&tokenType.indexOf(jwtUtil.getJwtType1010())>-1){
            // tokenType=jwtUtil.getJwtType1010();
        }
        else if(tokenType&&tokenType.indexOf(jwtUtil.getJwtTypeMyHkt())>-1){
            // tokenType=jwtUtil.getJwtTypeMyHkt();
        }
        else if(tokenType&&tokenType.indexOf(jwtUtil.getJwtTypeClubsim())>-1){

        }
        else{
           tokenType=null;
        }
        return tokenType;
    }

    public getPageLimit():number{
         var result:number=10;
         var r=environment.pageLimit;
         if(r){
             result=r;
         }
         return result;
    }

    public static getSetArray(arr:any[]):Set<any>{
       var res:Set<any>=new Set(arr);
       return res;
    }

    public static getSetReveroArray(arr:any[]):Set<any>{
        var res:Set<any>=new Set();
        for(var i=0;i<arr.length;i++){
            res.add(arr[arr.length-1-i]);
        }   
        return res;
    }

    public static convertSetToArray(set:Set<any>):any[]{
       var res:any[]=[];
       set.forEach((v,v2,set)=>{
           res.push(v);
       })
       return res;
    }

    public static getQueueByArray(arr:any[],queueLen:number):any[]{
        var res:any[]=[];
        if(arr.length>queueLen){
            var si=arr.length-queueLen;
            for(var i=0;i<queueLen;i++){
              res.push(arr[si+i]);
            }
         }
         else{
             res=arr;
         }
        return res;
    }

    public static parseSizeToInt(size:string):number{
        var result:number;
        if(size.indexOf('px')>-1){
            size=size.replace('px','');
        }
        else if(size.indexOf('pt')>-1){
            size=size.replace('pt','');
        }
        result=new Number(size).valueOf();
        return result;
    }

    public static addElementClass(element:HTMLElement,rClass:string){
        var ec=element.getAttribute('class');
        ec=ec+' '+rClass;
        element.setAttribute('class',ec);
    }

    public static removeElementClass(element:HTMLElement,rClass:string){
        this.replaceElementClass(element,rClass,'');
    }

    public static replaceElementClass(element:HTMLElement,sClass:string,rClass:string){
        var ec=element.getAttribute('class');
        ec=ec.replace(sClass,rClass);
        element.setAttribute('class',ec);
    }

    public static setElementText(element:HTMLElement,text:string){
        element.innerText=text;
    }

    public static setElementCss(element:HTMLElement,property:string,val:string){
        window.getComputedStyle(element).setProperty(property,val);
    }

    public static clickElement(id:string){
        var e=document.getElementById(''+id);
        if(e){
            e.click();
        }
    }

    public static convertNumber(num:number):number{
        var r=0;
        if(num){
            r=num;
        }
        return r;
    }
}
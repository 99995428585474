import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { EwalletListService } from '../../../data/service/ewalletList.service';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { LoginModel } from '../../../data/models/login.model';
import {TranslateService} from '@ngx-translate/core';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { ComponentUtil } from '../../../data/util/componentUtil';
import { RedemptionRecordItemModel } from '../../../data/models/redemptionRecordItem.model';
import { ErrHandler } from '../../../data/util/errHandler';
import { DateRangePickerUtils } from '../../../../assets/common/util/daterangepickerutil';
import { TransactionLogService } from '../../../data/service/transactionLog.service';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

@Component({
    selector: 'app-myewallet-mgmRewardHistory',
    templateUrl: './myewallet-mgmRewardHistory.component.html',
    styleUrls: ['./myewallet-mgmRewardHistory.component.scss']
  })
  export class MyEwalletMgmRewardHistoryComponent implements OnInit {
    rewardHists:RedemptionRecordItemModel[]=[];

    enterDateTime:string;

    
    dateTimeFormat:string='YYYY-MM-DD HH:mm:ss';

    dateFormat:string='YYYY-MM-DD';

    tsDateFormat:string='yyyy-MM-dd';

    // dateTimeFormat:string='DD-MMM-YYYY hh:mm:ss';

    // dateFormat:string='DD-MMM-YYYY';

    apiDateFormat:string='YYYY-MM-DD HH:mm:ss'

    dateFormatReg:string='^\\d{4}-\\d{2}-\\d{2}';

    separator:string=' to ';

    defaultLimit:number=10;

    nextOffset:number=0;

    totalNumber:number;

    couponCodeSet:Set<string> = new Set();

    lang:string=sessionStorage.getItem("lang");

    channel: string;

    constructor(private transactionLogService:TransactionLogService,private errMsgUtil:ErrMsgUtil,private jwtUtil:JwtUtil,private popupMsgUtil:PopupMsgUtil,private translateService:TranslateService,private componentUtil:ComponentUtil){}
      
    ngOnInit() {
        this.channel = this.jwtUtil.getSubServiceAppType();
        this.defaultLimit=this.componentUtil.getPageLimit();
        // this.rewardHists[0]=new RedemptionRecordItemModel();
        // this.rewardHists[1]=new RedemptionRecordItemModel();
        // this.rewardHists[2]=new RedemptionRecordItemModel();
    }

    initDetail(){
      this.enterDateTime=DateRangePickerUtils.getNow(this.dateTimeFormat);
      this.totalNumber=0;
      this.nextOffset=0;
      this.searchRedemptionRecord(null,null,this.defaultLimit,0,true);
      // var csDiv=document.getElementById('redemptionFilterDiv');
      // csDiv.scroll(-100,0);
  }

    clickTitleToggle(id:string){
      console.log(id);
       var toggle=document.getElementById('mgmRewardHist_toggle'+id);
       var title=document.getElementById('mgmRewardHist_title'+id);
       var titleClass=title.getAttribute('class');
       var toggleClass=toggle.getAttribute('class');
      //  toggleClass.replace('fa-angle-up','fa-angle-down');
      //  titleStyle
      if(toggleClass.indexOf('fa-angle-up')>0){
        toggleClass=toggleClass.replace('fa-angle-up','fa-angle-down');
        titleClass=titleClass.replace('title_up','title_down');
      }
      else{
        toggleClass=toggleClass.replace('fa-angle-down','fa-angle-up');
        titleClass=titleClass.replace('title_down','title_up');
        let btn = document.getElementById('mgmRewardHistroyDetail'+id+'InitBtn');             
        btn.click();
      }
      console.log(toggleClass);
      console.log(titleClass);
      toggle.setAttribute('class',toggleClass);
      title.setAttribute('class',titleClass);
    }

    clickMore(){
      this.searchRedemptionRecord(null,null,this.defaultLimit,this.nextOffset,false);
     }

    //common method++
    searchRedemptionRecord(filterRedemptionName:string,dateRange:string,limit:number,offset:number,reset:boolean){
        this.popupMsgUtil.loading();
      
        if(reset){
            this.rewardHists=[];
            this.couponCodeSet.clear();
        }
        var fd=this.getDateRangeFrom(dateRange);
        var td=this.getDateRangeTo(dateRange);
        console.log("key:"+filterRedemptionName+";dateRange:"+dateRange+";limit:"+limit+";offset:"+offset+";fd:"+fd+";td:"+td);
        var nowD=DateRangePickerUtils.getNow(this.dateFormat);
        if(ComponentUtil.checkSelectNotNull(fd)){
            if(this.isDateAfterNow(fd)){
                fd=nowD
            }
            fd=fd+' 00:00:00';
        }
        if(ComponentUtil.checkSelectNotNull(td)){
            if(this.isDateAfterNow(td)){
                td=DateRangePickerUtils.getNow(this.dateFormat);
            }
            if(td==nowD){
                td=this.enterDateTime;
            }
            else{
            td=td+' 23:59:59';
            }          
        }
        else{
            td=this.enterDateTime;
        }
        console.log("key:"+filterRedemptionName+";dateRange:"+dateRange+";limit:"+limit+";offset:"+offset+";fd:"+fd+";td:"+td);
        if(fd){
          fd=DateRangePickerUtils.convertDateFormat(fd,this.dateTimeFormat,this.apiDateFormat);
        }
        if(td){
        td=DateRangePickerUtils.convertDateFormat(td,this.dateTimeFormat,this.apiDateFormat);
      }
      console.log("key:"+filterRedemptionName+";dateRange:"+dateRange+";limit:"+limit+";offset:"+offset+";fd:"+fd+";td:"+td);
        this.transactionLogService.getRedemptionRecords(limit,offset,fd,td,'M').subscribe(
            (res)=>{
                this.nextOffset=offset+limit;
                console.log(res);              
                res=this.jwtUtil.setupResponse(res);
                this.totalNumber=res.totalNumber;
                console.log(this.nextOffset);
                console.log(this.totalNumber);
                // this.redemptionHists.push(res.data);
                // this.rewardHists.push(res.data);
                if(res.data){
                    var addDatas:RedemptionRecordItemModel[]=res.data;
                    if(addDatas&&addDatas.length>0){
                        for(var i=0;i<addDatas.length;i++){
                            if(this.couponCodeSet&&!this.couponCodeSet.has(addDatas[i].couponCode)){
                                // UTC plus 8 hours(convert to HK time)
                                if(addDatas[i].redemptionDate){
                                    addDatas[i].redemptionDate = dayjs.utc(addDatas[i].redemptionDate).add(8,'hour').format('YYYY-MM-DD HH:mm:ss');
                                }
     
                                this.rewardHists.push(addDatas[i]);
                            }
                            this.couponCodeSet.add(addDatas[i].couponCode);
                        }
                    }
                }
                // this.filterRedemptionRecord(filterRedemptionName);                
                this.popupMsgUtil.endLoading();
            }
            ,(err)=>{
            this.popupMsgUtil.endLoading();
            const _this=this;
            function getHandler():ErrHandler{
                let handler=<ErrHandler><unknown>function () { };
                handler.handleErr=function(){

                }
                handler.handleRefreshToken=function(){
                  _this.searchRedemptionRecord(filterRedemptionName,dateRange,limit,offset,reset);              
                }
                return handler;
            }
            var eh:ErrHandler=getHandler();
            this.errMsgUtil.handleErr(eh,err.error); 
            }
        );
    }


    getDateRangeFrom(dateRange:string):string{
        var result:string=null;
      if(dateRange&&dateRange.length==(this.dateFormat.length*2+this.separator.length)){
            result=dateRange.substr(0,this.dateFormat.length);
            if(!this.matchDateFormatReg(result)){
                result=null;
            }
      }
      return result;
    }

    getDateRangeTo(dateRange:string){
        var result:string=null;
        if(dateRange&&dateRange.length==(this.dateFormat.length*2+this.separator.length)){
            result=dateRange.substr(this.dateFormat.length+this.separator.length,this.dateFormat.length);
        }
        return result;
    }

   matchDateFormatReg(date:string):boolean{
        var result:boolean=false;
        if(date){
            var m=date.match(this.dateFormatReg);
            if(m){
                result=true;
            }
        }       
        return result;
    }

    isDateAfterNow(date:string):boolean{
        var result:boolean=false;
        var now=DateRangePickerUtils.getNow(this.dateFormat);
        result=DateRangePickerUtils.isAfter(date,now,this.dateFormat);
        return result;
    }
    //common method--
  }
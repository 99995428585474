import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { ApiUtil } from '../util/apiUtil';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { environment  } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class MicrositeService {

constructor(
  private http:HttpClient,
  private apiUtil:ApiUtil
) { }
//private defaultHeader = { 'Content-Type': 'application/json' };

private url:string=environment.apiUrl;

private httpOptions  = {
  headers: new HttpHeaders({
   'Accept': 'application/json'
  }),
};

getCustomerInfo(code:string):Observable<any>{
  
  console.debug(">>>>>>call MgmServiceService.getCustomerInfo>>>>> ");
  console.debug(this.url+"/ewallet/api/info/cust?code="+code);
  return this.http.post(this.url+"/ewallet/api/MGM/info/cust",{'content':code},this.httpOptions).pipe(       
      (res)=> {
        //  console.log(res);
         return res;
      },
      catchError((err)=>{
        return  throwError(err);
      })
    );
}

getCustomerInfoOld(code:string,pageType:string):Observable<any>{
  console.debug(">>>>>>call MgmServiceService.getCustomerInfo>>>>> ");
  console.debug(this.url+"/ewallet/api/info/cust?code="+code+"&pageType="+pageType);
  return this.http.get(this.url+"/ewallet/api/info/cust?code="+code+"&pageType="+pageType,this.httpOptions).pipe(       
    (res)=> {
       return res;
    },
    catchError((err)=>{
      return  throwError(err);
    })
  );
}

getCouponList(code:string):Observable<any>{
  console.debug(">>>>>>call MgmServiceService.getCouponList>>>>> ");
  console.debug(this.url+"/ewallet/api/coupon/instance??statusIncluded=ALL&code="+code);
  return this.http.post(this.url+"/ewallet/api/MGM/coupon/instance?statusIncluded=REDEEMABLE",{'content':code},this.httpOptions).pipe(       
    (res)=> {
       return res;
    },
    catchError((err)=>{
      return  throwError(err);
    })
  );
}

getMgmCouponListOld(code:string):Observable<any>{
  
  console.debug(">>>>>>call MgmServiceService.getMgmCouponList>>>>> ");
  console.debug(this.url+"/ewallet/api/coupon/instance?code="+code);

  return this.http.get(this.url+"/ewallet/api/coupon/instance?code="+code,this.httpOptions).pipe(       
    (res)=> {
       return res;
    },
    catchError((err)=>{
      return  throwError(err);
    })
  );
}

getMgmCouponDetailOld(code:string):Observable<any>{
  
  console.debug(">>>>>>call MgmServiceService.getMgmCouponDetail>>>>> ");
  console.debug(this.url+"/ewallet/api/mgm/coupon?code="+code);

  return this.http.get(this.url+"/ewallet/api/mgm/coupon?code="+code,this.httpOptions).pipe(       
    (res)=> {
       return res;
    },
    catchError((err)=>{
      return  throwError(err);
    })
  );
}

putRefereeCouponViews(code:string):Observable<any>{
  const couponCode = {
    "couponCode": code
  }
  return this.apiUtil.doPut(this.url+"/ewallet/api/MGM/coupons/referrerCouponCount", couponCode , this.httpOptions).pipe(
    (res) => {
      return res;
    },
    catchError((err) => {
      return throwError(err);
    })
  );
} 


}

import { JqueryUiPopupButtonModel } from "./jquiPopupButton.model";
export class JqueryUiPopupModel{
    autoOpen?:boolean;
    show?:string;
    hide?:string;
    modal?:boolean;
    buttons?:JqueryUiPopupButtonModel[];
    draggable?:boolean;
    closeOnEscape?:boolean;
    title?:string;
    position?:string;
    width?:number;
    height?:number;
    resizeable?:boolean;
    zIndex?:number;
    drag?:any;
    dropLayout?:boolean;
    dropTop?:boolean;
    dropPrecent?:number;
    diableCloseBtn?:boolean;
    scanLayout?:boolean;
}
import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';
import { FormsModule }    from '@angular/forms';

import { MyEwalletRoutingModule } from './myewallet-routing.module';
import { MyEwalletDetailComponent } from './myewallet-detail/myewallet-detail.component';
import { MyEWalletFilterComponent } from './myewallet-filter/myewallet-filter.component';
import { MyEwalletCouponListComponent } from './myewallet-couponList/myewallet-couponList.component';
import { MyEwalletCouponDetailComponent } from './myewallet-couponDetail/myewallet-couponDetail.component';
import { MyEwalletMgmComponent } from './myewallet-mgm/myewallet-mgm.component';
import { MyEwalletSharePopupComponent} from './myewallet-sharepopup/myewallet-sharepopup.component';
import { MyEwalletCouponCardComponent} from './myewallet-couponCard/myewallet-couponCard-component';
import { MyEwalletPendingComponent } from './myewallet-pending/myewallet-pending-component';
import { MyEwalletNameCardComponent } from './myewallet-nameCard/myewallet-nameCard.component';
import { MyEwalletMgmRewardHistoryComponent} from './myewallet-mgmRewardHistory/myewallet-mgmRewardHistory.component';
import { MyEwalletRedemptionRecordComponent } from './myewallet-redemptionRecord/myewallet-redemptionRecord.component';
import { MyEwalletDateRangePickerComponent } from './myewallet-dateRangePicker/myewallet-dateRangePicker.component';
import { MyEwalletRewardsComponent } from './myewallet-rewards/myewallet-rewards.component';
import { MyEwalleSearchComponent } from './myewallet-search/myewallet-search.component';
import { MyEwalletBrandFilterComponent } from './myewallet-brandfilterComponent/myewallet-brandfilter-component';
import { MyEwalletCouponStatusFilterComponent } from './myewallet-couponstatusfilterComponent/myewallet-couponstatusfilter-component';
import { MyEwalletSearchCouponCardComponent } from './myewallet-searchCouponCard/myewallet-search-couponcard-component';
import { MyEwalletScanQrcode } from './myewallet-scanqrcode/myewallet-scanqrcode';
import { DragComponent} from './drag-component/drag-component';
import { QRCodeModule } from 'angularx-qrcode';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DateComponent} from './date-component/date-component';
import { MyewalletStockAvailComponent } from './myewallet-stock-avail/myewallet-stock-avail.component';
import {StockLevelPipe} from '../../data/pipe/stock-level.pipe';
import { MyewalletMgmRewardHistoryDetailComponent } from './myewallet-mgm-reward-history-detail/myewallet-mgm-reward-history-detail.component'

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      MyEwalletRoutingModule,
      QRCodeModule,
      TranslateModule,
      BrowserModule,
      InfiniteScrollModule,
      // platformBrowserDynamic,
    ],
    declarations: [
        StockLevelPipe,
        MyEwalletDetailComponent,
        MyEWalletFilterComponent,
        MyEwalletCouponDetailComponent,
        MyEwalletCouponListComponent,
        MyEwalletMgmComponent, 
        MyEwalletSharePopupComponent,
        MyEwalletCouponCardComponent,
        MyEwalletPendingComponent,
        MyEwalletNameCardComponent,
        MyEwalletMgmRewardHistoryComponent,
        MyEwalletDateRangePickerComponent,
        MyEwalletRedemptionRecordComponent,
        MyEwalletRewardsComponent,
        MyEwalleSearchComponent,
        MyEwalletBrandFilterComponent,
        MyEwalletCouponStatusFilterComponent,
        MyEwalletSearchCouponCardComponent,
        DragComponent,
        DateComponent,
        MyEwalletScanQrcode,
        MyewalletStockAvailComponent,
        MyewalletMgmRewardHistoryDetailComponent,
    ],
    exports:[
      MyEwalletNameCardComponent,
      MyEwalletCouponCardComponent,
      MyEwalletCouponDetailComponent,
    ]
  })
  export class MyEwalletModule {}
<div class="couponCard container" style="border-radius:8px;">
    <!--<img id="nameCardQrClose" class="close popClose" src="/assets/common/image/icons/icon_close.png" width="30" height="30" (click)="clickClose()"/>-->
    <i  class="close popClose" data-special (click)="clickClose()" style="top:18px;">×</i>
    <div class="row" style="margin-bottom:35px;padding-bottom:35px;">
        <div class="col-12">
            <p class="heading5" style="padding-left:18px;padding-top:20px;">{{'selectDateRange'|translate}}</p>
        </div>
        <div class="col-12" id="{{page}}CouponDateRangeDiv" style="height:360px;">
            <input type="text" id="{{page}}CouponDateRangeText" value="" style="width:100%;display:none;" [(ngModel)]="initDateRange"/>
        </div>
        <div class="col-12">
            <button type="button" class="btn button" data-special style="width:94%;margin-left:10px;" (click)="clickEnter()">{{'enter'|translate}}</button>
        </div>
    </div>
</div>


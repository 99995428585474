export class Base64Utils{
    constructor(){}
    
    static encode(src){
        var base = new BASE64();
        return base.encode(src);
    }

    static decode(src){
        var base = new BASE64();
        return base.decode(src);
    }
  }
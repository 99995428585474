import { Component, OnInit,OnDestroy, ErrorHandler,Input,Output,EventEmitter,OnChanges } from '@angular/core';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { ShareUtils } from '../../../../assets/common/util/shareutil';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { ComponentUtil } from '../../../data/util/componentUtil';
import { EwalletMgmService } from '../../../data/service/ewalletMgm.service';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { ErrHandler } from '../../../data/util/errHandler';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { environment } from '../../../../environments/environment';
import { Base64Utils } from '../../../../assets/common/util/base64util';
import { FavroitesService } from '../../../data/service/favroites.service';
import { DateRangePickerUtils } from 'src/assets/common/util/daterangepickerutil';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-date', 
    // directives: [ InfiniteScroll ],
    templateUrl: './date-component.html',
    styleUrls: ['./date-component.scss']
})
export class DateComponent implements OnInit{
    //page variable
    @Input()
    dateFormat:string;//use dateformat 

    @Input()
    date:string;//use date

    format:string="";

    constructor(private translateService:TranslateService){}

    
    ngOnInit() {
        this.translateService.get("momentDateFormat").subscribe(
            (res)=>{
                this.format=res;
            }
        );

        if(this.dateFormat){
           this.format=this.dateFormat; 
        }
        if(this.date){
            this.date=DateRangePickerUtils.convertDateFormat(this.date,'YYYY-MM-DD HH:mm:ss',this.format);
            //console.log('formatDate:'+this.date+":"+this.format); 
        }
    }

    ngOnChanges(changes:any){
        // console.log('change');
        if(this.dateFormat){
            this.format=this.dateFormat; 
         }
         if(this.date){
            this.date=DateRangePickerUtils.convertDateFormat(this.date,'YYYY-MM-DD HH:mm:ss',this.format);
            //console.log('formatDate:'+this.date+":"+this.format); 
         }
    }
}
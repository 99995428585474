import { Component, OnInit,OnDestroy, ErrorHandler,Input,Output,EventEmitter } from '@angular/core';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { ShareUtils } from '../../../../assets/common/util/shareutil';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { ComponentUtil } from '../../../data/util/componentUtil';
import { EwalletMgmService } from '../../../data/service/ewalletMgm.service';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { ErrHandler } from '../../../data/util/errHandler';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { environment } from '../../../../environments/environment';
import { Base64Utils } from '../../../../assets/common/util/base64util';
import { FavroitesService } from '../../../data/service/favroites.service';
import { JqueryUiUtils } from 'src/assets/common/util/jquiutil';


@Component({
    selector: 'app-drag', 
    // directives: [ InfiniteScroll ],
    templateUrl: './drag-component.html',
    styleUrls: ['./drag-component.scss']
})
export class DragComponent implements OnInit{
    //page variable
    @Input()
    page:string;//use page 

    @Input()
    initMsg:string;

    @Input()
    okMsg:string;

    @Output()
    onCompleted=new EventEmitter<any>();

    ngOnInit() {
        // this.page='coupon';
        // this.initMsg='hello';
        // this.okMsg='ok';
    }

    init(){
        JqueryUiUtils.drag(this.page+'_drag',{'initMsg':this.initMsg,'okMsg':this.okMsg,'page':this.page});
    }

    complete(){
        console.log('complete');
        this.onCompleted.emit();
    }







}
import { Component, OnInit,Input} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MicrositeService } from '../../../data/service/microsite.service';
import { CustomerInfoModel } from 'src/app/data/models/customerInfo.model';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { JwtUtil } from 'src/app/data/util/jwtUtils';
import { PopupMsgUtil } from 'src/app/data/util/popupMsgUtil';
import { ErrHandler } from 'src/app/data/util/errHandler';
import { ErrMsgUtil } from 'src/app/data/util/errMsgUtil';
import { JwtService } from '../../../data/service/jwt.service';
import { BrandImageModel } from 'src/app/data/models/brandimage.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-microsite-coupon-detail',
  templateUrl: './coupon-detail.component.html',
  styleUrls: ['./coupon-detail.component.scss']
})
export class CouponDetailComponent implements OnInit {

  @Input()
  nameCardInfo: CustomerInfoModel = {};

  @Input()
  item: CouponInstanceModel = {};

  @Input()
  mode:string=null;

  lang = localStorage.getItem("lang");

  nameCardQrCode:string; 

  referrerCouponCode:string;

  constructor(
    private route: ActivatedRoute,
    private mgmServiceService: MicrositeService,
    private jwtUtil:JwtUtil,
    private errMsgUtil:ErrMsgUtil,
    private popupMsgUtil:PopupMsgUtil,
    public JwtService:JwtService,
    private translateService:TranslateService,
    // private commonServiceService: CommonServiceService,
  ) {
    console.debug("enter @constructor Coupon Detail page...");
  }

  ngOnInit() {
    console.debug("enter @ngOnInit Coupon Detail page...");

    var code: string = "";
    this.route.queryParams.subscribe(params => {
      code = params['code'];
      this.lang = params['lang'];
    });
    if(code){
       console.log(code);
       var mc=escape(code).replace(/%20/g,'+');
       code=unescape(mc);
    }
    if(this.lang){
      if(this.lang=='en'||this.lang=='zh-HK'){
        sessionStorage.setItem('lang',this.lang);
        this.translateService.use(this.lang);
      }
    }
    // console.log(escape(code));
    
    this.JwtService.getIconImage().subscribe(
      (res)=>{
        res=this.jwtUtil.setupResponse(res);
        var r:BrandImageModel[]=res.data;
        for(var i=0;i<r.length;i++){
          var bi:BrandImageModel=r[i];
          sessionStorage.setItem(bi.name,bi.brandLogoSmall);
          sessionStorage.setItem('couponCard-'+bi.name,bi.couponCardLogo);
          sessionStorage.setItem('brandFilter-'+bi.name,bi.brandFilterLogo);
        }                 
      },
      (err)=>{   
      });

    console.debug("params code=" + code);
    // if (code == "" || code == null) {//URL from Coupon detail page
      //get name card info
      // var cInfo= this.route.snapshot.paramMap.get('nameCardInfo');

      // var cInfo = sessionStorage.getItem("CustomerInfoModel");
      // if (cInfo != null) {
      //   this.nameCardInfo = JSON.parse(cInfo);
      // }
      // //get coupon info
      // //var dInfo= this.route.snapshot.paramMap.get('mgmCouponItemModel');
      // var dInfo = sessionStorage.getItem("MgmCouponListModel");
      // if (dInfo != null) {
      //   this.item = JSON.parse(dInfo);
      // }
    if(this.mode=='popup'){
        window.scroll(0,0);
    } else {//URL from RUBY APP share
      //get name card info
      // this.mgmServiceService.getCustomerInfo(code, "DETAIL").subscribe(
        this.mgmServiceService.getCustomerInfo(code).subscribe(
        (res) => {
          console.debug(">>>>mgmServiceService.getCustomerInfo return>>>");
          console.debug(res);
          this.nameCardInfo = res;
          this.jwtUtil.setServiceAppType(this.nameCardInfo.channel);
          var ulb=document.getElementById('micrositeupdateLogo');
          ulb.click();
        }, (err) => {
          console.debug(">>>>mgmServiceService.getCustomerInfo error>>>");
          console.debug(err);
        });

      //get coupon info
      // this.mgmServiceService.getMgmCouponDetail(code).subscribe(
        this.popupMsgUtil.loading();
        this.mgmServiceService.getCouponList(code).subscribe(
        (res) => {
          console.debug(">>>>mgmServiceService.getMgmCouponDetail return>>>");
          console.debug(res);
          if(res.data){
          this.item = res.data[0];
          this.referrerCouponCode = this.item.referrerCouponCode;

          if(window.name == ""){
            window.name = "isReload"; 
            
            //when open with CouponDetailComponent
            if(this.mode!='popup') {        
              this.mgmServiceService.putRefereeCouponViews(this.referrerCouponCode).subscribe(
                (res)=> {
                  
                }, (err) =>{

                }
              );
            }
            
          }else if(window.name == "isReload"){
            console.log("referrerCouponCode will not + 1");
          }

          }
          this.popupMsgUtil.endLoading();
        }, (err) => {
          console.debug(">>>>mgmServiceService.getMgmCouponDetail error>>>");
          console.debug(err);
          this.errMsgUtil.popupMsg(err.layer,err.status,err.errors.message,err.errors.detail,'');
          this.popupMsgUtil.endLoading();
        });

    }
  }
  
  openNameCardQrCode(){
    this.nameCardQrCode=document.getElementById('Microsite_NameCard_Qrcode').innerHTML;
    var btn=document.getElementById('myDetailNameCardQrModalBtn');
    btn.click();
  }

  openShare(){
  }

  openDetail(){
    
  }





}

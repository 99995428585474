import { Component, OnInit,OnDestroy, ErrorHandler,Input,Output,EventEmitter } from '@angular/core';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { ShareUtils } from '../../../../assets/common/util/shareutil';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { ComponentUtil } from '../../../data/util/componentUtil';
import { EwalletMgmService } from '../../../data/service/ewalletMgm.service';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { ErrHandler } from '../../../data/util/errHandler';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { environment } from '../../../../environments/environment';
import { Base64Utils } from '../../../../assets/common/util/base64util';
import { FavroitesService } from '../../../data/service/favroites.service';


@Component({
    selector: 'app-myewallet-couponstatusfilter', 
    // directives: [ InfiniteScroll ],
    templateUrl: './myewallet-couponstatusfilter-component.html',
    styleUrls: ['./myewallet-couponstatusfilter-component.scss']
})
export class MyEwalletCouponStatusFilterComponent implements OnInit{

    @Input()
    page:string;

    @Input()
    filterStatus:string;

    @Output()
    statusChange=new EventEmitter<any>();

    env=environment;

    ngOnInit() {
    }

    clickCouponStatus(csId:string){
        var selectStatusId=this.page+csId;
        // this.filterCs=this.getFilterStr(csId);
        this.filterStatus=csId;//default not all
        // this.changeButtonSelectStyle(selectStatusId,this.page+"csBtn");

        // this.filterResult(this.filterBrand,this.filterStatus,this.filterType,this.filterCouponName);
        // this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,this.filterBrand,this.filterStatus,this.filterType,this.filterCouponName);
        // this.jwtUtil.setupSessionTime();
        //  sessionStorage.setItem("myWallet_status_filter",this.filterStatus);
        this.statusChange.emit(this.filterStatus);
      }

      changeButtonSelectStyle(ctId:string,className:string){
        console.log('change status style');
        var ctButtons=document.getElementsByClassName(className);
        // console.log(ctButtons);
        for(var i=0;i<ctButtons.length;i++){
            // ctButtons[i].setAttribute("style","");
            var btnClass=ctButtons[i].getAttribute("class");
            btnClass=btnClass.replace('csbuttonFill','csbuttonNoFill');
            ctButtons[i].setAttribute("class",btnClass);
        }
        if(ctId&&ctId.length>0){
            var selectButton=document.getElementById(ctId);
            if(selectButton){
              // selectButton.setAttribute("style","border:2px solid orange;color:orange");
              var btnClass=selectButton.getAttribute("class");
              btnClass=btnClass.replace('csbuttonNoFill','csbuttonFill');
              selectButton.setAttribute("class",btnClass);
            }
        }
      }
}
export class JqueryUiUtils{
    constructor(){}
    
    static initAwesom(){
        awesomplete_init(); 
    }

    static loadTextVal(id,val){
        $('#'+id).autocomplete({
            source: val,
            minLength:0
        });
    }

    static initPopup(id,popParam){
        $('#'+id).dialog(popParam);
    }

    static showPopup(id){
        $('#'+id).dialog('open');
    }

    static hidePopup(id){
        $('#'+id).dialog('close');
    }

    static hidePopupAndRefresh(id){
         hidePopup(id);
         window.location=window.location;
    }

    static setInputValue(id,val){
        $('#'+id).val(val);
    }

    static getInputValue(id){
        return $('#'+id).val();
    };

    static drag(id,options){
        $('#'+id).drag(options);
    }


  }
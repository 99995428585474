import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RedemptionRecordItemModel } from 'src/app/data/models/redemptionRecordItem.model';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import {TranslateService} from '@ngx-translate/core';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { ErrHandler } from '../../../data/util/errHandler';
import { TransactionLogService } from '../../../data/service/transactionLog.service';
import { ComponentUtil } from '../../../data/util/componentUtil';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';



@Component({
  selector: 'app-myewallet-mgm-reward-history-detail',
  templateUrl: './myewallet-mgm-reward-history-detail.component.html',
  styleUrls: ['./myewallet-mgm-reward-history-detail.component.css']
})
export class MyewalletMgmRewardHistoryDetailComponent implements OnInit{

  @Input()
  data:RedemptionRecordItemModel;

  rewardHists:RedemptionRecordItemModel[]=[];

  defaultLimit:number=10;

  nextOffset:number=0;

  totalNumber:number;

  constructor(private transactionLogService:TransactionLogService,private errMsgUtil:ErrMsgUtil,private jwtUtil:JwtUtil,private translateService:TranslateService,private componentUtil:ComponentUtil,private popupMsgUtil:PopupMsgUtil) {}
  
  ngOnInit(): void {
    this.defaultLimit=this.componentUtil.getPageLimit();
  }

  initDetail(){
    this.totalNumber=0;
    this.nextOffset=0;
    this.searchRedemptionRecordByCode(this.data.couponCode, this.defaultLimit, 0, true,'mgm');
  }
  
  searchRedemptionRecordByCode(couponCode:string,limit:number,offset:number,reset:boolean,page:string){
    this.popupMsgUtil.loading();
    if(reset) {
      this.rewardHists=[];
    }
    this.transactionLogService.getRedemptionRecordsByCouponCode(limit,offset,couponCode,page,false).subscribe(
      (res)=>{
          this.nextOffset = offset + limit;
          res = this.jwtUtil.setupResponse(res);
          this.totalNumber = res.totalNumber;

          if(res.data) {
              let addDatas:RedemptionRecordItemModel[] = res.data;
              if(addDatas&&addDatas.length>0){
                  for(let i = 0; i < addDatas.length; i++){
                      this.rewardHists.push(addDatas[i]);
                  } 
              }
          }

          this.popupMsgUtil.endLoading();
      }, (err) => {
          const _this = this;
          function getHandler(): ErrHandler{
              let handler = <ErrHandler> <unknown> function() {};

              handler.handleErr = function() {};

              handler.handleRefreshToken = function() {
                  _this.searchRedemptionRecordByCode(couponCode, limit, offset, reset, page);
              }

              return handler;
          }

          let eh:ErrHandler = getHandler();
          this.errMsgUtil.handleErr(eh,err.error);
      }
    );
  }

  clickMore(){
    if(this.data.couponCode){
      this.searchRedemptionRecordByCode(this.data.couponCode,this.defaultLimit,this.nextOffset,false,'mgm');
    }
  }


}

import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { CreateCouponReq } from '../models/createCouponReq.model';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ApiUtil } from '../util/apiUtil';
import { JwtUtil } from '../util/jwtUtils';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreateCouponService {
  constructor(
    private http: HttpClient,
    private apiUtil: ApiUtil,
    private jwtUtil: JwtUtil,
  ) { }

  defaultHeader = { 'Content-Type': 'application/json' };

  private createCouponUrl: string = environment.apiUrl + '/ewallet/api/coupons';

  createCoupon(couponItemCode: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        ewAccessToken: this.jwtUtil.getAccessToken()
      }),
      observe: "response",
      responseType: "json",
      reportProgress: true
    };
    var req = new CreateCouponReq;
    req.couponItemCode = couponItemCode;
    req.grantOrderType = 'Commodity';
    req.order = {salesChannelCode:"ONLINE"};
    req.source = {sys:"COUPON"};
    return this.apiUtil.doPost(this.createCouponUrl, req, httpOptions);
  }
}

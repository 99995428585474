import { CouponItemModel } from "./couponItem.model";

export class RedemptionRecordItemModel{
    logId?:string;
    actionType?:string;
    actionDate?:string;
    couponCode?:string;
    staffId?:string;
    staffName?:string;
    orderId?:string;
    orderIssueDate?:string;
    channelCode?:string;
    channelGroup?:string;
    redemptionDate?:string;
    redemptionAmount?:number;
    redemptionRemark?:string;
    salesChannelCode?:string;
    salesChannelName?:string;
    source?:string;
    couponItem?:CouponItemModel;
    referralCount?:number;
    referralTarget?:number;
}
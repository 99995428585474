<button id="myewallet-couponList" (click)="init()" style="display:none;"></button>
<button id="myewallet-couponList_search" (click)="searchCouponForCouponName()" style="display:none;"></button>
<div class="container-fluid couponScrollFreshView" id=""
      style="padding-top:{{channel!='MyHKT'?(isWeb?114:0):114}}px; overflow:hidden;">
      <div *ngIf="activeTab=='coupon-tab' || activeTab=='myewallet-couponList'" class="row" infinite-scroll
            [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12"
                  style="padding-top:1rem;padding-right:30px;padding-left:15px;display: grid;grid-column: 1 / span 12;">
                  <app-myewallet-nameCard (qrCode)="clickQrCode()" (share)="clickShare()"
                        [tab]="'coupon'"></app-myewallet-nameCard>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-12" style="padding-right: 35px;">
                  <app-myewallet-brandfilter [issueBrandList]="issueBrandList" [resultBrandMap]="resultBrandMap"
                        [filterBrand]="filterBrand" (brandChange)="brandChange($event)"></app-myewallet-brandfilter>
                  <div class="secondly_header"
                        *ngIf="resultBrandMap&&resultBrandMap.size>0&&(!(resultBrandMap.size==1&&resultBrandMap.get(appIssueBrand)))"
                        style="position:absolute;overflow:auto;width:92%;top:{{resultBrandMap.size==1&&resultBrandMap.get(appIssueBrand)?20:75}}px;height:1px;background-color:var(--splitColor);">
                  </div>
                  <app-myewallet-couponstatusfilter [page]="'coupon'" [filterStatus]="filterStatus"
                        (statusChange)="statusChange($event)"></app-myewallet-couponstatusfilter>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3"
                  style="padding-top:10px;padding-bottom:10px;padding-left:10px;padding-right:10px;"
                  *ngFor="let cp of couponList">
                  <div class="walletCard"
                        style="padding-left:0px;padding-right:0px;vertical-align: middle;border-radius:15px;border:1px solid #B5B2B2;">
                        <app-myewallet-couponCard [cp]='cp' [favoritesString]='favoritesString'
                              (favoritesStringChanged)="fromChildFunc($event)"
                              (openDetail)="goDetail(cp.couponCode||'',cp.couponItem.couponItemCode)"
                              (share)="clickShare()" [tab]="'coupon'" (refreshList)="init()"
                              (openPromoCpnDetail)="openPromoCpnDetail($event)">
                        </app-myewallet-couponCard>
                  </div>
            </div>

            <!-- <p *ngIf="(showCouponList&&couponList&&(couponList.length!=0)&&(nextOffset>=totalNumber))||(((channel=='csl')||(channel=='1010')||(channel=='clubsim')||(channel=='MyHKT'))&&(couponList==undefined?true:couponList.length==0)&&!(filterStatus=='All'||filterStatus=='REDEEMABLE'))" class="noMoreCoupon">{{'noMoreCoupon'|translate}}</p>
      <p *ngIf="channel=='1010'&&(couponList==undefined?true:couponList.length==0)&&(filterStatus=='All'||filterStatus=='REDEEMABLE')" class="noMoreCoupon">{{'noMoreCoupon_1010'|translate}}</p>
      <p *ngIf="channel=='csl'&&(couponList==undefined?true:couponList.length==0)&&(filterStatus=='All'||filterStatus=='REDEEMABLE')" class="noMoreCoupon">{{'noMoreCoupon_csl'|translate}}</p> -->

            <!-- <p *ngIf="showCouponList" class="noMoreCoupon"> -->
            <p *ngIf="showCouponList && !(couponList&&couponList.length>0&&allCouponList&&allCouponList.length>0&&totalNumber>(nextOffset))"
                  class="noMoreCoupon">
                  {{((channel == '1010' && (couponList == undefined?true:couponList.length == 0) && (filterStatus ==
                  'All' || filterStatus == 'REDEEMABLE')) ?
                  'noMoreCoupon_1010' :
                  ((channel == 'csl' && (couponList == undefined?true:couponList.length == 0) && (filterStatus == 'All'
                  || filterStatus == 'REDEEMABLE')) ?
                  'noMoreCoupon_csl' :
                  (channel == 'MyHKT' && (couponList == undefined?true:couponList.length == 0))?
                  'noMoreCoupon_MyHKT':
                  'noMoreCoupon')) | translate}}</p>
            <!--
      <div class="col-12" *ngIf="showCouponList&&couponList.length==0">
            <p style="text-align: center;font-size:19px;margin-top:60px;">{{'noCouponFound'|translate}}</p>
      </div>
      <input type="text" id="testToken" style="display: none;"/> 
-->
      </div>

      <div class="row">
            <p class="col-12" style="text-align: center;margin-top:10px;"
                  *ngIf="couponList&&couponList.length>0&&allCouponList&&allCouponList.length>0&&totalNumber>(nextOffset)"
                  (click)="moreCoupon()">{{'more'|translate}}▼</p>
      </div>

      <!--&&totalNumber>(nextOffset+defaultLimit)-->
</div>

<button id="couponRedemptionHistroyModalBtn" style="display: none;" data-toggle="modal"
      data-target="#redemptionHistoryModal" (click)="clickRedemptionRecords()"></button>
<div class="modal fade secondly_header" id="redemptionHistoryModal"
      style="padding-right:0px;position:flex;top:0%;height:100%;overflow: hidden;overflow-y:scroll;">
      <div class="secondly_header modal-dialog modal-lg " style="max-width:100%;margin:0px;">
            <div class="secondly_header modal-content" style="padding-left:0px;padding-right:0px;border:none;">
                  <div class="secondly_header modal-body  container-fluid">
                        <!--<i id="nameCardQrClose" class="close popClose" data-dismiss="modal" >×</i>-->
                        <img class="close popClose" data-dismiss="modal"
                              src="/assets/common/image/icons/{{channel}}/icon_close.png" width="30" height="30" />
                        <app-myewallet-redemptionRecord [page]="'coupon'"></app-myewallet-redemptionRecord>
                  </div>
            </div>
      </div>
</div>
import { Injectable, Injector, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { LoginModel } from '../models/login.model';
import { Location } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import { CustomerInfoModel } from '../models/customerInfo.model';

@Injectable({ providedIn: 'root' })
export class JwtUtil{
    constructor(
        private router: Router,
    ){}

    //enum
    validateNoAccess:string="0";
    validateAccess:string="1";
    validating:string="2";
    

    getAppImg():string{
      var jwtType=this.getServiceAppType();
      var appImg=this.getImgCsl();
      if(jwtType){
          //  if(jwtType==this.getJwtTypeMyHkt()){
           if(jwtType.indexOf(this.getJwtTypeMyHkt())>-1){
             appImg=this.getImgMyHkt();
           }
          //  else if(jwtType==this.getJwtType1010()){
           else if(jwtType.indexOf(this.getJwtType1010())>-1){
             appImg=this.getImg1010();
           }
           else if(jwtType.indexOf(this.getJwtTypeClubsim())>-1){
             appImg=this.getImgClubsim();
           }
      }
      return appImg;
    }

    getBrandImage():Map<string,string>{
          var bi:Map<string,string>=new Map<string,string>();
          bi.set('csl.',sessionStorage.getItem('csl.'));
          bi.set('CSL',sessionStorage.getItem('CSL'));
          bi.set('1010',sessionStorage.getItem('1010'));
          bi.set('1O1O',sessionStorage.getItem('1O1O'));
          bi.set('Club SIM',sessionStorage.getItem('Club SIM'));
          bi.set('HKT',sessionStorage.getItem('HKT'));
          return bi;
    }

    setupResponse(res):any{
      if(res.headers){//response type of api ruturn result
           var hs;
          //  console.log(res);
           hs=res.headers;
           res=res.body;
          //  console.log(hs);
          //  console.log(hs.get('IdType'));
          // this.setServiceAppTokenHeader(hs);
      }
      return res;
    }

    //remove the jwt key from storge
    removeJwtToken(){
      this.removeServiceAppType();
      this.removeServiceAppToken();
      this.removeValidateStatus();
      this.removeCustomerInfo();
      this.remove2ndToken();
    }

    // setupJwtMode(){//1 is old jwt   0 or null is new default jwt
    //   localStorage.setItem("JWTMode","1");
    // }

    // removeJwtMode(){
    //   // localStorage.removeItem("JWTMode");
    //   this.removeTokenType();
    //   this.removeServiceAppType();
    //   this.removeServiceAppToken();
    //   // this.removeServiceAppTokenHeader();
    //   this.removeJwtValidate();
    //   this.removeCustomerInfo();
    //   this.removeAccessToken();
    //   this.removeRefreshTocken();
    // }

    // removeOldJwtMode(){
    //   localStorage.removeItem("JWTMode");
    //   this.removeUserName();//not remove access and refresh token
    // }

    // removeNewJwtMode(){
    //   localStorage.removeItem("JWTMode");
    //   this.removeTokenType();
    //   this.removeServiceAppType();
    //   this.removeServiceAppToken();
    //   // this.removeServiceAppTokenHeader();
    //   this.removeJwtValidate();
    //   this.removeCustomerInfo();
    // }

    //T1 get app type from token's options is 'csl 1010 myhkt' 
    setServiceAppType(serverAppType){
      sessionStorage.setItem('serviceAppType',serverAppType);
    }

    getServiceAppType():string{
      return sessionStorage.getItem('serviceAppType');
    }

    getSubServiceAppType():string{
      var result:string=null;
      var jwtType=this.getServiceAppType();
      if(jwtType){
           if(jwtType.indexOf(this.getJwtTypeMyHkt())>-1){
             result=this.getJwtTypeMyHkt();
           }
           else if(jwtType.indexOf(this.getJwtType1010())>-1){
             result=this.getJwtType1010();
           }
           else if(jwtType.indexOf(this.getJwtTypeCsl())>-1){
             result=this.getJwtTypeCsl();
            // result=this.getJwtTypeClubsim();
           }
           else if(jwtType.indexOf(this.getJwtTypeClubsim())>-1){
             result=this.getJwtTypeClubsim();
           }
      }
      return result;
    }

    getIssueBrandFromServiceAppType():string{
      var result:string=null;
      var jwtType=this.getServiceAppType();
      if(jwtType){
           if(jwtType.indexOf(this.getJwtTypeMyHkt())>-1){
             result='HKT';
           }
           else if(jwtType.indexOf(this.getJwtType1010())>-1){
             result='1010';
           }
           else if(jwtType.indexOf(this.getJwtTypeCsl())>-1){
             result='CSL';
           }
           else if(jwtType.indexOf(this.getJwtTypeClubsim())>-1){
             result='clubsim';
           }
      }
      return result;
    }

    removeServiceAppType(){
      sessionStorage.removeItem('serviceAppType');
    }

    //T2 get the token
    setServiceAppToken(serverAppToken){
      sessionStorage.setItem('serviceAppToken',serverAppToken);
    }

    getServiceAppToken():string{
      return sessionStorage.getItem('serviceAppToken');
    }

    removeServiceAppToken(){
      sessionStorage.removeItem('serviceAppToken');
    }

    
    // setJwtValidate(validate){
    //    sessionStorage.setItem("serviceAppValidate",validate);
    // }

    // getJwtValidate(){
    //   return sessionStorage.getItem("serviceAppValidate");
    // }

    // removeJwtValidate(){
    //   sessionStorage.removeItem("serviceAppValidate");
    // }

    //T3 get the validate status
    setValidateStatus(){
      sessionStorage.setItem("validateStatus","1");
    }

    getValidateStatus():boolean{
      var result:boolean=false;
      var validateStatus=sessionStorage.getItem("validateStatus");
      if(validateStatus&&validateStatus=="1"){
        result=true;
      }
      return result;
    }

    removeValidateStatus(){
      sessionStorage.removeItem("validateStatus");
    }

    setGetTokenStatus(){
      sessionStorage.setItem("getToken","1");
    }

    getGetTokenStatus(){
      var result:boolean=false;
      var validateStatus=sessionStorage.getItem("getToken");
      if(validateStatus&&validateStatus=="1"){
        result=true;
      }
      return result;
    }

    removeGetTokenStatus(){
      sessionStorage.removeItem("getToken");
    }

    //T4 name card info setup
    setCustomerInfo(info:CustomerInfoModel){
      if(localStorage&&localStorage.getItem("JWTMode")=="1"){ 
          if(info){
             sessionStorage.setItem("CI_ID",info.id);
             sessionStorage.setItem("CI_NAME",info.name);
             sessionStorage.setItem("CI_NICKNAME",info.nickname);
             sessionStorage.setItem("CI_MRT",info.mrt);
             sessionStorage.setItem("CI_IMAGEURL",info.imageUrl);
             sessionStorage.setItem("CI_REFERERCD",info.referrerCd);
          }    
      }
      else{//old jwt hard code
        // sessionStorage.setItem("CI_ID",null);
        // sessionStorage.setItem("CI_NAME",null);
        sessionStorage.setItem("CI_NICKNAME","Ttrttyyy()$$!!@@\"@!?,");
        sessionStorage.setItem("CI_MRT","54703808");
        sessionStorage.setItem("CI_IMAGEURL","http://uat.sdeweb.hkcsl.com/cs/profile/?hash=eyJhbGciOiJIUzI1NiJ9.eyJhY3Rpb24iOjIwMCwibXNpc2RuIjo1NDcwMzgwOH0.iF6mvb9J0pLx3-E1-9PI6pETOwExpDellZOkNV3JMXA");
        // sessionStorage.setItem("CI_REFERERCD",null);
      }
    }

    removeCustomerInfo(){
      sessionStorage.removeItem("CI_ID");
      sessionStorage.removeItem("CI_NAME");
      sessionStorage.removeItem("CI_NICKNAME");
      sessionStorage.removeItem("CI_MRT");
      sessionStorage.removeItem("CI_IMAGEURL");
      sessionStorage.removeItem("CI_REFERERCD");
    }

    
    //2 refresh token jwt setting
    // setUserName(userName){
    //   localStorage.setItem('userName',userName);
    // }

    // getUserName():string{
    //   return localStorage.getItem('userName');
    // }

    // removeUserName(){
    //   localStorage.removeItem('userName');
    // }

    //T6 the 2th layer jwt token++
    setAccessToken(accessToken){
      sessionStorage.setItem('accessTocken',accessToken);
    }

    getAccessToken():string{
      return sessionStorage.getItem('accessTocken');
    }

    removeAccessToken(){
      sessionStorage.removeItem('accessTocken');
    }

    setRefreshTocken(refreshTocken){
      sessionStorage.setItem('refreshTocken',refreshTocken);
    }

    getRefreshTocken():string{
      return sessionStorage.getItem('refreshTocken');
    }

    removeRefreshTocken(){
      sessionStorage.removeItem('refreshTocken');
    }

    setup2ndToken(res){
        var li:LoginModel=res;
        // this.setUserName(li.user.username);
        this.setAccessToken(li.accessToken);
        this.setRefreshTocken(li.refreshToken);
    }

    remove2ndToken(){
        // this.removeUserName();
        this.removeAccessToken();
        this.removeRefreshTocken();
    }
   //T6 the 2th layer jwt token--

    //T7 session time setting
    setupSessionTime(){
      if(!sessionStorage.getItem("sessionTime")){
        var n:Date=new Date();
        sessionStorage.setItem("sessionTime",""+n.getTime());
      }
    }

    refreshSessionTime(){
      var n:Date=new Date();
      sessionStorage.setItem("sessionTime",""+n.getTime());
    }



     //constant var define and get++
    getJwtTypeCsl():string{
      // return 'JWT-CSL';
      return 'csl';
    }

    getJwtType1010():string{
      // return 'JWT-1010';
      return '1010';
    }

    getJwtTypeMyHkt():string{
      // return 'JWT-MYHKT';
      return 'MyHKT';
    }

    getJwtTypeClubsim():string{
      return 'clubsim';
    }

    getTokenTypeParam():string{
      return 'param';
    }

    getTokenTypeHeader():string{
      return 'header';
    }

    getCssCsl():string{
      return 'csl';
    }

    getCss1010():string{
      return '1010';
    }

    getCssMyHkt():string{
      return 'myhkt';
    }

    getCssClubsim():string{
      return 'clubsim';
    }

    getImgCsl():string{
      return '/assets/common/image/mgm/csl_5g.png';
    }

    getImg1010():string{
      return '/assets/common/image/mgm/1010_5g.png';
    }

    getImgMyHkt():string{
      return '/assets/common/image/mgm/My HKT logo.png';
    }

    getImgClubsim():string{
      return '/assets/common/image/CLUBSIM.png';
    }
    
    getValidateNoAccess():string{
        return this.validateNoAccess;
    }

    getValidateAccess():string{
      return this.validateAccess;
    }

    getValidating():string{
      return this.validating;
    }
    //constant var define and get--
    
}
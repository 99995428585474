import { Component, OnInit,OnDestroy, ErrorHandler,Input,Output,EventEmitter } from '@angular/core';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { ShareUtils } from '../../../../assets/common/util/shareutil';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { ComponentUtil } from '../../../data/util/componentUtil';
import { EwalletMgmService } from '../../../data/service/ewalletMgm.service';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { ErrHandler } from '../../../data/util/errHandler';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { environment } from '../../../../environments/environment';
import { Base64Utils } from '../../../../assets/common/util/base64util';
import { FavroitesService } from '../../../data/service/favroites.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
    selector: 'app-myewallet-brandfilter', 
    // directives: [ InfiniteScroll ],
    templateUrl: './myewallet-brandfilter-component.html',
    styleUrls: ['./myewallet-brandfilter-component.scss']
})
export class MyEwalletBrandFilterComponent implements OnInit{

  @Input()
  resultBrandMap:Map<string,number>=new Map<string,number>();

  @Input()
  issueBrandList:string[];

    @Input()
    filterBrand:string;

    appIssueBrand:string;

    @Output()
    brandChange= new EventEmitter<any>();

    brandImageMap:Map<string,string>=new Map<string,string>();

    sessionStorage(id){
      return sessionStorage.getItem(id);
  }
  
    constructor(private jwtUtil:JwtUtil){}


    ngOnInit() {
        this.appIssueBrand=this.appIssueBrand=this.jwtUtil.getIssueBrandFromServiceAppType();
        this.brandImageMap=this.jwtUtil.getBrandImage();
        console.log(this.brandImageMap.size);
    }

    changeBrand(brand:string,noInit:boolean){
        if(noInit){
          if(this.filterBrand==brand){
            this.filterBrand='ALL';
            brand='ALL';
         }
        }
        this.filterBrand=brand;
        this.brandChange.emit(this.filterBrand);
        // console.log(this.filterBrand);
        // this.filterResult(this.filterBrand,this.filterStatus,this.filterType,this.filterCouponName);
        // this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,this.filterBrand,this.filterStatus,this.filterType,this.filterCouponName);
        // this.changeBrandSelectStyle(brand,"brand-item");
        // this.changeBrandSelectStyle(brand,"brandImage");
        // var brandUl=document.getElementsByClassName('brand')[0];
        // if(brand==''){
        //   brandUl.setAttribute("style","margin:0px;padding-top:8px;");
        // }
        // else{
        //   brandUl.setAttribute("style","margin:0px;padding-top:6px;");
        // }
        // this.jwtUtil.setupSessionTime();
        //  sessionStorage.setItem("myWallet_brand_filter",this.filterBrand);
         
      }
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { LoginService} from '../../data/service/login.service';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoginModel } from '../../data/models/login.model';
import { ErrMsgUtil } from '../../data/util/errMsgUtil';
import { JwtUtil } from '../../data/util/jwtUtils';
import { PopupMsgUtil } from '../../data/util/popupMsgUtil';
// import * as $ from 'jquery';
// import * as daterangepicker  from '../../../../node_modules/daterangepicker/daterangepicker.js';
// import * as moment from '../../../../node_modules/daterangepicker/moment.min.js';
// import { DateRangePickerUtils } from '../../../assets/common/util/daterangpickerutil.js';
import { DateRangePickerUtils } from '../../../assets/common/util/daterangepickerutil';
import { Base64Utils } from '../../../assets/common/util/base64util';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginId: string;
    pws: string;
    callWeb:boolean;

    showMsg:boolean=false;
    msg:string;

    constructor(private route: ActivatedRoute,private router: Router,private loginServer:LoginService,
      private errMsgUtil:ErrMsgUtil,private jwtUtil:JwtUtil,private popupMsgUtil:PopupMsgUtil,) {
    }
//private daterangepickerutil: DateRangePickerUtils

    
    ngOnInit() {
        this.showMsg=false;
        this.callWeb=false;
        var msgCode=this.route.snapshot.paramMap.get('msgCode');
        if(msgCode&&msgCode.length>0){
            this.showMsg=true;
            switch(msgCode){
                case 'unAuth':this.msg='msg.unauthMsg'; break;
                case 'noAccess':this.msg='msg.loginFailureMsg'; break;
                default:this.msg='msg.loginErrorMsg';break;
            }
        }
        //  normal('testDateRange');
        // this.daterangepickerutil.normalRange('testDateRange');
        DateRangePickerUtils.normalRange('testDateRange');

        // console.log('1233');
        // var token=Base64Utils.decode('eyJzdWJLZXlJZCI6IjAxIiwic3ViIjoiMkhtcUx1VGVTNTU4eG93N2l4RjVoZz09IiwiY3VzdFR5cGUiOiIwMSIsImNoYW5uZWwiOiJjc2wtYXBwIiwic3ViVHlwZSI6IjAxIiwiZXhwIjoxNjQ2MTg4NTgwLCJzaWduS2V5SWQiOiIwMSIsInRva2VuIjoiRzZ6ZzB3WS9wd2JnenFNWjVxQyt3SzJkUmJmWVM5TisxSmpqbC95bmhQMHhSak1lbWJmSlMvckZSWHJ4d2krdCJ9');
        // console.log(token);
        // var tokenObj=JSON.parse(token);
        // console.log(tokenObj);
        // console.log(tokenObj.channel);

        // var allStr='eyJ0eXAiOiJKV1QtMTAxMCIsImFsZyI6IkhTMjU2In0.eyJzdWJLZXlJZCI6IiIsInN1YiI6IlNSbExuK2xKMCtjelBMMzBtSDJzb1E9PSIsImN1c3RUeXBlIjoiMDEiLCJjaGFubmVsIjoiY3NsLXdlYiIsInN1YlR5cGUiOiIwMSIsImV4cCI6MTY0NjE4ODU4MCwic2lnbktleUlkIjoiIiwiaWF0IjoxNjE2MTQ1Njk3LCJ0b2tlbiI6ImRlMGMzMjczYTJmMzk0OThkOGM3NjU2YTgzMWRlMDgyYTVkMjQ1ODcyMjY0NmI0ZjVmZDZmYTJjOGVhNjViOWQifQ.UaTTNO7OkQgKyc5JuazT8w868GU-TwDvjtl3jIR82js';
        // var s=allStr.split('.');
        // var subStr=s[1];
        // console.log(subStr);
        // var token2:string=Base64Utils.decode(subStr);
        // // var token2='{"subKeyId":"","sub":"SRlLn+lJ0+czPL30mH2soQ==","custType":"01","channel":"csl-web","subType":"01","exp":1646188580,"signKeyId":"","iat":1616145697,"token":"de0c3273a2f39498d8c7656a831de082a5d2458722646b4f5fd6fa2c8ea65b9d"}'
        // console.log(token2);
        // // token2=token2.substr(0,token2.length-2);
      
        // var aa='{a} ';
        // console.log(aa.substr(0,aa.indexOf('}')));

        // console.log(aa.endsWith(' '));
        // console.log(token2.endsWith(''));
        // // token2=token2.substr(1,token2.length-3);
        // // console.log(token2);
        // console.log(JSON.parse(token2));


        // document.getElementById('testDateRange').focus();

        // var dr=document.getElementById('testDateRange');
        // var dr=$('#testDateRange');
        // var bb={
        //   timePicker:true,
        //   startDate:moment().startOf('hour'),
        //   endDate:moment().startOf('hour').add(32, 'hour'),
        //   locale: {
        //   format:'M/DD hh:mm A'
        //   }
        // };
        // var bb1={
        //   opens:'left'
        //   }
        // daterangepicker(dr,bb1,null);
        // $('#testDateRange').daterangepicker({
        //   timePicker:true,
        //   startDate:moment().startOf('hour'),
        //   endDate:moment().startOf('hour').add(32, 'hour'),
        //   locale: {
        //   format:'M/DD hh:mm A'
        //   }
        // });
        // console.log(dr);
    }

    login(form){
        console.log(form);
        this.popupMsgUtil.loading();
        var isHeader=environment.headerMode;
        this.loginServer.login(form).subscribe(
             (res) => {
              console.log('login info:'+res);
              sessionStorage.clear();
              localStorage.clear();
              // this.jwtUtil.removeJwtToken();
              // var n:Date=new Date();
            //   sessionStorage.setItem("sessionTime",""+n.getTime());
               var li:LoginModel=res;    
               if(!isHeader){
                 this.jwtUtil.refreshSessionTime();
                //  sessionStorage.setItem('serviceAppType',this.configService.get("serviceAppType"));
                //  sessionStorage.setItem("serviceAppToken",this.configService.get("serviceAppToken"));
                 this.jwtUtil.setServiceAppType('jwt-csl');//remove not access
                 this.jwtUtil.setServiceAppToken('123');
                 console.log(localStorage);
                 this.popupMsgUtil.endLoading();
                 this.router.navigateByUrl('/myewallet');
               }
               else{
                //  localStorage.setItem("JWTMode","0");
                //  localStorage.setItem("userName",li.user.username);
                //  localStorage.setItem("accessToken",li.accessToken);
                //  localStorage.setItem("refreshToken",li.refreshToken);
                //  this.jwtUtil.setAccessToken(li.accessToken);
                //  this.jwtUtil.setRefreshTocken(li.refreshToken);
                this.msg="No header support";
               }

             },(err) => {
              console.log(err);
              this.popupMsgUtil.endLoading();
               if(err.status!='403'){
                // this.errMsgUtil.popupMsg("Request error:"+err.status+" "+err.statusText);
                this.errMsgUtil.popErrMsg(err,'','');
               }
              this.router.navigateByUrl('/login/unAuth');
              // alert(err);
            });
   
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something bad happened; please try again later.');
      };
}
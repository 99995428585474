<button id="{{page}}couponDetailInit" (click)="initCouponDetail()" style="display:none;"></button>
<p id="{{page}}couponDetailInitCouponCode" style="display:none;"></p>
<div class="container" style="padding:0px;">
    <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
            <img src="{{
                 couponDetail&&couponDetail.couponItem?
                 couponDetail.couponItem.imageUrl=='tes112501'||couponDetail.couponItem.imageUrl=='test'?'/assets/common/image/b.jpg'
                 :lang == 'zh-HK'?couponDetail.couponItem.imageUrlCht:couponDetail.couponItem.imageUrl
                  :''   
                }}" 
            width="100%" height="auto" />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
            <p class="bookRemark1_bold" style="text-align:left;padding-top:30px;padding-left:22px;margin-right:22px;"  data-couponItemName>
                {{couponDetail&&couponDetail.couponItem?
                    lang == 'zh-HK'?couponDetail.couponItem.nameCht:
                    couponDetail.couponItem.name:''}}</p>
            
            <div class="bookRemark1" data-special style="text-align:left;padding-left:22px;margin-right:22px;word-break: break-word;" [innerHTML]="couponDetail&&couponDetail.couponItem?lang == 'zh-HK'?couponDetail.couponItem.descriptionCht:couponDetail.couponItem.description:''"></div>
            
            <p class="bookRemark1_bold" data-detailBalance style="text-align:left;padding-left:22px;margin-right:22px;" *ngIf="page=='mgm'?(couponDetail&&couponDetail.couponItem?couponDetail.couponItem.isRefereeCoupon==false||couponDetail.couponItem.isRefereeCoupon==null:false):false">
                {{'successfulReferrals'|translate}}/ {{'totalRequiredReferrals'|translate}}:&nbsp;
                {{couponDetail&&couponDetail.couponItem?(couponDetail.referralCount?couponDetail.referralCount:'0')+'/'+(couponDetail.couponItem.referralTarget?couponDetail.couponItem.referralTarget:(couponDetail.couponItem.referralTarget==null?'unlimited':0)):'0/0'}}
            </p>
            <p class="bookRemark1_bold" style="text-align:left;padding-left:22px;margin-right:22px;" data-detailBalance *ngIf="couponDetail&&couponDetail.couponItem?couponDetail.couponItem.isRefereeCoupon==false:false">
                <!-- {{(couponDetail && couponDetail.couponItem?(((couponDetail.couponItem.amountDisplay || couponDetail.couponItem.quotaDisplay) && couponDetail.banlanceShowLabel)?couponDetail.banlanceShowLabel+':':''):'')|translate}}{{couponDetail && couponDetail.couponItem?(((couponDetail.couponItem.amountDisplay || couponDetail.couponItem.quotaDisplay) && couponDetail.banlanceShowLabel)?couponDetail.banlanceShowValue:''):''}} -->
                {{(couponDetail&&((couponDetail.couponItem.amountDisplay && couponDetail.couponItem.couponType=='Cash_Discount') || (couponDetail.couponItem.quotaDisplay && couponDetail.couponItem.couponType!='Cash_Discount'))?couponDetail.banlanceShowLabel:'')|translate}}
                {{couponDetail&&couponDetail.banlanceShowLabel&&((couponDetail.couponItem.amountDisplay && couponDetail.couponItem.couponType=='Cash_Discount') || (couponDetail.couponItem.quotaDisplay && couponDetail.couponItem.couponType!='Cash_Discount'))?':':''}}
                {{couponDetail&&((couponDetail.couponItem.amountDisplay && couponDetail.couponItem.couponType=='Cash_Discount') || (couponDetail.couponItem.quotaDisplay && couponDetail.couponItem.couponType!='Cash_Discount'))?couponDetail.banlanceShowValue:''}}
            </p>
            <div class="bookRemark1_couponCode" data-special data-validDate style=" text-align: left; margin-left:18px;padding-left:8px;padding-right:10px;padding-top:2px;padding-bottom:2px;" *ngIf="couponDetail&&isSpringBoarCoupon!='SB'">
                <p class="couponCardDateRange" style="display:inline-block;padding-left:5px;padding-right:5px">
                    {{'validFrom'|translate}}
                    <!-- <app-date [page]="couponDetailValidFrom" [date]="couponDetail.validFrom||(couponDetail.couponItem&&couponDetail.couponItem.defaultFromDate)"></app-date>
                    {{'dateMiddleText'|translate}}
                    <app-date [page]="couponDetailValidTo" [date]="couponDetail.validTo||(couponDetail.couponItem&&couponDetail.couponItem.defaultToDate)"></app-date> -->
                    {{couponDetail.validFrom?couponDetail.validFrom:couponDetail.couponItem.defaultFromDate}}
                    {{'dateMiddleText'|translate}}
                    {{couponDetail.validTo?couponDetail.validTo:couponDetail.couponItem.defaultToDate}}
                </p>
            </div>
        </div>
        <div class="col-12" style="padding-top:36px;padding-bottom:12px;">
            <div class="row" *ngIf="couponDetail&&!couponDetail.is3rdCoupon&&couponDetail.couponCode">
                <p style="text-align:center;margin-bottom:0px;margin-left:5px;padding-bottom:3px;" class="col-12 bookRemark1_couponCode" data-special data-couponCode>{{'couponCode'|translate}}:&nbsp;<b>{{couponDetail?(couponDetail.couponAlias?couponDetail.couponAlias:couponDetail.couponCode):''}}</b></p>
                <!--<img src="/assets/common/image/icons/qrcode_logo_{{couponDetail&&couponDetail.couponItem?couponDetail.couponItem.issueBrand:'csl'}}.png" width="40" height="40" 
                style="position:absolute;left:-webkit-calc(50%-20px);left:-moz-calc(50% - 20px); left:calc(50% - 20px);top:115px;"/>-->
                <div class="col-12" style="text-align: center; height: 150px;">
                <img src="{{sessionStorage(couponDetail&&couponDetail.couponItem?couponDetail.couponItem.issueBrand:'csl.')}}" style="max-width:35px;max-height:35px;position:absolute;left:-webkit-calc(50%);left:-moz-calc(50%); left:50%; transform:translate(-50%,-50%);top:50%;z-index:80;"/>
                <div class="couponCricle" style="position:absolute;width:40px;height:40px;
                left:-webkit-calc(50%);left:-moz-calc(50%); left:50%;transform:translate(-50%,-50%);top:50%;
                border-radius:40px 40px 40px 40px ;">
                 </div>
                <qrcode class = "m-auto couponQr" 
                [qrdata]="couponDetail?(couponDetail.couponAlias?couponDetail.couponAlias:couponDetail.couponCode):'init'" [size]=150 [level]="'L'" [width]=150 [margin]=1></qrcode>
            </div>
            </div>
            <div class="row" *ngIf="couponDetail&&couponDetail.is3rdCoupon&&!couponDetail.isShowToRedeem&&couponDetail.status=='REDEEMED'">              
                <p *ngIf="couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='Q'" style="text-align:center;margin-bottom:0px;margin-left:5px;padding-bottom:3px;font-size: 18px;" class="col-12 bookRemark1_couponCode" data-special>{{'showQrCode'|translate}}</p>
                <qrcode *ngIf="couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='Q'" class = "m-auto couponQr"  
                [qrdata]="couponDetail.providerCoupon.couponCode?couponDetail.providerCoupon.couponCode:'init'" [size]=150 [level]="'L'" [width]=150 [margin]=1></qrcode>
                <div *ngIf="couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='T'" class="col-1"></div>
                <p *ngIf="couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='T'" id="{{page}}3rdText" class="col-8" style="text-align: center;margin-top: 10px;">{{couponDetail.providerCoupon.couponCode}}</p>
                <img *ngIf="couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='T'&&channel!='MyHKT'" src="/assets/common/image/buttons/{{channel}}/button_copy.png" class="col-2 rounded" width="40px" height="40x" style="margin-right:20px;display: inline;max-width:60px;" (click)="clickCopyCouponCode()" />
                <img *ngIf="couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='T'&&channel=='MyHKT'" src="/assets/common/image/buttons/{{channel}}/button_copy.png" class="col-2 rounded" style="margin-right:20px;display: inline;max-width:44px; border:1px var(--primary) solid; padding: 0px" (click)="clickCopyCouponCode()" />
                <a id="{{page}}linka" *ngIf="couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='L'&&channel!='MyHKT'" class="col-12" style="text-align: center;color: var(--primary);display:none;" href="{{appsUrlPrefix+couponDetail.providerCoupon.url}}" target="_top">{{couponDetail.providerCoupon.url}}</a>
                <a id="{{page}}linka" *ngIf="couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='L'&&channel=='MyHKT'" class="col-12" style="text-align: center;color: var(--primary);display:none;" href="{{appsUrlPrefix+couponDetail.providerCoupon.url}}" target="_top">{{couponDetail.providerCoupon.url}}</a>
                <!-- <div class="col-2" *ngIf="couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='L'"></div> -->
                <!-- <div class="col-2" *ngIf="couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='L'"></div> -->
                <img *ngIf="couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='Q'" src="/assets/common/image/icons/{{channel}}/qrBorder.png"  style="position: absolute;left:-webkit-calc(50%-76px);left:-moz-calc(50% - 76px); left:calc(50% - 76px);top:66px;" width="153" height="153"/>
            </div>
            <div class="row" style="margin-top: 2.7rem;display: block;text-align: center;" *ngIf="(channel=='csl'||channel=='1010'||channel=='MyHKT'||channel=='clubsim')&&couponDetail&&couponDetail.couponItem&&couponDetail.couponItem.isTransferrable&&!couponDetail.is3rdCoupon">
                <button class="buttonRedeem"  style="min-width: 134px;max-width: 300px;" (click)="clickTransfer()">{{'transfer'|translate}}</button>
            </div>
            <div class="row" style="margin-top: 2.7rem;display: block;text-align: center;" *ngIf="couponDetail&&couponDetail.is3rdCoupon&&couponDetail.isShowToRedeem">
                <button [disabled]="isUnderRedeem" class="buttonRedeem" (click)="clickToRedeem()" style="margin-top:10px;min-width: 134px;max-width: 300px;">{{'clickToRedeem'|translate}}</button>
            </div>
            <div class="row"  style="margin-top: 2.7rem;display: block;text-align: center;" *ngIf="couponDetail&&couponDetail.is3rdCoupon&&!couponDetail.isShowToRedeem&&couponDetail.status=='REDEEMED'&&couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='L'">
                <button [disabled]="isUnderRedeem" class="buttonRedeem"  (click)="clickLink()" style="margin-top:10px;min-width: 134px;max-width: 300px;">{{'link'|translate}}</button>
            </div>
            <div class="row" style="margin-top: 2.7rem;display: block;text-align: center;" *ngIf="(channel=='csl'||channel=='1010'||channel=='MyHKT'||channel=='clubsim')&&couponDetail&&couponDetail.statusLabel=='UNUSED'&&couponDetail.couponItem&&(couponDetail.couponItem.couponType=='Gift_Coupon')&&couponDetail.couponItem.stockDisplay">
                <button class="buttonRedeem"  (click)="clickCheckStock()" style="min-width: 134px;max-width: 300px;" >{{'checkStock'|translate}}</button>
            </div>
        </div> 
    </div>
    <!--Miki add 2021/06/17-->
    <div class="row" style="padding:10px 5px 10px 10px;border-top:var(--splitColor) 1px solid;" *ngIf="couponDetail&&couponDetail.couponItem&&(couponDetail.couponItem.redemptionChannelDesc||couponDetail.couponItem.redemptionChannelDescCht)">
        <p class="col-10 col-md-11 col-lg-11 bookRemark2_blod" data-detailTC style="text-align: left;margin-left:14px;margin-top:10px;">{{'redemptionChannel'|translate}}</p>
        <div class="popBotton" style="width:30px;height:30px;border-radius: 50%;margin-top:7px;" >
           <i *ngIf="showRedemChan" class="fa fa-angle-up fa-angle-up_new" style="margin-left:7px;font-size: 25px;" data-toggle="collapse" data-target="#redemChanDetail" (click)="clickCollapseRedemChan()"></i>
           <i *ngIf="!showRedemChan" class="fa fa-angle-down fa-angle-up_new" style="margin-left:7px;font-size: 25px;" data-toggle="collapse" data-target="#redemChanDetail" (click)="clickCollapseRedemChan()"></i>
        </div>
   </div>
   <div id="redemChanDetail" class="collapse show" style="padding:2px;margin:2px;margin-right: 15px;" *ngIf="couponDetail&&couponDetail.couponItem&&(couponDetail.couponItem.redemptionChannelDesc||couponDetail.couponItem.redemptionChannelDescCht)">
    <div class="col-12 detailText bookRemark1_couponCode" data-detailTC style="padding-top: 10px; margin-left:6px; margin-right: 0px;padding-left:10px;padding-right:20px; margin-bottom: 10px; ">
           <div class="col-12" style="margin:4px;padding-left:0px;padding-bottom: 3px; " [innerHTML]="couponDetail&&couponDetail.couponItem?(lang=='zh-HK'?couponDetail.couponItem.redemptionChannelDescCht:couponDetail.couponItem.redemptionChannelDesc):''"></div>
           <!--           couponDetail&&couponDetail.couponItem?
           lang == 'zh-HK'?couponDetail.couponItem.tncCht:
           couponDetail.couponItem.tnc:''-->
    </div>
    </div>

   <div *ngIf="page!='microsite'&&redemptionHists&&redemptionHists.length>0" class="row" style="padding:10px 5px 10px 10px;border-top:var(--splitColor) 1px solid;">
        <p class="col-10 col-md-11 col-lg-11 bookRemark2_blod" data-detailTC style="text-align: left;margin-left:14px;margin-top:10px;">{{'transactionRecords'|translate}}</p>
        <div class="popBotton" style="width:30px;height:30px;border-radius: 50%;margin-top:7px;" >
           <i *ngIf="showRedemHis" class="fa fa-angle-up fa-angle-up_new" style="margin-left:7px;font-size: 25px;" data-toggle="collapse" data-target="#redemHisDetail" (click)="clickCollapseRedemHis()"></i>
           <i *ngIf="!showRedemHis" class="fa fa-angle-down fa-angle-up_new" style="margin-left:7px;font-size: 25px;" data-toggle="collapse" data-target="#redemHisDetail" (click)="clickCollapseRedemHis()"></i>
        </div>
   </div>
    <div *ngIf="page!='microsite'&&redemptionHists&&redemptionHists.length>0" id="redemHisDetail" class="row collapse show" style="padding:2px;margin:2px;">
        <div *ngFor="let item of redemptionHists;let i = index" class="col-12">
            <div class="row detailText" data-special style="padding-top:10px;margin-left:-3px;margin-right:-5px;margin-bottom:10px;" *ngIf="item.actionType=='RDM'||item.actionType=='RFS'">
                <pre class="col-6 bookRemark1_couponCode" style="text-align:left;">{{item.actionType=='RDM'?('redeemedDate'|translate):('SuccessfulReferralDate'|translate)}}:</pre>
                <pre class="col-6 bookRemark1_couponCode_bold" style="text-align:right;"><app-date [date]="item.redemptionDate?item.redemptionDate:item.actionDate" [dateFormat]="dateTimeFormat"></app-date></pre>

                <pre class="col-7 bookRemark1_couponCode" style="text-align:left;" *ngIf="item.couponItem.couponType=='Cash_Discount' && item.couponItem.cashDiscountType=='C'
                && item.couponItem.keepBalance==true">{{'redeemedAmount'|translate}}:</pre>
                <pre class="col-5 bookRemark1_couponCode_bold" style="text-align:right;" *ngIf="item.couponItem.couponType=='Cash_Discount' && item.couponItem.cashDiscountType=='C'
                && item.couponItem.keepBalance==true">HK${{item.redemptionAmount}}</pre>

                <pre class="col-7 bookRemark1_couponCode" style="text-align:left;" *ngIf="item.couponItem.couponType=='Cash_Discount' && item.couponItem.cashDiscountType=='C'
                && item.couponItem.keepBalance==false">{{'redeemedAmount'|translate}}:</pre>
                <pre class="col-5 bookRemark1_couponCode_bold" style="text-align:right;" *ngIf="item.couponItem.couponType=='Cash_Discount' && item.couponItem.cashDiscountType=='C'
                && item.couponItem.keepBalance==false">HK${{item.couponItem.initBalance}}</pre>

                <pre class="col-7 bookRemark1_couponCode" style="text-align:left;" *ngIf="item.couponItem.couponType=='Gift_Coupon'">{{'redeemedItem'|translate}}:</pre>
                <pre class="col-5 bookRemark1_couponCode_bold" style="text-align:right;" *ngIf="item.couponItem.couponType=='Gift_Coupon'">{{item.redemptionRemark}}</pre>

                <pre class="col-7 bookRemark1_couponCode" style="text-align:left;" >{{'redeemedChannel'|translate}}:</pre>
                <pre class="col-5 bookRemark1_couponCode_bold" style="text-align:right;" >{{item.salesChannelName?item.salesChannelName:'-'}}</pre>
            </div>

            <div class="row detailText" style="padding-top:10px;margin-left:-3px;margin-right:-5px;margin-bottom:10px;" *ngIf="page=='mgm'&&item&&item.source&&item.source=='SB'">
                <pre class="col-6 bookRemark1_couponCode" style="text-align:left;">{{'orderId|translate'}}:</pre>
                <pre class="col-6 bookRemark1_couponCode_bold" style="text-align:right;">{{item.orderId}}</pre>
                <pre class="col-6 bookRemark1_couponCode" style="text-align:left;">{{'redeemedDate'|translate}}:</pre>
                <pre class="col-6 bookRemark1_couponCode_bold" style="text-align:right;"><app-date [date]="item.redemptionDate?item.redemptionDate:item.actionDate" [dateFormat]="dateTimeFormat"></app-date></pre>
            </div>

            <div class="row detailText" style="padding-top:10px;margin-left:-3px;margin-right:-5px;margin-bottom:10px;" *ngIf="page!='mgm'&&item.actionType=='TFT'">
                
                <pre class="col-7 bookRemark1_couponCode" style="text-align:left;;" *ngIf="item.isTransferor">{{'transferTo'|translate}}:</pre>
                <pre class="col-5 bookRemark1_couponCode_bold" style="text-align:right;" *ngIf="item.isTransferor">{{item.transferredToMasked}}</pre>
    
                <pre class="col-7 bookRemark1_couponCode" style="text-align:left;;" *ngIf="!item.isTransferor">{{'transferFrom'|translate}}:</pre>
                <pre class="col-5 bookRemark1_couponCode_bold" style="text-align:right;" *ngIf="!item.isTransferor">{{item.transferredFromMasked}}</pre>
            </div>
        </div>

        <div class="col-12 row" style="padding-left:40px;">
            <p class="col-12" style="text-align: center;margin-top:10px;" *ngIf="redemptionHists&&redemptionHists.length>0&&totalNumber>nextOffset" (click)="clickMore()">{{'more'|translate}}▼</p><!--&&totalNumber>(nextOffset+defaultLimit)-->
        </div> 
          
        <!-- <div class="row detailText" style="padding-top:10px;margin-left:9px;margin-right:5px;margin-bottom:10px;" *ngIf="page=='mgm'&&item&&item.source&&item.source=='RUBY'">
            <pre class="col-6 bookRemark1_couponCode" style="text-align:left;">{{'referee'|translate}}:</pre>
            <pre class="col-6 bookRemark1_couponCode_bold" style="text-align:right;">Cxxx Txxx Mxxx</pre>
            <pre class="col-6 bookRemark1_couponCode" style="text-align:left;">{{'redeemedDate'|translate}}:</pre>
            <pre class="col-6 bookRemark1_couponCode_bold" style="text-align:right;">2021/04/15</pre>
            <pre class="col-6 bookRemark1_couponCode" style="text-align:left;">{{'redeemedChannel'|translate}}:</pre>
            <pre class="col-6 bookRemark1_couponCode_bold" style="text-align:right;">Shop XXX</pre>
        </div> -->
    </div>

    <div class="row" style="padding-top:30px;" style="padding:10px 5px 10px 10px;border-top:var(--splitColor) 1px solid;">
        <p class="col-10 col-md-11 col-lg-11 bookRemark2_blod" data-detailTC style="text-align: left;margin-left: 14px;margin-top:10px;">{{'remarks'|translate}}</p>
        <div class="popBotton" style="width:30px;height:30px;border-radius: 50%;margin-top:7px;" >
           <i *ngIf="showRemarks" class="fa fa-angle-up fa-angle-up_new" style="margin-left:7px;font-size: 25px;" data-toggle="collapse" data-target="#remarkDetail" (click)="clickCollapseRemarks()"></i>
           <i *ngIf="!showRemarks" class="fa fa-angle-down fa-angle-up_new" style="margin-left:7px;font-size: 25px;" data-toggle="collapse" data-target="#remarkDetail" (click)="clickCollapseRemarks()"></i>
        </div>
    </div>

    <div id="remarkDetail" class="collapse show" style="padding:2px;margin:2px;margin-right: 15px;">
        <div class="col-12 detailText bookRemark1_couponCode" data-detailTC style="padding-top: 10px; margin-left:6px; margin-right: 0px;padding-left:10px;padding-right:20px; margin-bottom: 10px;">
               <div class="col-12" style="margin:4px;padding-left:0px;padding-bottom: 3px;" [innerHTML]="
                 couponDetail?(lang == 'zh-HK'?(couponDetail.remarkCht?couponDetail.remarkCht:'不適用'):(couponDetail.remark?couponDetail.remark:'N/A')):''">
               </div>
        </div>
    </div>

    <div class="row" style="padding-top:30px;" style="padding:10px 5px 10px 10px;border-top:var(--splitColor) 1px solid;">
        <p class="col-10 col-md-11 col-lg-11 bookRemark2_blod" data-detailTC style="text-align: left;margin-left: 14px;margin-top:10px;">{{'terms&Conditions'|translate}}</p>
        <div class="popBotton" style="width:30px;height:30px;border-radius: 50%;margin-top:7px;" >
           <i *ngIf="showTermCondition" class="fa fa-angle-up fa-angle-up_new" style="margin-left:7px;font-size: 25px;" data-toggle="collapse" data-target="#termConditionDetail" (click)="clickCollapseTermCondition()"></i>
           <i *ngIf="!showTermCondition" class="fa fa-angle-down fa-angle-up_new" style="margin-left:7px;font-size: 25px;" data-toggle="collapse" data-target="#termConditionDetail" (click)="clickCollapseTermCondition()"></i>
        </div>
    </div>

    <div id="termConditionDetail" class="collapse show" style="padding:2px;margin:2px;margin-right: 15px;">
        <div class="col-12 detailText bookRemark1_couponCode" data-detailTC style="padding-top: 10px; margin-left:6px; margin-right: 0px;padding-left:10px;padding-right:20px; margin-bottom: 10px;">
               <div class="col-12" style="margin:4px;padding-left:0px;padding-bottom: 3px;" [innerHTML]="
               couponDetail&&couponDetail.couponItem?
               lang == 'zh-HK'?couponDetail.couponItem.tncCht:
               couponDetail.couponItem.tnc:''"></div>
        </div>
    </div>
</div>

<div id="{{page}}TransferForm" *ngIf="page!='microsite'">
    <div class="row">
       <p class="col-12 heading1_1" style="text-align: center;">{{'transfer'|translate}}</p>
       <p class="col-12 bookRemark1" style="text-align: center;color: red;" *ngIf="inputMobileValidateMsg">{{inputMobileValidateMsg|translate}}</p>
       <p class="col-12 bookRemark1" style="text-align: center;">{{(channel=='MyHKT'?'myhktLoginId':'mobileNum')|translate}}: </p>
       <input id="{{page}}MobileNum" autocomplete="off" type="search" class="form-control inputMobileNum col-12" style="margin-bottom:12px;" [(ngModel)]="inputMobileNum"/>
       <div class="col-4"></div>
           <button class="button col-4" style="margin-bottom:13px;" (click)="submitTransferForm()">{{'confirm'|translate}}</button>
       <div class="col-4"></div>
       <div class="col-4"></div>
            <button class="buttonCancel col-4" (click)="closeTransferForm()">{{'cancel'|translate}}</button>
       <div class="col-4"></div>
    </div> 
</div>

<div id="{{page}}ConfirmTransfer" *ngIf="page!='microsite'">
    <div class="row">
        <p class="col-12 heading1_1" style="text-align: center;">{{'transfer'|translate}}</p>
        <p class="col-12 heading5" style="text-align: center;">
             {{'transferAsk'|translate}} {{couponDetail?lang=='zh-HK'?couponDetail.couponItem.nameCht:couponDetail.couponItem.name:''}} {{'transferAskTo'|translate}} {{inputMobileNum}} ?
        </p>
        <div class="col-4"></div>
            <button class="button col-4" style="margin-bottom:13px;" (click)="submitConfirmTransfer()">{{'confirm'|translate}}</button>
        <div class="col-4"></div>
        <div class="col-4"></div>
            <button class="buttonCancel col-4" (click)="closeConfirmTransfer()">{{'cancel'|translate}}</button>
        <div class="col-4"></div>
    </div>
</div>

<div id="{{page}}TransferResult" *ngIf="page!='microsite'">
    <div class="row">
        <p class="col-12 heading1_1" style="text-align: center;">{{'transfer'|translate}}</p>
        <p class="col-12 heading5" style="text-align: center;">{{transferResult|translate}}{{transferErrMsg}}</p>
        <div class="col-4"></div>
        <button class="buttonCancel col-4" (click)="closeTransferResult()">{{'close'|translate}}</button>
   <div class="col-4"></div>
    </div>
</div>

<div id="{{page}}ToRedeem" *ngIf="page!='microsite'">
     <div class="row">
        <div class="col-12" style="padding-top:36px;padding-bottom:12px;">
            <div class="row">
                <img src="/assets/common/image/icons/qrcode_logo_{{couponDetail&&couponDetail.couponItem?couponDetail.couponItem.issueBrand:'csl'}}.png" width="40" height="40" 
                style="position:absolute;left:-webkit-calc(50%-20px);left:-moz-calc(50% - 20px); left:calc(50% - 20px);top:95px;" *ngIf="isToRedeemPopupDonedRedeem"/>

                <qrcode class = "m-auto couponQr"  
                [qrdata]="couponDetail&&isToRedeemPopupDonedRedeem&&couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='Q'?couponDetail.providerCoupon.couponCode:'init'" [size]=150 [level]="'L'" [width]=150 [margin]=1></qrcode>
                <p *ngIf="couponDetail&&isToRedeemPopupDonedRedeem&&couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='T'" class="col-12" style="text-align: center;">{{couponDetail.providerCoupon.couponCode}}</p>
                <a *ngIf="couponDetail&&isToRedeemPopupDonedRedeem&&couponDetail.providerCoupon&&couponDetail.providerCoupon.type=='L'" class="col-12" style="text-align: center;color: var(--primary);" href="{{couponDetail.couponCode}}">{{couponDetail.providerCoupon.url}}</a>
                <img src="/assets/common/image/icons/{{channel}}/qrBorder.png"  style="position: absolute;left:-webkit-calc(50%-76px);left:-moz-calc(50% - 76px); left:calc(50% - 76px);top:36px;" width="153" height="153"/>
                <img src="/assets/common/image/qrOpacity.png" *ngIf="!isToRedeemPopupDonedRedeem" style="position: absolute;left:-webkit-calc(50%-69px);left:-moz-calc(50% - 69px); left:calc(50% - 69px);top:42px;" width="139" height="141"/>
                <pre *ngIf="!isToRedeemPopupDonedRedeem" class="bookRemark1_bold_redemSlidNote" style="width:150px;position:absolute;left:-webkit-calc(50%-74px);left:-moz-calc(50% - 74px); left:calc(50% - 74px);top:40px;text-align: center;">{{(couponDetail&&couponDetail.providerCoupon&&couponDetail.providerCoupon.type!='Q'?'redemSlidNote2':'redemSlidNote')|translate}}</pre>
            </div>
            <div class="row" style="margin-top: 20px;">
                <div class="col-1"></div>
                <div class="col-10">
                      <app-drag [page]="page" [initMsg]="'redemSlidBtn'|translate" [okMsg]="'redemSuccess'|translate" (onCompleted)="completeToRedeem()"></app-drag>
                </div>
                <div class="col-1"></div>
            </div>
        </div>
     </div>
</div>

<div id="{{page}}Scan" *ngIf="page!='microsite'" style="width:100%;height:100%;padding:0px;">
    <div class="row">
        <div class="col-12">
            <app-myewallet-scanQrCode [couponName]="!couponDetail?'':lang=='zh-HK'?couponDetail.couponItem.nameCht:couponDetail.couponItem.name" (onReturn)="clickScanReturn()" (onScanSuccess)="onScaned($event)"></app-myewallet-scanQrCode>
        </div>
    </div>
</div>

<div id="{{page}}StockAvail" *ngIf="page!='microsite'" style="width:100%;height:100%;padding:0px;">
            <app-myewallet-stock-avail [couponDetail]="couponDetail"></app-myewallet-stock-avail>
</div>


<div *ngIf="page!='microsite'" id="{{page}}WebOpenCamera" >
    <div class="row"> 
        <!--<img class="close popClose" src="/assets/common/image/icons/{{channel}}/icon_close.png" (click)="closeWebOpenCamera()" width="30" height="30"/>-->
        <p *ngIf="channel!='MyHKT'" class="col-12 heading1" style="text-align: center;">{{'WebOpenCameraTitle'|translate}}</p>
        <p *ngIf="channel!='MyHKT'" class="col-12 heading4" style="text-align: center;">
             {{'WebOpenCameraContent'|translate}} {{couponDetail?lang=='zh-HK'?couponDetail.couponItem.nameCht:couponDetail.couponItem.name:''}} </p>
             <img *ngIf="channel=='MyHKT' && lang== 'zh-HK'" src="/assets/common/image/images/{{channel}}/webopencam_zh.png" style="max-width:100%"/>
             <img *ngIf="channel=='MyHKT' && lang!= 'zh-HK'" src="/assets/common/image/images/{{channel}}/webopencam_en.png" style="max-width:100%"/>                
        <!--<div class="col-4"></div>
            <button class="button col-4" (click)="closeWebOpenCamera()">{{'close'|translate}}</button>
        <div class="col-4"></div>-->
    </div>
</div>

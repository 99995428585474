import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stockLevel'
})
export class StockLevelPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    var lang = sessionStorage.getItem('lang');
    switch(value){
      case 'Low':
        if (lang=='en'){
          return 'Low Stock';
        }else if(lang=='zh-HK'){
          return '少量';
        }else{
          return 'Low Stock';
        }
      case 'Medium':
        if (lang=='en'){
          return 'In Stock';
        }else if(lang=='zh-HK'){
          return '尚有存貨';
        }else{
          return 'In Stock';
        }
      case 'High':
        if (lang=='en'){
          return 'In Stock';
        }else if(lang=='zh-HK'){
          return '尚有存貨';
        }else{
          return 'In Stock';
        }
      case 'No Stock':
        if (lang=='en'){
          return 'No Stock';
        }else if(lang=='zh-HK'){
          return '沒有存貨';
        }else{
          return 'No Stock';
        }
      case null:
        return '';
      default:
        return value;
    }
  }
}
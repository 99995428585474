<div *ngIf="(tab=='mgm'&& channel!='MyHKT') || (tab=='microsite' && !channel.includes('MyHKT'))">
 <img *ngIf="channel!='MyHKT'" src="/assets/common/image/nameCard/{{channel}}/mycard_bg.png" style="position: absolute; top: 10px; left: 20px;width:100%;height:275px;padding-right:35px;padding-bottom:34px;border-radius:9px;"/>
<div class="row nameCard" style="padding-top:10px;border-radius:9px 9px 9px 9px;padding-bottom:30px;">
    <div class="col-8" style="margin-right:-30px;padding-right:5px">
       <div class="row">
        <div class="col-12">
            <p  class="normalText" style="font-size:var(--bigSize);text-align:left;padding-left: 10px;">{{tab!='microsite'&&customerInfo?customerInfo.name!=null?customerInfo.name:'':''}}</p>
        </div>
       <div class="col-2" style="padding-right:5px;" *ngIf="channel!='clubsim'">
           <!--<i class="fa fa-user icon" style="padding-left:10px;"></i>-->
           <img class="img_namecard" src="/assets/common/image/icons/{{channel}}/icon_nickName.png" width="9" height="12" style="margin-left:14px;"/>
        </div>
        <div class="col-10" *ngIf="channel!='clubsim'">
            <!--demohardcode-->
          <span class="normalText" style="padding-left:12px;margin-left:-9px;" *ngIf="tab=='microsite'">{{customerInfo?((customerInfo.nickname!=null&&customerInfo.nickname!="")?customerInfo.nickname:'Customer'):'Customer'}}</span>
          <span class="normalText" style="padding-left:12px;margin-left:-9px;" *ngIf="tab!='microsite'">{{customerInfo?((customerInfo.nickname!=null&&customerInfo.nickname!="")?customerInfo.nickname:'Customer'):'Customer'}}</span>
          <!-- <span class="normalText" style="padding-left:12px;margin-left:-9px;" *ngIf="tab=='microsite'">Mr. WAN, BRIAN</span> -->
          <!-- <span class="normalText" style="padding-left:12px;margin-left:-9px;" *ngIf="tab!='microsite'">Mr. WAN, BRIAN</span> -->
       </div>
       <div class="col-2" style="padding-right:5px;">
           <!--<i class="fa fa-mobile icon" style="padding-left:11px;"></i>-->
           <img class="img_namecard" *ngIf="tab!='microsite'" src="/assets/common/image/icons/{{channel}}/icon_mobile.png"  style="margin-left:14px;"/>
        </div>
        <div class="col-10">
           <span *ngIf="tab!='microsite'" class="normalText" style="padding-left:12px;margin-left:-9px;">{{customerInfo?customerInfo.mrt!=null?customerInfo.mrt:'':''}}</span>
       </div>
       <div class="col-2" style="padding-right:5px;">
           <!--<i class="fa fa-ruble icon" style="padding-left:10px;"></i>-->
           <img class="img_namecard" *ngIf="tab!='microsite'" src="/assets/common/image/icons/{{channel}}/icon_club_point.png"  style="margin-left:14px;"/>
       </div>
       <div class="col-10">
           
           <span *ngIf="tab!='microsite'" class="normalText" style="padding-left:12px;margin-left:-9px;">{{customerInfo?customerInfo.clubPoint?customerInfo.clubPoint.pointBalance?customerInfo.clubPoint.pointBalance:'':'':''}}</span>
           <!-- <span *ngIf="tab!='microsite'" class="normalText" style="padding-left:0px;margin-left:-9px;">0</span> -->
       </div>
       </div>
    </div>
    <div class="col-4" *ngIf="channel!='MyHKT'">
           <img src="{{customerInfo?customerInfo.imageUrl!=null?customerInfo.imageUrl:'/assets/common/image/nameCard/'+channel+'/profile_pic_icon.png':'/assets/common/image/profile_pic_icon.png'}}" max-width="100%" height="100px" style="padding-top:0px;margin-top: 15px;border-radius: 8px;"/>
    </div>
    <div class="col-12">
        <span class="normalText" *ngIf="tab=='microsite'" style="padding-left:5px;">{{'refererCode'|translate}}: {{customerInfo?customerInfo.referrerCd:''}}</span><br/>
    </div>
   
        <div class="col-4" style="padding-top:30px; margin-left: 5px; display: flex;flex-wrap: nowrap;">
            <div class="nameCardQRCode" >
                <!--<i class="fa fa-qrcode" style="color: var(--white);margin-left:6px;margin-top:4px;font-size:22px;" (click)="clickQrCode()"></i>-->
                <img src="/assets/common/image/icons/{{channel}}/icon_qrcode.png" style="width: 32px; height: 32px;" (click)="clickQrCode()"/>
            </div>   
            <div class="nameCardShare"  *ngIf="tab=='mgm'">
                <!--<i class="fa fa-share-alt" style="color: var(--white);margin-left:6px;margin-top:4px;font-size:22px;" (click)="onShare()"></i>-->
                <img src="/assets/common/image/icons/{{channel}}/icon_share_btn.png" style="width: 32px; height: 32px;" (click)="onShare()"/>
            </div>   
        </div>
        <div class="col-3" ></div>
        <div class="col-4" style="padding-top:30px">
            <img src="{{appImg}}" class="nameCardAppImg"/>
        </div>
    
</div>
</div>

<!--
<div class="row nameCard" style="padding-top:30px;padding-bottom:10px;">
    <a href="#" class="normalText"><abbr style="border-bottom: white solid 1px;color:white">Share name card</abbr></a>
</div>
<div class="row nameCard" style="padding-bottom:10px;border-radius:0px 0px 9px 9px;">
    <div class="col-3 col-md-3 col-lg-4" style="padding-left:25px;">
            <qrcode class = "m-auto couponQr" 
            [qrdata]="customerInfo?customerInfo.mrt!=null?customerInfo.mrt:'':''" [size]=200 [level]="'M'" [width]=30></qrcode> 
    </div>
    <div class="col-5 col-md-1 col-lg-4"></div>
    <div class="col-2 col-md-3 col-lg-2">
        <img src="{{appImg}}" width="30px" height="30px" style="margin:0.5px;"/>
    </div>-->
    <!--                
    <div class="col-2 col-md-3 col-lg-2">
        <img src="/assets/common/image/1010.png" width="30px" height="30px" style="margin:0.5px;"/>
    </div>
    <div class="col-2 col-md-3 col-lg-2">
        <img src="/assets/common/image/CLUBSIM.png" width="30px" height="30px" style="margin:0.5px;"/>
    </div>
    -->
<!--</div>-->
<div class="row" style="margin-top:10px;margin-bottom:10px;">
    <div class="col-12" style="text-align:right;font-size:12px;" *ngIf="tab=='mgm'">
        <a class="normalText" data-history (click)="historyPopup('mgmRewardHistroyModalBtn')"><abbr>{{'mgmRewardHistory'|translate}}&nbsp;&nbsp;<i class="fa fa-chevron-right fa-chevron-right_new"></i></abbr></a>
    </div>  
    <div class="col-12" style="text-align:right;font-size:12px;" *ngIf="tab=='coupon'">
        <a class="normalText" data-history (click)="historyPopup('couponRedemptionHistroyModalBtn')"><abbr>{{'transactionRecords'|translate}}&nbsp;&nbsp;<i class="fa fa-chevron-right fa-chevron-right_new"></i></abbr></a>
    </div>    
    <div class="col-12" style="text-align:right;font-size:12px;" *ngIf="tab=='rewards'">
        <a class="normalText" data-history (click)="historyPopup('rewardsRedemptionHistroyModalBtn')"><abbr>{{'transactionRecords'|translate}}&nbsp;&nbsp;<i class="fa fa-chevron-right fa-chevron-right_new"></i></abbr></a>
    </div>       
</div>
<p id="NameCard_Qrcode" style="display:none;">{{customerInfo?customerInfo.mrt:''}}</p>
<p id="Microsite_NameCard_Qrcode" style="display:none;">{{customerInfo?customerInfo.referrerCd:''}}</p>
<button id="{{tab}}updateLogo" class="updateLogo" style="display: none;" (click)="updateLogo()">UpdateLog</button>


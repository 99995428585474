 <!-- nameCard start-->
 <div class="card text-white bg-dark mb-3">
  <div class="card-body">
    <div class="row ">
      <div class="col-7">
        <h6 class="card-title">{{nameCardInfo.nickname ==null?(nameCardInfo.channel=='1010'?'1010 Customer':'CSL Customer'):nameCardInfo.nickname}}</h6>
        
      </div>
      <div class="col-5">
        <img class="card-img" src="{{nameCardInfo.imageUrl==null?'../../../assets/image/default/profile_pic.png':nameCardInfo.imageUrl}}">
      </div>
      <div class="col-6">
        <p style="margin-bottom: 0px;font-size:5px;">{{'mgmCouponDetail.refererCode' | translate}}:{{nameCardInfo.referrerCd}}</p>
       <!-- <qr-code value="{{nameCardInfo.referrerCd}}" [size]="70"></qr-code>-->
       <qrcode class = "m-auto couponQr" 
       [qrdata]="nameCardInfo.referrerCd" [size]=70 [level]="'L'" [width]=70 [margin]=1></qrcode>
      </div>
      <div class="col-6 ">
        <br>
        <img class="img-fluid" src="../../../assets/image/logo/{{nameCardInfo.channel=='1010'?'1010':'csl'}}_5g.png">
      </div>
    </div>
  </div>
</div>

<!-- nameCard end-->
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
    selector: 'error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
    constructor(private route: ActivatedRoute) {
    }
    errorCode: string;
    errorMessage1: string;
    errorMessage2: string;
    ngOnInit() {
        // this.errorCode = "404";
        // this.errorMessage1 = "Forbidden";
        // this.errorMessage2 = "We're sorry, the page you requested was forbidden.";
        /** poc code */
        this.errorCode = this.route.snapshot.paramMap.get('errorCode');
        if (!this.errorCode || this.errorCode == null) this.errorCode = '400';

        switch (this.errorCode) {
            case '401':
              this.errorMessage1 = `Unauthorized`;
              this.errorMessage2 = `We're sorry, the page you requested requires authorization.`;
              break;
            case '403':
                this.errorMessage1 = `Forbidden`;
                this.errorMessage2 = `We're sorry, the page you requested is restricted.`;
                break;
            case '404':
                this.errorMessage1 = `Page Not Found`;
                this.errorMessage2 =  `We're sorry, the page you requested could not be found.`;
                break;
            default:
                this.errorMessage1 = 'Bad Request';
                this.errorMessage2 = `We're sorry, the page you requested encountered problem.`;
                break;
        }
    }
}

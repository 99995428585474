<div id="{{page}}_drag" class="drag">
    <div id="{{page}}_drag_bg" class="drag_bg" data-special></div>
    <div id="{{page}}_drag_text" style="font-size: 14px;" class="drag_text slidetounlock" onselectstart="return false;" unselectable="on">
        {{initMsg}}
    </div>
    <div id="{{page}}_handler" class="handler handler_bg" data-special></div>
</div>
<button id="{{page}}_dragInit" (click)="init()" style="display:none;">init</button>
<button id="{{page}}_dragRest" style="display:none;">reset</button>
<button id="{{page}}_dragInitCompleted" style="display:none;">initCompleted</button>
<button id="{{page}}_dragComplete" class="complete" (click)="complete()" style="display:none;">complete</button>
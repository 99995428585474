import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyEwalletDetailComponent } from './myewallet-detail/myewallet-detail.component';
import { MyEWalletFilterComponent } from './myewallet-filter/myewallet-filter.component';
import { MyEwalletCouponDetailComponent } from './myewallet-couponDetail/myewallet-couponDetail.component';
import { MyEwalletMgmComponent } from './myewallet-mgm/myewallet-mgm.component';
import { MyEwalletMgmRewardHistoryComponent } from './myewallet-mgmRewardHistory/myewallet-mgmRewardHistory.component';
import { MyEwalletRedemptionRecordComponent } from './myewallet-redemptionRecord/myewallet-redemptionRecord.component';
import { MyEwalletDateRangePickerComponent } from './myewallet-dateRangePicker/myewallet-dateRangePicker.component';
import { MyEwalleSearchComponent } from './myewallet-search/myewallet-search.component';
import { MyewalletStockAvailComponent } from './myewallet-stock-avail/myewallet-stock-avail.component';
import { MyEwalletScanQrcode } from './myewallet-scanqrcode/myewallet-scanqrcode';
import { DragComponent} from './drag-component/drag-component';
import { AuthGuard } from '../../router-auth/auth-guard';


const myEWalletRoutes: Routes = [
  { path: 'myewallet',  component: MyEwalletDetailComponent ,canActivate: [AuthGuard]   },
  { path: 'searchCoupon',component:MyEwalleSearchComponent,canActivate: [AuthGuard] },
  { path:'stock',component:MyewalletStockAvailComponent}
  // { path:'drag',component:DragComponent},
  // { path: 'dateRange',  component: MyEwalletDateRangePickerComponent},
  // { path:'redemptionHistory',component:MyEwalletRedemptionRecordComponent},
  // { path:'scan',component:MyEwalletScanQrcode},
  // { path: 'mgmRewardHistory',component:MyEwalletMgmRewardHistoryComponent},
  // { path: 'myewalletfilter',  component: MyEWalletFilterComponent,canActivate: [AuthGuard] },
  // { path: 'myewalletCouponDetail/:id', component:MyEwalletCouponDetailComponent,canActivate: [AuthGuard] }
];

@NgModule({
    imports: [
      RouterModule.forChild(myEWalletRoutes)
    ],
    exports: [
      RouterModule
    ]
  })
  export class MyEwalletRoutingModule { }
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError, of } from 'rxjs';
import { MicrositeService } from '../../../data/service/microsite.service';
import { CustomerInfoModel } from '../../../data/models/customerInfo.model';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { JwtUtil } from 'src/app/data/util/jwtUtils';
import { PopupMsgUtil } from 'src/app/data/util/popupMsgUtil';
import { ErrHandler } from 'src/app/data/util/errHandler';
import { ErrMsgUtil } from 'src/app/data/util/errMsgUtil';

@Component({
  selector: 'app-microsite-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrls: ['./coupon-list.component.scss']
})
export class CouponListComponent implements OnInit {

  nameCardInfo:CustomerInfoModel={};

  mgmCouponListModel: CouponInstanceModel[] = [];

  lang = localStorage.getItem("lang");

  nameCardQrCode:string; 

  selectCoupon:CouponInstanceModel;

  channel:string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private mgmServiceService: MicrositeService,
    private jwtUtil:JwtUtil,
    private errMsgUtil:ErrMsgUtil,
    private popupMsgUtil:PopupMsgUtil,
    // private commonServiceService: CommonServiceService,
    
  ) {
    
    console.debug("enter @constructor Coupon List page...");
  }

  ngOnInit() {
    console.debug("enter @ngOnInit Coupon List page...");
   // this.lang = this.activatedRoute.snapshot.paramMap.get('lang');
    this.channel=this.jwtUtil.getSubServiceAppType();
    var code = "";//{encrypted content} ex: {referrer_code}:{Channel}
    this.selectCoupon=null;
    this.activatedRoute.queryParams.subscribe(params => {
      code = params['code'];
      this.lang = params['lang'];
    });
    console.debug("params code=" + code);
    if(this.lang){
      if(this.lang=='en'||this.lang=='zh-HK'){
        sessionStorage.setItem('lang',this.lang);
        this.translate.use(this.lang);
      }
    }
    // if(code== null||code==''){  //throw 400
    //   this.commonServiceService.showErrorPage("400");
    // }

    //get customer info
    // this.mgmServiceService.getCustomerInfo(code,"LIST").subscribe(
      this.mgmServiceService.getCustomerInfo(code).subscribe(
      (res) => {
        console.debug(">>>>mgmServiceService.getCustomerInfo return>>>");
        console.debug(res);
        this.nameCardInfo = res;
        this.jwtUtil.setServiceAppType(this.nameCardInfo.channel);
        this.channel=this.jwtUtil.getSubServiceAppType();
        // var e = document.createEvent("MouseEvents");
        // e.initEvent("onclick", true, true);　
        // var ulbs=document.getElementsByClassName('updateLogo');
        // console.log("btns:"+ulbs.length);
        // for(var i=0;i<ulbs.length;i++){
        //   console.log("click:"+i);
        //   ulbs[i].dispatchEvent(e);
        // }
        var ulb=document.getElementById('micrositeupdateLogo');
        ulb.click();

      }, (err) => {
        console.debug(">>>>mgmServiceService.getCustomerInfo error>>>");
        console.debug(err);
      });

    //get coupon list info 
    // this.mgmServiceService.getMgmCouponList(code).subscribe(
      this.popupMsgUtil.loading();
      this.mgmServiceService.getCouponList(code).subscribe(
      (res) => {
        console.debug(">>>>mgmServiceService.getMgmCouponList return>>>");
        console.debug(res);
        console.log(res);
        this.mgmCouponListModel=res.data;
        this.popupMsgUtil.endLoading();
      }, (err) => {
        console.debug(">>>>mgmServiceService.getMgmCouponList error>>>");
        console.debug(err);
        this.errMsgUtil.popupMsg(err.layer,err.status,err.errors.message,err.errors.detail,'');
        this.popupMsgUtil.endLoading();
      });

  }


  gotoCouponDetail(cInfo:CustomerInfoModel,dInfo:CouponInstanceModel){
    console.debug("call @gotoCouponDetail Coupon List page...");
    //save to session 
    sessionStorage.setItem("CustomerInfoModel",JSON.stringify(cInfo));
    sessionStorage.setItem("MgmCouponListModel",JSON.stringify(dInfo));

    this.router.navigate(['/mgm/coupon']);
   
  }


  openNameCardQrCode(){
    this.nameCardQrCode=document.getElementById('Microsite_NameCard_Qrcode').innerHTML;
    var btn=document.getElementById('myNameCardQrModalBtn');
    btn.click();
  }

  openShare(){
  }

  openDetail(item:CouponInstanceModel){
    this.selectCoupon=item;
    var dpp=document.getElementById('openCouponDetailBtn');
    dpp.click();
    this.mgmServiceService.putRefereeCouponViews(this.selectCoupon.referrerCouponCode).subscribe(
      (res)=> {
        console.log("referrerCouponCode + 1");
        
      }, (err) =>{
        console.log("referrerCouponCode is error");
        
      }
    );
    
    var mv=document.getElementById('myCouponDetailModal'); 
    mv.setAttribute('style','display: block; padding-right:0px;padding-left: 0px;position:flex;top:0%;height:100%;overflow: hidden;overflow-y:scroll;margin-right:15px;');
    // var initCouponCode=document.getElementById('micrositecouponDetailInitCouponCode');
    // initCouponCode.innerHTML=this.selectCoupon.couponCode;
    // var init=document.getElementById('micrositecouponDetailInit'); 
    // init.click();

    document.getElementById('initMicrositeDetail').click();
  }

}

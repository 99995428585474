<div class="container-fluid" style="padding-left:0px;padding-right:0px;">
    <div class="row" (click)="onOpenDetail()">
       <div class="col-3">
            <img src="{{
                cp.couponItem.imageUrl=='tes112501'||cp.couponItem.imageUrl=='test'
                ?'/assets/common/image/b.jpg'
                :lang == 'zh-HK'?cp.couponItem.imageUrlCht
                :cp.couponItem.imageUrl
            }}" 
             width="100%" height="80px" style="margin-left: 15px;margin-top: 8px;"/>
       </div>
       <div class="col-9">
          <div class="row">
                <div class="col-8">
                   <p class="heading3" style="text-align: left;padding-left: 18px;margin-bottom: 0px;margin-top: 6px;">
                       COUPONS
                    </p>
               </div> 
                <div class="col-2">           
                    <img src="/assets/common/image/brands/brand/{{cp.couponItem.issueBrand}}.png" 
                    width="36px" height="22px"  style="margin-top: 5px;"/>
                </div>  
                <div class="col-12">
                    <p class="heading5" style="text-align:left;padding-top:12px;padding-left:18px;margin-right:22px;">
                        {{cp&&cp.couponItem?
                             lang == 'zh-HK'?cp.couponItem.nameCht:
                             cp.couponItem.name:''}}
                     </p>
    
                     <p class="bookRemark1" style="text-align:left;padding-left:18px;margin-right:22px;">
                        {{cp&&cp.couponItem?
                            lang == 'zh-HK'?cp.couponItem.descriptionCht:
                            cp.couponItem.description:''}}
                    </p>
                    <p class="bookRemark1_bold" style="text-align:left;padding-left:18px;margin-right:22px;">
                        <!-- {{(cp?cp.banlanceShowLabel:'')|translate}}{{cp&&cp.banlanceShowLabel?':':''}}{{cp?cp.banlanceShowValue:''}} -->
                        <!-- {{(cp && cp.couponItem?(((cp.couponItem.amountDisplay || cp.couponItem.quotaDisplay) && cp.banlanceShowLabel)?cp.banlanceShowLabel+':':''):'')|translate}}{{(cp?(((cp.couponItem.amountDisplay || cp.couponItem.quotaDisplay) && cp.banlanceShowLabel)?cp.banlanceShowValue:''):'')}} -->
                        {{(cp&&((cp.couponItem.amountDisplay && cp.couponItem.couponType=='Cash_Discount') || (cp.couponItem.quotaDisplay && cp.couponItem.couponType!='Cash_Discount'))?cp.banlanceShowLabel:'')|translate}}
                        {{cp&&cp.banlanceShowLabel&&((cp.couponItem.amountDisplay && cp.couponItem.couponType=='Cash_Discount') || (cp.couponItem.quotaDisplay && cp.couponItem.couponType!='Cash_Discount'))?':':''}}
                        {{cp&&((cp.couponItem.amountDisplay && cp.couponItem.couponType=='Cash_Discount') || (cp.couponItem.quotaDisplay && cp.couponItem.couponType!='Cash_Discount'))?cp.banlanceShowValue:''}}
                    </p>
                </div>
                    <div class="col-12" style="padding-bottom:16px;padding-left:9px;padding-right: 22px;">
                        <pre class="bookRemark1_couponCode couponCardDateRange" style="text-align: left;margin-left: 10px;padding-left: 8px;padding-right: 8px;padding-top: 2px;padding-bottom: 2px;">{{'validFrom'|translate}}&nbsp;<app-date [date]="cp.validFrom"></app-date>&nbsp;{{'dateMiddleText'|translate}}&nbsp;<app-date [date]="cp.validTo"></app-date></pre>
                    </div>
         </div>
       </div> 
  </div>
</div>

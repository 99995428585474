<!--<div class="container-fluid">
    <div class="row">
          <i class="col-12 fa fa-close" style="font-size:30px;text-align:right;color:orange;position:fixed;right:20px;top:10px;" (click)="closeFilter()"></i>
    </div>
</div>-->
<div class="container">
    <div class="row">
          <p class="col-12 heading1" style="margin-top:50px;padding-bottom:30px;">{{'askLookFor'|translate}}</p>
    </div>
    <!--
    <div class="row" style="border-bottom: white solid 1px;margin-left:10px;margin-right:10px;">
        <div class="input-group-prepend col-2 col-md-1 col-lg-1">
            <i class="fa fa-search icon" style="font-size: 20px;padding-top:8px;"></i>
      </div>
      <input type="search" class="form-control col-10 col-md-11 col-lg-11" id="searchCoupon_{{theme}}"  placeholder="{{'searchForCoupon'|translate}}" [(ngModel)]="showFilterCouponName">
    </div>
    
    <div class="row">
        <p class="col-12 heading3" style="text-align:left;padding-top:30px;padding-bottom:5px;">{{'couponType'|translate}}</p>
     </div>-->
     <div class="input-group searchGroup">
        <div class="input-group-prepend">
              <i class="col-2 fa fa-search icon" style="font-size:20px;padding-top:8px;"></i>
        </div>
        <input type="search" class="form-control searchCoupon" style="margin-right:20px;" 
        placeholder="{{'searchForCoupon'|translate}}" [(ngModel)]="showFilterCouponName">
    </div>
</div>



<!--
<div class="container" style="margin-bottom:30px;"> 
    <button type="button" id="ctAll" class="col-3 col-md-2 col-lg-1 btn ctBtn filterBtn buttonNoFill " (click)="clickCouponType('All')">{{'all'|translate}}</button>
    <button type="button" id="ctCASH" class="col-5 col-md-3 col-lg-2 btn ctBtn filterBtn buttonNoFill " (click)="clickCouponType('CASH')">{{'cash'|translate}}</button>
    <button type="button" id="ctFREE_SERVICE" class="col-5 col-md-3 col-lg-2 btn ctBtn filterBtn buttonNoFill " (click)="clickCouponType('FREE_SERVICE')">{{'freeService'|translate}}</button>
    <button type="button" id="ctGIFT" class="col-7 col-md-4 col-lg-2 btn ctBtn filterBtn buttonNoFill " (click)="clickCouponType('GIFT')">{{'gift'|translate}}</button>
    <button type="button" id="ctREDEMPTION" class="col-5 col-md-3 col-lg-2 btn ctBtn filterBtn buttonNoFill " (click)="clickCouponType('REDEMPTION')">{{'redemption'|translate}}</button>
   
</div>-->

<div class="container">
    <div class="row">
        <p class="col-12 heading3" style="text-align:left;padding-top:30px;padding-bottom:5px;">{{'couponStatus'|translate}}</p>
     </div>
</div>

<div class="container" style="margin-bottom:30px;">
    <button type="button" id="csAll" class="col-3 col-md-2 col-lg-1 btn csBtn filterBtn buttonNoFill " style="font-size:12px;"  (click)="clickCouponStatus('All')">{{'all'|translate}}</button>
    <button type="button" id="csPENDING" class="col-4 col-md-3 col-lg-2 btn csBtn filterBtn buttonNoFill " style="font-size:12px;" (click)="clickCouponStatus('PENDING')">{{'pending'|translate}}</button>
    <button type="button" id="csREDEEMABLE"class="col-5 col-md-3 col-lg-2 btn csBtn filterBtn buttonNoFill " style="font-size:12px;" (click)="clickCouponStatus('REDEEMABLE')">{{'redeemable'|translate}}</button>
    <!--<button type="button" id="csTOREDEEM"class="col-5 col-md-3 col-lg-2 btn csBtn filterBtn buttonNoFill " style="font-size:12px;" (click)="clickCouponStatus('TOREDEEM')">{{'toRedeem'|translate}}</button>-->
    <button type="button" id="csREDEEMED" class="col-4 col-md-3 col-lg-2 btn csBtn filterBtn buttonNoFill " style="font-size:12px;" (click)="clickCouponStatus('REDEEMED')">{{'redeemed'|translate}}</button>
    <button type="button" id="csEXPIRED" class="col-3 col-md-2 col-lg-2 btn csBtn filterBtn buttonNoFill " style="font-size:12px;" (click)="clickCouponStatus('EXPIRED')">{{'expired'|translate}}</button>
    <!--<button type="button" id="csEXCEEDQUOTA" class="col-6 col-md-3 col-lg-2 btn csBtn filterBtn buttonNoFill " style="font-size:12px;" (click)="clickCouponStatus('EXCEEDQUOTA')">{{'exceedQuota'|translate}}</button>-->
</div>

<div class="container">
    <div class="row">
        <p class="col-12 heading3" style="text-align:left;padding-top:30px;padding-bottom:5px;">{{'redemptionChannels'|translate}}</p>
     </div>
</div>

<div class="container" style="margin-bottom:30px;">
    <button type="button" id="rcAll" class="col-3 col-md-2 col-lg-1 btn rcBtn filterBtn buttonNoFill " style="font-size:12px;" (click)="clickRedemptionChannels('All')">{{'all'|translate}}</button>
    <button type="button" id="rcRetail" class="col-3 col-md-2 col-lg-1 btn rcBtn filterBtn buttonNoFill " style="font-size:12px;" (click)="clickRedemptionChannels('Retail')">{{'retail'|translate}}</button>
    <button type="button" id="rcCallCentre"class="col-5 col-md-3 col-lg-2 btn rcBtn filterBtn buttonNoFill " style="font-size:12px;" (click)="clickRedemptionChannels('CallCentre')">{{'callCentre'|translate}}</button>
    <button type="button" id="rcOnline" class="col-3 col-md-2 col-lg-2 btn rcBtn filterBtn buttonNoFill " style="font-size:12px;" (click)="clickRedemptionChannels('Online')">{{'online'|translate}}</button>
    <button type="button" id="rcEvent" class="col-3 col-md-2 col-lg-2 btn rcBtn filterBtn buttonNoFill " style="font-size:12px;" (click)="clickRedemptionChannels('Event')">{{'event'|translate}}</button>
    <button type="button" id="rcOtherMerchants" class="col-6 col-md-4 col-lg-3 btn rcBtn filterBtn buttonNoFill " style="font-size:12px;" (click)="clickRedemptionChannels('OtherMerchants')">{{'otherMerchants'|translate}}</button>
</div>


<div class="container">
    <div class="row">
        <button type="button" class="col btn button" style="margin-left:12px;margin-right:12px;" (click)="showResult()">{{'showResult'|translate}}</button>
     </div>
     <div class="row">
         <u class="col bookRemark2" style="text-align:center;font-size:16px;margin-top:20px;" (click)="clickClearSelect()">{{'clear'|translate}}</u>
     </div>
     <button id="filterInit" (click)="initFilter(false)" style="display:none;"></button>
</div>


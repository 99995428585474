<div class="actionBar secondly_header">
      <div  class="container-fluid secondly_header input-group searchGroup"  style="margin-top:15px;{{env.threePartRelease==1?'display:none;':tab?'display:none;':''}}" >         
            <input id="searchCoupon" type="search" class="form-control searchCoupon" 
            placeholder="{{'searchForCoupon'|translate}}" [(ngModel)]="filterCouponName" ng-reflect-model="" autocomplete="off" style="margin-right:2px;"/>
            <div class="input-group-prepend">
                  <img src="/assets/common/image/icons/{{channel}}/icon_search.png" width="20" height="20" style="margin-top:8px;" (click)="clickSearch()"/>
            </div>
      </div>

      <div class="container-fluid secondly_header" style="{{env.threePartRelease==1?'display:none;':(channel=='MyHKT'?'':'display:none;')}}">
            <ul class="nav nav-tabs row" role="tablist">
                  <li class="nav-item col-4 ">
                    <a id="coupons-tab" class="nav-link active" href="#coupons" data-toggle="tab" 
                    role="tab" aria-controls="coupons" aria-selected="true" (click)="changeMenu('myewallet-couponList')">
                    {{'coupons'|translate}}</a> 
                  </li>
                  <li class="nav-item col-4 ">
                  <a id="rewards-tab" class="nav-link" href="#rewards" data-toggle="tab"
                  role="tab" aria-controls="rewards" aria-selected="false" (click)="changeMenu('myewallet-rewards')"
                  > {{'rewards'|translate}}</a>
                  </li>
                  <li class="nav-item col-4 ">
                  <a id="mgm-tab" class="nav-link" href="#mgm" data-toggle="tab"
                  role="tab" aria-controls="mgm" aria-selected="false" (click)="changeMenu('myewallet-mgm')"
                  >{{'mgm'|translate}}</a>
                  </li>
            </ul>
      </div>
</div>

<!-- <div  class="secondly_header" style="overflow:auto;z-index:999;width:100%;top:107px;height:3px;{{env.threePartRelease==1?'position:absolute;display:none;':tab=='myewallet-couponList'?'display:none;':'position:fixed;'}}"></div> -->

<div class="tab-content" id="">
   <div class="tab-pane secondly_header fade show active" id="coupons" role="tabpanel" aria-labelledby="coupons-tab">
       <app-myewallet-couponList (qrCode)="openNameCardQrCode()" (share)="openShare()" (popDetail)="openCouponDetail($event)" [activeTab]="activeTab"></app-myewallet-couponList>
   </div>
   <!-- <div  style="{{env.threePartRelease==1?'display:none;':''}}" class="tab-pane secondly_header fade" id="rewards" role="tabpanel" aria-labelledby="rewards-tab" >
      <app-myewallet-rewards (qrCode)="openNameCardQrCode()" (share)="openShare()" (popDetail)="openCouponDetail($event)"></app-myewallet-rewards>
   </div>
   <div style="{{env.threePartRelease==1?'display:none;':''}}" class="tab-pane secondly_header fade" id="mgm" role="tabpanel" aria-labelledby="mgm-tab">
      <app-myewallet-mgm (qrCode)="openNameCardQrCode()" (share)="openShare()" (popDetail)="openCouponDetail($event)"></app-myewallet-mgm>
   </div> -->
   <div  class="tab-pane secondly_header fade" id="rewards" role="tabpanel" aria-labelledby="rewards-tab" >
      <app-myewallet-rewards (qrCode)="openNameCardQrCode()" (share)="openShare()" (popDetail)="openCouponDetail($event)" [activeTab]="activeTab"></app-myewallet-rewards>
   </div>
   <div  class="tab-pane secondly_header fade" id="mgm" role="tabpanel" aria-labelledby="mgm-tab">
      <app-myewallet-mgm (qrCode)="openNameCardQrCode()" (share)="openShare()" (popDetail)="openCouponDetail($event)" [activeTab]="activeTab"></app-myewallet-mgm>
   </div>
</div>

<!--1  name card popup-->
<button id="myNameCardQrModalBtn" style="display:none;" data-toggle="modal" data-target="#myNameCardQrModal"></button>
<div class="modal fade" id="myNameCardQrModal" style="position: absolute;top:30%;">
    <div class="modal-dialog">
          <div class="modal-content" >
                <div class="modal-body">
                      <!--<i id="nameCardQrClose" class="close popClose" data-dismiss="modal" >×</i>-->
                      <img class="close popClose" data-dismiss="modal" src="/assets/common/image/icons/icon_close.png" width="30" height="30"/>
                      <div class="row">
                        <qrcode class = "m-auto couponQr" 
                        [qrdata]="nameCardQrCode?nameCardQrCode:'nameCard'" [size]=200 [level]="'M'" [width]=200></qrcode>  
                      </div>
                </div>
          </div>
    </div>
</div>

<!--2 coupon detail popup -->
<button id="openCouponDetailBtn" style="display:none;" data-toggle="modal" data-target="#myCouponDetailModal"></button>
<div class="modal fade walletCard" id="myCouponDetailModal" style="padding-right:0px;padding-left:0px;">
      <div class="modal-dialog modal-lg walletCard" style="max-width:100%;margin:0px;">
            <div class="modal-content walletCard" >
                  <div class="modal-body walletCard" style="padding:0px;">
                        <!--<i id="couponDetailClose" class="close popClose" data-dismiss="modal" >×</i>-->
                        <img id='closeCouponDetailBtn' class="close popClose" data-dismiss="modal" src="/assets/common/image/icons/{{channel}}/icon_close.png" width="30" height="30"/>
                        <app-myewallet-couponDetail [couponDetail]="selectCoupon?selectCoupon:selectSbCoupon" [page]="openDetailPage"></app-myewallet-couponDetail>
                  </div>
            </div>
      </div>
</div>

<!--3 search popup-->
<a id="redirectSearchCoupon" href="/searchCoupon" style="display: none;">aaa</a>
<!--
<button id="openSearchBtn" style="display:none;" data-toggle="modal" data-target="#mySearchModal"></button>
<div class="modal fade secondly_header" id="mySearchModal" style="padding-right:0px;padding-left:0px;">
      <div class="modal-dialog modal-lg secondly_header" style="max-width:100%;margin:0px;">
            <div class="modal-content secondly_header" >
                  <div class="modal-body secondly_header" style="padding:0px;">
                        <img id="mySearchModalClose" class="close popClose" data-dismiss="modal" src="/assets/common/image/icons/{{channel}}/icon_close.png" width="30" height="30"/>
                        <app-myewallet-search (popDetail)="openCouponDetail($event)"></app-myewallet-search>
                  </div>
            </div>
      </div>
</div>
-->

<!--4 default share popup-->
<button id="shareModalBtn" style="display: none;" data-toggle="modal" data-target="#shareModal"></button>
<div class="modal fade walletCard" id="shareModal" style="padding-right:0px;position:flex;top:60%;height:40%;overflow: hidden;overflow-y:scroll;">
    <div class="walletCard modal-dialog modal-lg " style="max-width:100%;margin:0px;">
          <div class="walletCard modal-content" >
                <div class="walletCard modal-body">
                      <!--<i id="nameCardQrClose" class="close popClose" data-dismiss="modal" >×</i>-->
                      <img id="shareClose" class="close popClose" data-dismiss="modal" src="/assets/common/image/icons/icon_close.png" width="30" height="30"/>
                      <div class="row">
                        <div class="col-2 col-md-1 col-lg-1">
                              <div style="height:50px;width:50px;background-color:var(--primary);">

                              </div>
                        </div>
                        <div class="col-10 col-md-11 col-lg-11">
                           <p class="heading5" style="margin-bottom:0px">{{'sharePop_mgmReferral'|translate}}</p>
                           <abbr class="bookRemark1" style="padding-right:10px;">{{'share'|translate}}</abbr>
                           <a style="color:var(--primary)">{{'option'|translate}}&nbsp;&gt;</a>
                        </div>
                     </div>
                  <div class="row col-12 col-md-6 col-lg-4" style="margin-top:10px;background-color:var(--gray2);border-radius:12px;padding-top:10px;padding-bottom:10px;margin-left:5px; margin-right: 5px;">
                       <div class="col-10">
                             <pre style="padding-left:10px;" id="shareModalContent">url</pre>
                       </div>
                       <div class="col-2">
                             <i class="fa fa-file icon" (click)="clickCopyShare()"></i>
                       </div>
                  </div>

                </div>
          </div>
    </div>
</div>



<button id="mgmRewardHistroyDetail{{data.logId}}InitBtn" (click)="initDetail()" style="display:none;"></button>
<div *ngIf="data.source=='RUBY'">
    <p class="col-12 bookRemark1" style="padding-bottom:10px;padding-left:8px;border-bottom:var(--splitColor) 1px solid;">{{'referees'|translate}}:</p>
    <div *ngFor="let rh  of rewardHists" >
        <div class="col-12 row" style="padding-bottom:30px;margin-left:0px;">
            <pre class="col-6 bookRemark1" style="text-align:left;padding-left:0px;">{{'referee'|translate}}:</pre>
            <pre class="col-6 bookRemark1_bold" style="text-align:right;font-weight:bold;padding-left:16px;">-</pre>
            <pre class="col-6 bookRemark1" style="text-align:left;padding-left:0px">{{'redeemedDate'|translate}}:</pre>
            <pre class="col-6 bookRemark1_bold" style="text-align:right;font-weight:bold;padding-left:16px;"><app-date [date]="rh.redemptionDate?rh.redemptionDate:rh.actionDate"></app-date></pre>
            <pre class="col-6 bookRemark1" style="text-align:left;padding-left:0px;">{{'redeemedChannel'|translate}}:</pre>
            <pre class="col-6 bookRemark1_bold" style="text-align:right;font-weight:bold;padding-left:16px;">{{rh.salesChannelName?rh.salesChannelName:"-"}}</pre>
            
        </div>
        <div class="col-12 row" style="padding-left:40px;">
            <p class="col-12" style="text-align: center;margin-top:10px;" *ngIf="rewardHists&&rewardHists.length>0&&totalNumber>nextOffset" (click)="clickMore()">{{'more'|translate}}▼</p><!--&&totalNumber>(nextOffset+defaultLimit)-->
        </div> 
    </div>
</div>


<div *ngIf="data.source=='SB'">
    <div *ngFor="let rh  of rewardHists" >
        <p class="col-12 bookRemark1" style="padding-bottom:10px;padding-left:8px;border-bottom:var(--splitColor) 1px solid;"></p>    
        <div class="col-12 row" style="padding-bottom:30px;margin-left:0px;">
            <pre class="col-6 bookRemark1" style="text-align:left;padding-left:0px">{{'orderId'|translate}}:</pre>
            <pre class="col-6 bookRemark1_bold" style="text-align:right;font-weight:bold;padding-left:16px;">{{rh.orderId}}</pre>
            <pre class="col-6 bookRemark1" style="text-align:left;padding-left:0px">{{'redeemedDate'|translate}}:</pre>
            <pre class="col-6 bookRemark1_bold" style="text-align:right;font-weight:bold;padding-left:16px;"><app-date [date]="rh.redemptionDate?rh.redemptionDate:rh.actionDate"></app-date></pre>
        </div>
        <div class="col-12 row" style="padding-left:40px;">
            <p class="col-12" style="text-align: center;margin-top:10px;" *ngIf="rewardHists&&rewardHists.length>0&&totalNumber>nextOffset" (click)="clickMore()">{{'more'|translate}}▼</p><!--&&totalNumber>(nextOffset+defaultLimit)-->
        </div> 
    </div>
</div>


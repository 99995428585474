import { Component, OnInit,Input } from '@angular/core';

import { CustomerInfoModel } from '../../../data/models/customerInfo.model';
@Component({
  selector: 'app-microsite-name-card',
  templateUrl: './name-card.component.html',
  styleUrls: ['./name-card.component.scss']
})
export class NameCardComponent implements OnInit {

  @Input() nameCardInfo:CustomerInfoModel={};

  constructor() { 
    console.debug("enter @constructor() Name Card page...");
  }

  ngOnInit() {
    console.debug("enter @ngOnInit() Name Card page ...");
    //console.debug(this.nameCardInfo);
  }

}

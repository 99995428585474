<button id="initMicrositeDetail" style="display: none;" onclick="window.scroll(0,0)">init</button>
<div class="container-fluid walletCard">
  <div class="row">
    <div *ngIf="mode!='popup'" class="col-12 col-sm-12 col-md-6 col-lg-3" style="padding-top:10px;padding-right:30px;padding-left:15px;margin-top: 10px;">
      <!-- nameCard start--> 
      <!--<app-microsite-name-card [nameCardInfo]="nameCardInfo"></app-microsite-name-card>-->
      <app-myewallet-nameCard (qrCode)="openNameCardQrCode()" (share)="openShare()" [tab]="'microsite'" [customerInfo]="nameCardInfo"></app-myewallet-nameCard>
    </div>
    <!-- nameCard end-->
    <div class="col-12 col-sm-12 {{mode=='popup'?'col-md-12 col-lg-12':'col-md-6 col-lg-9'}}">
      <!-- coupon detail start-->
      <!--
      <div class="card">
        <div class="col-12">
          <img class="card-img-top bg-dark " src="{{item&&item.couponItem?lang=='zh-HK'?item.couponItem.imageUrlCht:item.couponItem.imageUrl:''}}">
        </div>
        <br>
        <div class="card"></div>

        <div class="col-12">
          <br>
          <h3><b>{{item&&item.couponItem?lang=='zh-HK'?item.couponItem.nameCht:item.couponItem.name:''}}</b></h3>
        </div>
        <div class="col-12">
          <h5><b>{{item&&item.couponItem?lang=='zh-HK'?item.couponItem.descriptionCht:item.couponItem.description:''}}</b></h5>
        </div>-->
        <!-- <div class="col-12">
          <h5><b>{{'mgmCouponDetail.referralCountText' | translate}}  2/10</b></h5>
        </div> -->
        <!--<div class="col-12">
          <h5 ><b class="bg-light offset-1">{{item.validFrom | date: ('common.dateFormat'|translate) }} {{'common.dateMiddleText' | translate}} {{item.validTo | date: ('common.dateFormat'|translate)}}</b></h5>
        </div>
        <div class="col-12">
          <h5 ><b>{{'mgmCouponDetail.redemptionChannelText' | translate}}{{item&&item.couponItem?item.couponItem.appliedBrand:''}}</b></h5>
        </div>
        <div class="col-12 text-center">
          <figure class="figure">
            <figcaption class="figure-caption">{{item.couponCode}}</figcaption>-->
            <!--<qr-code class="figure-img img-fluid" value="{{item.couponCode}}" [size]="200"></qr-code>-->
            <!--<qrcode class = "m-auto couponQr" 
            [qrdata]="item.couponCode" [size]=200 [level]="'L'" [width]=150 [margin]=1></qrcode>

          </figure>
        </div>
        <div class="col-12">
          <details>
            <summary ><b>{{'mgmCouponDetail.tcText' | translate}}</b></summary>
            <div [innerHTML]="item&&item.couponItem?lang=='zh-HK'?item.couponItem.tncCht:item.couponItem.tnc:''"></div>
          </details>
        </div>
        <br>
      </div>-->
      <!-- coupon detail end-->

      <app-myewallet-couponDetail [couponDetail]='item' [page]="'microsite'"></app-myewallet-couponDetail>

    </div>
  </div>
</div>


<button id="myDetailNameCardQrModalBtn" style="display:none;" data-toggle="modal" data-target="#myDetailNameCardQrModal"></button>
  <div class="modal fade" id="myDetailNameCardQrModal" style="position: absolute;top:30%;">
      <div class="modal-dialog">
            <div class="modal-content" >
                  <div class="modal-body" id="couponDetailModalBody">
                        <!--<i id="nameCardQrClose" class="close popClose" data-dismiss="modal" >×</i>-->
                        <img id="detailNameCardQrClose" class="close popClose" data-dismiss="modal" src="/assets/common/image/icons/icon_close.png" width="30" height="30"/>
                        <div class="row">
                          <qrcode class = "m-auto couponQr" 
                          [qrdata]="nameCardQrCode?nameCardQrCode:'nameCard'" [size]=200 [level]="'M'" [width]=200></qrcode>  
                        </div>
                  </div>
            </div>
      </div>
  </div>
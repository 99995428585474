<button id="myewallet{{page}}-redemptionRecords" (click)="initDetail()" style="display:none;"></button>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
                <p class="redemptionRecords" data-special>{{(page=='coupon'?'couponRedemptionRecords':'rewardsRedemptionRecords')|translate}}</p>
        </div>
    </div>
</div>
<!-- <div class="container-fluid secondly_header input-group searchGroup" style="margin-top:3px;margin-left:-9px;width:105%;margin-right:-10px;{{env.threePartRelease==1?'display:none;':''}}">
    <input id="{{page}}searchCoupon" type="search" class="form-control searchCoupon" 
    placeholder="{{'searchForRedemptionRecords'|translate}}" (keydown.enter)="clickSerchBar()" [(ngModel)]="filterRedemptionName"/>
    <div class="input-group-prepend">
          <img src="/assets/common/image/icons/{{channel}}/icon_search.png" width="20" height="20" style="margin-top:8px;" (click)="clickSerchBar()"/>
    </div>
</div> -->

<div class="container-fluid">
<div id="{{page}}redemptionFilterDiv" class="horizontalScrollDiv" style="padding-top: 30px; padding-bottom: 5px; padding-left: 0px; padding-right: 0px;margin-left:-23px; margin-right: -27px;">
    <button type="button" id="{{page}}rfDateRange" class="col-5  btn csBtn csbuttonNoFill redemptionFilterBtn" (click)="clickDateRange('DateRange')">{{!dateRangePickerShow||dateRangePickerShow==''?pickDateRangeStr:dateRangePickerShow}}</button><!-- 5 Pick Date Range-->
    <button type="button" id="{{page}}rfAll" class="col-3 btn csBtn csbuttonNoFill redemptionFilterBtn" (click)="clickDateRange('All')">{{'all'|translate}}</button>
    <button type="button" id="{{page}}rfLastWeek" class="col-4  btn csBtn csbuttonNoFill redemptionFilterBtn" (click)="clickDateRange('LastWeek')">{{'lastWeek'|translate}}</button>
    <button type="button" id="{{page}}rfLastMonth" class="col-4  btn csBtn csbuttonNoFill redemptionFilterBtn" (click)="clickDateRange('LastMonth')">{{'lastMonth'|translate}}</button>
</div>
</div>

<div class="container-fluid">
     <div class="row" *ngFor="let key of dateKeys" style="margin-top:20px;">
          <div class="col-12"><pre class="redemptionDate" style="margin-left:-15px;"><app-date [date]="key"></app-date></pre></div>
          <div class="col-12">
              <div class="row walletCard" data-special *ngFor="let item of redemptionHistMap.get(key)" style="margin-top: 20px; margin-bottom: 0px;padding-left:5px;padding-right:5px;padding-top:10px;padding-bottom:10px;border-radius:8px;">
                   <div class="col-2">
                       <!--/assets/common/image/brands/brand/CSL.png--> 
                       <!-- special handle for netivgator logo-->
                       <img src="{{sessionStorage('couponCard-'+item.couponItem.issueBrand)}}" style="width:100%;padding:0px"/>
                   </div>
                   <div class="col-10">
                      <div class="row" *ngIf="item.actionType=='RDM'"  style="padding-left:7px;">
                          <div class="col-12 bookRemark1_bold" style="text-align: left; padding-left:7px;margin-bottom:10px;">{{lang=='zh-HK'?item.couponItem.nameCht:item.couponItem.name}}</div>
                          <!--<pre class="col-6 bookRemark1" style="text-align:left;padding-left:7px;" *ngIf="item.couponItem.couponType!='Cash/Discount Coupon'">{{'redeemedItem'|translate}}:</pre>
                          <pre class="col-6 bookRemark1_bold" style="text-align: right;padding-right:20px;" *ngIf="item.couponItem.couponType!='Cash/Discount Coupon'">{{item.couponCode}}</pre>-->
<!--                          <pre class="col-6 bookRemark1" style="text-align:left;padding-left:7px;" *ngIf="item.couponItem.couponType.indexOf('Cash')>-1&&item.couponItem.couponType.indexOf('Discount')>-1">{{'redeemedAmount'|translate}}:</pre>
                          <pre class="col-6 bookRemark1_bold" style="text-align: right;padding-right:20px;" *ngIf="item.couponItem.couponType.indexOf('Cash')>-1&&item.couponItem.couponType.indexOf('Discount')>-1">{{item.redemptionAmount}}</pre>
                          old logic:item.couponItem.couponType!='Free Gift or Premium Coupon' && item.couponItem.couponType!='Free Service Coupon' && item.couponItem.couponType!='Premium Service Coupon' &&item.redemptionAmount 
-->
                          <!-- <pre class="col-7 bookRemark1" style="text-align:left;padding-left:7px;" *ngIf="item.couponItem.couponType=='Cash_Discount'||item.couponItem.couponType=='Free Gift or Premium Coupon'">{{'redeemedAmount'|translate}}:</pre>
                          <pre class="col-5 bookRemark1_bold" style="text-align: right;padding-right:20px;" *ngIf="item.couponItem.couponType=='Cash_Discount'||item.couponItem.couponType=='Free Gift or Premium Coupon'">{{item.redemptionAmount}}</pre> -->
                        
                          <pre class="col-7 bookRemark1_couponCode" style="text-align:left;" *ngIf="item.couponItem.couponType=='Cash_Discount' && item.couponItem.cashDiscountType=='C'
                          && item.couponItem.keepBalance==true">{{'redeemedAmount'|translate}}:</pre>
                          <pre class="col-5 bookRemark1_couponCode_bold" style="text-align:right;" *ngIf="item.couponItem.couponType=='Cash_Discount' && item.couponItem.cashDiscountType=='C'
                          && item.couponItem.keepBalance==true">HK${{item.redemptionAmount}}</pre>

                          <pre class="col-7 bookRemark1_couponCode" style="text-align:left;" *ngIf="item.couponItem.couponType=='Cash_Discount' && item.couponItem.cashDiscountType=='C'
                          && item.couponItem.keepBalance==false">{{'redeemedAmount'|translate}}:</pre>

                          <pre class="col-5 bookRemark1_couponCode_bold" style="text-align:right;" *ngIf="item.couponItem.couponType=='Cash_Discount' && item.couponItem.cashDiscountType=='C'
                          && item.couponItem.keepBalance==false">HK${{item.couponItem.initBalance}}</pre>

                          <pre class="col-7 bookRemark1_couponCode" style="text-align:left;;" *ngIf="item.couponItem.couponType=='Gift_Coupon'">{{'redeemedItem'|translate}}:</pre>
                          <pre class="col-5 bookRemark1_couponCode_bold" style="text-align:right;" *ngIf="item.couponItem.couponType=='Gift_Coupon'">{{item.redemptionRemark}}</pre>
                          
                          <pre class="col-12 bookRemark1_couponCode" style="text-align:left;;">{{'redeemedChannel'|translate}}: <span class="bookRemark1_couponCode_bold">{{item.salesChannelName?item.salesChannelName:'-'}}</span></pre>

                          <!-- <pre class="col-7 bookRemark1" style="text-align:left;padding-left:7px;" *ngIf="item&&item.channelGroup">{{'redeemedChannel'|translate}}:</pre>
                          <pre class="col-5 bookRemark1_bold" style="text-align: right;padding-right:20px;" *ngIf="item&&item.channelGroup">{{item.channelGroup}}</pre> -->
                      </div>
                      <div class="row" *ngIf="item.actionType=='TFT'">
                        <div class="col-12 bookRemark1_bold" style="text-align: left; padding-left:7px;margin-bottom:10px;">{{lang=='zh-HK'?item.couponItem.nameCht:item.couponItem.name}}</div>

                        <pre class="col-7 bookRemark1_couponCode" style="text-align:left;;" *ngIf="item.isTransferor">{{'transferTo'|translate}}:</pre>
                        <pre class="col-5 bookRemark1_couponCode_bold" style="text-align:right;" *ngIf="item.isTransferor">{{item.transferredToMasked}}</pre>

                        <pre class="col-7 bookRemark1_couponCode" style="text-align:left;;" *ngIf="!item.isTransferor">{{'transferFrom'|translate}}:</pre>
                        <pre class="col-5 bookRemark1_couponCode_bold" style="text-align:right;" *ngIf="!item.isTransferor">{{item.transferredFromMasked}}</pre>
                        
                    </div>
                   </div>
              </div>
          </div>
     </div>
     <div class="row">
         <p class="col-12" style="text-align: center;margin-top:10px;" *ngIf="redemptionHists&&redemptionHists.length>0&&totalNumber>(nextOffset)" (click)="clickMore()">{{'more'|translate}}▼</p><!--&&totalNumber>(nextOffset+defaultLimit)-->
     </div>
</div>


<div id="{{page}}redemptionHistoryDateRangeDiv" class="dateRangeModal">
    <!--<img id="nameCardQrClose" class="close popClose" (click)="closeDateRange()" src="/assets/common/image/icons/icon_close.png" width="30" height="30" style="top: 105px;right:40px;"/>-->
    <div class="dateRangeModal-content">
        <app-myewallet-dateRangePicker [id]="'RedemptionHistory'" [initDateRange]="dateRangePickerShow" [page]="page" (onClose)="closeDateRange()" (onEnter)="enterDateRange()"></app-myewallet-dateRangePicker>
    </div>
</div>
 
<div *ngIf="channel=='MyHKT' && redemptionHistMap.size==0" class="noHistoryDisplay"> {{'noHistory'|translate}} </div>
<button id="myewallet-search" (click)="init()" style="display:none;"></button>
<div class="actionBar secondly_header">
    <div class="container-fluid secondly_header input-group searchGroup" style="margin-top:15px;">
        <input id="search_searchCoupon" type="search" class="form-control searchCoupon" 
        placeholder="{{'searchForCoupon'|translate}}" [(ngModel)]="filterCouponName" ng-reflect-model="" autocomplete="off" style="margin-right:2px;"/>
        <div class="input-group-prepend">
              <!--{{'searchForCoupon'|translate}} <i class="fa fa-search icon" style="padding-top:7px;color:var(--gray2)" (click)="clickSearch()"></i>-->
              <img src="/assets/common/image/icons/{{channel}}/icon_search.png" width="20" height="20" style="margin-top:8px;" (click)="clickSearch()"/>
        </div>
  </div>
</div>
<div class="container-fluid searchScrollFreshView" style="padding-top:74px;overflow:auto;">
      <div class="row">
            <div class="col-12" style="padding-right: 35px;">
                  <app-myewallet-brandfilter [issueBrandList]="issueBrandList" [resultBrandMap]="resultBrandMap" [filterBrand]="filterBrand" (brandChange)="brandChange($event)" ></app-myewallet-brandfilter>
                  <div class="secondly_header" *ngIf="resultBrandMap&&resultBrandMap.size>0" style="position:absolute;overflow:auto;width:92%;top:{{resultBrandMap.size==1&&resultBrandMap.get(appIssueBrand)?20:61}}px;height:1px;background-color:var(--splitColor);"></div>
                  <app-myewallet-couponstatusfilter [page]="'search'" [filterStatus]="filterStatus" (statusChange)="statusChange($event)"></app-myewallet-couponstatusfilter>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" style="padding-top:10px;padding-bottom:10px;padding-left:10px;padding-right:10px;" *ngFor="let cp of couponList">
                  <div class="walletCard" style="padding-left:0px;padding-right:0px;vertical-align: middle;border-radius:15px;" >
                        <app-myewallet-search-couponCard [cp]='cp' (openDetail)="goDetail(cp.couponCode)" (share)="clickShare()" [tab]="'coupon'"></app-myewallet-search-couponCard>
                </div>
            </div>     
      </div>
</div>


<!--2 coupon detail popup -->
<button id="openCouponDetailBtn" style="display:none;" data-toggle="modal" data-target="#myCouponDetailModal"></button>
<div class="modal fade walletCard" id="myCouponDetailModal" style="padding-right:0px;padding-left:0px;">
      <div class="modal-dialog modal-lg walletCard" style="max-width:100%;margin:0px;">
            <div class="modal-content walletCard" >
                  <div class="modal-body walletCard" style="padding:0px;">
                        <!--<i id="couponDetailClose" class="close popClose" data-dismiss="modal" >×</i>-->
                        <img class="close popClose" data-dismiss="modal" src="/assets/common/image/icons/{{channel}}/icon_close.png" width="30" height="30" (click)="detailClose()"/>
                        <app-myewallet-couponDetail [couponDetail]='selectCoupon' [page]="openDetailPage"></app-myewallet-couponDetail>
                  </div>
            </div>
      </div>
</div>
import { Component, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

@Component({
    selector: 'app-mywallet-filter',
    templateUrl: './myewallet-filter.component.html',
    styleUrls: ['./myewallet-filter.component.scss']
  })
  export class MyEWalletFilterComponent implements OnInit {

    selectCtId:string="ctAll";
    filterCt:string='';

    selectCsId:string="csAll";
    filterCs:string='';

    selectRcId:string="rcAll";
    filterRc:string='';

    @Input()
    filterCouponName:string;

    @Input()
    filterStatus:string;

    @Input()
    filterType:string;

    showFilterCouponName:string;

    theme:string;

    constructor(
      private router: Router,
      private location: Location,
    ){}

    ngOnInit() {
      this.theme=localStorage.getItem("theme");
      // console.log("detail page theme is "+this.theme);

      if(!this.checkSelectNotNull(this.theme)){
        this.theme="drak";
      }
      this.initFilter(true);
      
    }

    initFilter(bySession:boolean){
      this.theme=localStorage.getItem("theme");
      // console.log("detail page theme is "+this.theme);

      if(!this.checkSelectNotNull(this.theme)){
        this.theme="drak";
      }

      var stf;
      var ssf;
      var snf;
      if(bySession){
        stf=sessionStorage.getItem("myWallet_type_filter");
        ssf=sessionStorage.getItem("myWallet_status_filter");
        snf=sessionStorage.getItem("myWallet_name_filter");
      }
      else{
        stf=this.filterType;
        ssf=this.filterStatus;
        snf=this.filterCouponName;
      }

      console.log("init filter type:"+stf+";status:"+ssf+";name:"+snf);
      // console.log(stf);
      // console.log(snf);
      if(stf){      
        if(stf==''){
            this.selectCtId='ctAll';
            this.filterCt='';
        }
        else{
            this.selectCtId='ct'+stf;
            this.filterCt=stf;
         }
      }
      else{
        this.selectCtId='ctAll';
        this.filterCt='';
      }

      if(ssf){
          if(ssf==''){
             this.selectCsId='csAll';
             this.filterCs='';
          }
          else{
            this.selectCsId='cs'+ssf;
            this.filterCs=ssf;
          }
      }
      else{
        this.selectCsId='csAll';
        this.filterCs='';
      }

      if(snf){
         if(snf.trim()!=''){
           this.filterCouponName=snf;
           this.showFilterCouponName=snf;
         }
         else{
           this.filterCouponName='';
           this.showFilterCouponName='';
         }
      }
      else{
        this.filterCouponName='';
        this.showFilterCouponName='';
      }

      this.changeButtonSelectStyle(this.selectCtId,"ctBtn");
      this.changeButtonSelectStyle(this.selectCsId,"csBtn");
    }

    clickCouponType(ctId:string){
        this.selectCtId='ct'+ctId;
        this.filterCt=this.getFilterStr(ctId);
        this.changeButtonSelectStyle(this.selectCtId,"ctBtn");
    }

    clickCouponStatus(csId:string){
        this.selectCsId='cs'+csId;
        // this.filterCs=this.getFilterStr(csId);
        this.filterCs=csId;//default not all
        this.changeButtonSelectStyle(this.selectCsId,"csBtn");
    }

    clickRedemptionChannels(rcId:string){
        this.selectRcId='rc'+rcId;
        this.filterRc=this.getFilterStr(rcId);
        this.changeButtonSelectStyle(this.selectRcId,"rcBtn");
    }

    clickClearSelect(){
      this.selectCtId="ctAll";
      this.filterCt='';
  
      // this.selectCsId="csAll";
      // this.filterCs='';
      this.selectCsId="csREDEEMABLE";
      this.filterCs='REDEEMABLE';
      this.showFilterCouponName='';
      this.changeButtonSelectStyle(this.selectCtId,"ctBtn");
      this.changeButtonSelectStyle(this.selectCsId,"csBtn");
    }

    closeFilter(){
      this.router.navigateByUrl('/myewallet');
    }

    showResult(){
        if(!sessionStorage.getItem("sessionTime")){
             var n:Date=new Date();
             sessionStorage.setItem("sessionTime",""+n.getTime());
        }

        this.filterCouponName=this.showFilterCouponName;
        sessionStorage.setItem("myWallet_type_filter",this.filterCt);
        sessionStorage.setItem("myWallet_status_filter",this.filterCs);
        sessionStorage.setItem("myWallet_name_filter",this.showFilterCouponName);
        var closeBtn=document.getElementById('filterClose');
        var reSearchBtn=document.getElementById("filterSearch");
        if(closeBtn&&reSearchBtn){
          closeBtn.click();
          reSearchBtn.click();
        }
        else{
          this.router.navigateByUrl('/myewallet');
        }
    }

    private changeButtonSelectStyle(ctId:string,className:string){
      var ctButtons=document.getElementsByClassName(className);
      // console.log(ctButtons);
      for(var i=0;i<ctButtons.length;i++){
          // ctButtons[i].setAttribute("style","");
          var btnClass=ctButtons[i].getAttribute("class");
          btnClass=btnClass.replace('buttonFill','buttonNoFill');
          ctButtons[i].setAttribute("class",btnClass);
      }
      if(ctId&&ctId.length>0){
          var selectButton=document.getElementById(ctId);
          if(selectButton){
            // selectButton.setAttribute("style","border:2px solid orange;color:orange");
            var btnClass=selectButton.getAttribute("class");
            btnClass=btnClass.replace('buttonNoFill','buttonFill');
            selectButton.setAttribute("class",btnClass);
          }
      }
    }

    private getFilterStr(filterStr:string):string{
      var result=null;
      if(filterStr=='All'){
        result='';
      }
      else{
        result=filterStr;
      }
      return result;
    }

    private checkSelectNotNull(value:string):Boolean{
      var b:Boolean=false;
      if(value&&value.length>0){
          if(value!='---'){
            b=true;
          }
      }
      return b;
  }
  }
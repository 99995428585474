<div class="container-fluid secondly_header" data-special-stock style="width:100%;height:100%; ">
    <div style="padding-top: 20px;text-align:center" >
            <label class="stock_check_label">{{"product"|translate}}:</label>
            <select class="custom-select" style="width: 50%;text-align:center" [(ngModel)]="selectedProduct" (change)="ChangingProduct($event)" [disabled]="!productFlag">
                <option value="none">--</option>
                <option *ngFor="let product of productList" [ngValue]="product.SKU">{{product.name}}</option>
            </select>
    </div>
    <div style="padding-top: 20px;text-align:center" >
        <label class="stock_check_label">{{"district"|translate}}:</label>
            <select class="custom-select" style="width: 50%;text-align:center" [(ngModel)]="selectedDistrict" (change)="ChangingDistrict($event)" [disabled]="districtListDisabled">
                <option value="none">{{'stockDistrictHint'|translate}}</option>
                <option *ngFor="let district of districtList" [ngValue]="district.code">{{district.name}}</option>
            </select>
    </div>
    <div class="break-text" style="padding-top: 20px; height: 100%; overflow:auto" *ngIf="detailFlag">
        <!--<label style="margin-right:5%;margin-left:5%">{{'stockAvailabilityTitle'|translate}}</label>-->

        <table class="table stock_table">
            <thead>
                <tr>
                    <th style="width:30%">{{'district'|translate}}</th>
                    <th style="width:50%">{{'address'|translate}}</th>
                    <th style="width:20%">{{'stock_level'|translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let stock of stockDisplayList let i = index" [ngClass]="getLastItemClass(i, stockDisplayList.length)">
                    <td>{{stock?.districtDes}}</td>
                    <td>{{stock?.addressDes}}</td>
                    <td>{{stock?.stockLevel|stockLevel}}</td>
                </tr>
            </tbody>
        </table>
        <!--
        <div style="margin-top: 0px; margin-bottom: 0px;padding-left:5px;padding-right:5px;padding-top:5px;padding-bottom:5px;border-radius:8px;font-weight: bold;font-size: var(--iconSize)">
            <div class="col-2">
                <p>{{'district'|translate}}</p>
            </div>
            <div class="col-8">
                <p>{{'address'|translate}}</p>
            </div>
            <div class="col-2">
                <p>{{'status'|translate}}</p>
            </div>
        </div>
        <div class="row walletCard" *ngFor="let stock of stockDisplayList"
            style="margin-top: 10px; margin-bottom: 0px;padding-left:5px;padding-right:5px;padding-top:10px;padding-bottom:10px;border-radius:8px;">
            <div class="col-2 border-right">
                <p>{{stock?.district}}</p>
            </div>
            <div class="col-8 border-right">
                <p>{{stock?.address}}</p>
            </div>
            <div class="col-2">
                <p>{{stock.stock_level}}</p>
            </div>
        </div>-->
    </div>
    <div style="text-align:center" *ngIf="noAvailChannelFlag">
        <label class="stock_check_label">{{'noAvaliChannel'|translate}}</label>
    </div>
</div>
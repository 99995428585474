<button id="mgmRewardHistroyInitBtn" (click)="initDetail()" style="display:none;"></button>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
                <p class="historyPopupTitle" data-special>{{'mgmRewardHistory'|translate}}</p>
        </div>
    </div>
</div>

<div *ngFor="let cp of rewardHists;" class="container-fluid walletCard"  data-special style="border-top-left-radius:8px;
border-top-right-radius:8px;
border-bottom-left-radius:8px;
border-bottom-right-radius:8px;">
        <div id="mgmRewardHist_title{{cp.logId}}" class="row walletCard title_down">
            <div class="col-10 col-md-11 col-lg-11" style="padding-top:1px;">
                <pre class="bookRemark1_bold" style="font-size:18px; text-align: left;margin-left: 8px;margin-top:10px;">{{lang == 'zh-HK'?cp.couponItem.nameCht:cp.couponItem.name}}</pre>    
            </div>
            <div class="popBotton col-1 col-md-1 col-lg-1" style="width:30px;height:30px;border-radius: 50%;" >
                <a id="mgmRewardHist_toggle{{cp.logId}}" href="#rwhPb{{cp.logId}}Detail" data-toggle="collapse"  class="fa fa-angle-down" style="margin-left:11px;padding-top:12px;font-size: 25px;" (click)="clickTitleToggle(cp.logId)"></a>                               
            </div>
            <div class="col-12 row" style="margin-top:20px; margin-left:8px;" *ngIf="cp&&cp.actionType=='RDM'">
                <pre class="col-6 bookRemark1_bold" style="text-align:left;padding-left:0px">{{'redeemedDate'|translate}}:</pre>
                <pre class="col-6 bookRemark1_bold" style="text-align:right;font-weight:bold;padding-left:16px;"><app-date [date]="cp.redemptionDate?cp.redemptionDate:cp.actionDate"></app-date></pre>
                <pre class="col-6 bookRemark1_bold" style="text-align:left;padding-left:0px;">{{'redeemedChannel'|translate}}:</pre>
                <pre class="col-6 bookRemark1_bold" style="text-align:right;font-weight:bold;padding-left:16px;">{{cp.salesChannelName?cp.salesChannelName:"-"}}</pre>    
            </div>
            <div class="bookRemark1" style="text-align: left;margin-left: 16px;margin-top:10px;">{{'successfulReferrals'|translate}}/ {{'totalRequiredReferrals'|translate}}:&nbsp;{{cp.referralCount?cp.referralCount:0}}/{{cp.referralTarget?cp.referralTarget:0}}</div>
        </div> 

        <div id="rwhPb{{cp.logId}}Detail" class="row collapseDetail collapse" >      
            <div class="col-12 row walletCard" style="padding-top:10px;  margin-left:5px;margin-right:5px;margin-bottom:0px;border-bottom-left-radius:8px;border-bottom-right-radius:8px;">
                <app-myewallet-mgm-reward-history-detail [data]="cp" style="width:100%"></app-myewallet-mgm-reward-history-detail>
            </div>
        </div>
    </div>


<div class="row">
    <p class="col-12" style="text-align: center;margin-top:10px;" *ngIf="rewardHists&&rewardHists.length>0&&totalNumber>(nextOffset)" (click)="clickMore()">{{'more'|translate}}▼</p><!--&&totalNumber>(nextOffset+defaultLimit)-->
</div>


<div *ngIf="channel=='MyHKT' && (!rewardHists||rewardHists.length==0)" class="noHistoryDisplay"> {{'noMGMHistory'|translate}} </div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './router-auth/auth-guard';
import { ErrorPageComponent } from './module/error/error-page.component';
import { LoginComponent } from './module/login/login.component';
import { MyEwalletDetailComponent } from './module/myewallet/myewallet-detail/myewallet-detail.component';
import {MyEwalletPendingComponent } from './module/myewallet/myewallet-pending/myewallet-pending-component';
import { environment } from '../environments/environment';

import { CouponListComponent } from './module/microsite/coupon-list/coupon-list.component';
import { CouponDetailComponent } from './module/microsite/coupon-detail/coupon-detail.component';


const routes: Routes = [
  { path: '',   redirectTo: '/myewallet', pathMatch: 'full',canActivate: [AuthGuard] },
  // { path:'error',component:ErrorPageComponent},
  // { path: 'error/:errorCode', component: ErrorPageComponent },
  // { path:'login',component:LoginComponent},
  // { path:'login/:msgCode',component:LoginComponent},
  // { path:'pending',component:MyEwalletPendingComponent},
  // { path: 'myewallet',  component: MyEwalletDetailComponent}
];

const routes2: Routes = [
  { path:'error',component:ErrorPageComponent},
  { path: 'error/:errorCode', component: ErrorPageComponent },
  { path: 'mgm', component: CouponListComponent },
  { path: 'mgm/coupon', component: CouponDetailComponent },
  { path: '', redirectTo: '/error', pathMatch: 'full' },
  { path: '**', component: ErrorPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(environment.env.indexOf('microsite')>-1?routes2:routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

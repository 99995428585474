import { Component, OnInit,OnDestroy, ErrorHandler,Output,EventEmitter, Input} from '@angular/core';
import { QrCodeScanUtil } from '../../../../assets/common/util/qrcodescanutil';
import { JwtUtil } from '../../../data/util/jwtUtils';

@Component({
    selector: 'app-myewallet-scanQrCode',
    // directives: [ InfiniteScroll ], 
    templateUrl: './myewallet-scanqrcode.html',
    styleUrls: ['./myewallet-scanqrcode.scss']
})
export class MyEwalletScanQrcode implements OnInit{
    
    // showHeight:number=0;

    // showWidth:number=0;

    channel:string;

    @Input()
    couponName:string;

    @Output()
    onReturn=new EventEmitter<any>();

    @Output()
    onScanSuccess=new EventEmitter<any>();

    scanResultInput:Element;

    scanning=false;

    ngOnInit() { 
        if(this.couponName==undefined){
            this.init();
        }
        this.channel=this.jwtUtil.getSubServiceAppType();
        // this.showHeight=window.screen.availHeight;
        // this.showWidth=window.screen.availWidth;
        this.scanResultInput=document.getElementsByClassName('scanResult')[0];
        this.scanResultInput.setAttribute('value','');
        // console.log(this.showHeight);
        // console.log(this.showWidth);
    }

    constructor(private jwtUtil:JwtUtil,){}

    init(){
        QrCodeScanUtil.doScan('canvas','camera');
    }

    clickReturn(){
        QrCodeScanUtil.closeCamera('camera');
        this.onReturn.emit();
    }

    resetScan(){
        this.scanning=false;
    }

    clickScaned(){
       if(!this.scanning){
            this.scanning=true;
            console.log('scaned');
            QrCodeScanUtil.closeCamera('camera');
            var code=this.scanResultInput.getAttribute('value');
            console.log('result:'+code);
            this.onScanSuccess.emit(code);         
       }
    }
}